import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { CreditContext } from "../context/CreditContext";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import "moment/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";
import { statusCred, types } from "../pages/Credit";
import { PersonContext } from "../context/PersonContext";
import { ICredit, IPerson } from "../types";

const { RangePicker } = DatePicker;
const { Option } = Select;

interface FilterCreditProps {
    person: IPerson[];
    credits?: ICredit[];

    setFilterOps: any;
    filterOps: any;

    setSend: any;
}

export function FilterCredit(props: FilterCreditProps) {
    // status: "",
    // type: "",
    // person_customer: "",
    // doc: "",
    const [statusFilter, setStatusFilter] = useState(
        props.filterOps.options.status
    );
    const [typeFilter, setTypeFilter] = useState(props.filterOps.options.type);
    const [docFilter, setDocFilter] = useState(props.filterOps.options.doc);
    const [personCustomerFilter, setPersonCustomerFilter] = useState(
        props.filterOps.options.personCustomer
    );
    const [personList, setPersonList] = useState([]);

    const [createDate, setCreateDate] = useState<any>([
        props.filterOps.options.dateCreate.length > 0
            ? props.filterOps.options.dateCreate[0].split("T")[0]
            : "",
        props.filterOps.options.dateCreate.length > 0
            ? props.filterOps.options.dateCreate[1].split("T")[0]
            : "",
    ]);
    const [expiredDate, setExpiredDate] = useState<any>([
        props.filterOps.options.dateExpired.length > 0
            ? props.filterOps.options.dateExpired[0].split("T")[0]
            : "",
        props.filterOps.options.dateExpired.length > 0
            ? props.filterOps.options.dateExpired[1].split("T")[0]
            : "",
    ]);

    useEffect(() => {
        let list: any = [];
        if (props.credits) {
            if (props.credits.length > 0) {
                props.credits.forEach((c: any) => {
                    if (props.person.length > 0) {
                        if (
                            list.findIndex(
                                (i: any) =>
                                    i.person_customer_temp ===
                                    c.person_customer_temp
                            ) === -1
                        ) {
                            list.push(c);
                        }
                    }
                });
            }
        }

        setPersonList(list);
    }, [props.credits, props.person]);

    return (
        <>
            <Form
                layout="vertical"
                onFinish={(v: any) => {
                    let dateCreateFormat;
                    let dateExpiredFormat;

                    if (createDate[0] === "") {
                        dateCreateFormat = createDate;
                    } else {
                        dateCreateFormat = [
                            createDate[0].concat("T00:00:00"),
                            createDate[1].concat("T23:59:59"),
                        ];
                    }

                    if (expiredDate[0] === "") {
                        dateExpiredFormat = expiredDate;
                    } else {
                        dateExpiredFormat = [
                            expiredDate[0].concat("T00:00:00"),
                            expiredDate[1].concat("T23:59:59"),
                        ];
                    }

                    let newArray = JSON.parse(JSON.stringify(props.filterOps));
                    newArray.options = {
                        type: typeFilter,
                        status: statusFilter,
                        doc: docFilter,
                        personCustomer: personCustomerFilter,
                        dateCreate: dateCreateFormat,
                        dateExpired: dateExpiredFormat,
                    };
                    props.setSend(true);
                    props.setFilterOps(newArray);
                }}
                initialValues={{
                    type: typeFilter,
                    status: statusFilter,
                    doc: docFilter,
                    person_customer_temp: personCustomerFilter,
                    date:
                        createDate[0] === ""
                            ? ["", ""]
                            : [
                                  moment(createDate[0], "YYYY-MM-DDTHH:mm:ss"),
                                  moment(createDate[1], "YYYY-MM-DDTHH:mm:ss"),
                              ],
                    expire_date:
                        expiredDate[0] === ""
                            ? ["", ""]
                            : [
                                  moment(expiredDate[0], "YYYY-MM-DDTHH:mm:ss"),
                                  moment(expiredDate[1], "YYYY-MM-DDTHH:mm:ss"),
                              ],
                }}
            >
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item label="Data de Criação" name="date">
                            <RangePicker
                                locale={locale}
                                onChange={(val: any, val2: any) => {
                                    setCreateDate([val2[0], val2[1]]);
                                }}
                                disabledDate={current => {
                                    // Can not select days after today and before start Date
                                    const start = moment(
                                        "2020-01-01",
                                        "YYYY-MM-DD"
                                    );
                                    return (
                                        current < start ||
                                        current > moment().endOf("day")
                                    );
                                }}
                                ranges={{
                                    Hoje: [moment().startOf("day"), moment()],
                                    Ontem: [
                                        moment()
                                            .subtract(1, "days")
                                            .startOf("day"),
                                        moment()
                                            .subtract(1, "days")
                                            .endOf("day"),
                                    ],
                                    "Última semana": [
                                        moment()
                                            .subtract(6, "days")
                                            .startOf("day"),
                                        moment(),
                                    ],
                                    "Último mês": [
                                        moment()
                                            .subtract(1, "months")
                                            .startOf("day"),
                                        moment(),
                                    ],
                                }}
                                placeholder={["Data inicial", "Data final"]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Tipo do crédito" name="type">
                            <Select
                                placeholder="Selecione"
                                onSelect={(value: any) => setTypeFilter(value)}
                            >
                                <Option
                                    value=""
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Sem filtro
                                </Option>
                                {types.map((i: any) => {
                                    return (
                                        <Option key={uuidv4()} value={i.id}>
                                            {i.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Status do crédito" name="status">
                            <Select
                                placeholder="Selecione"
                                onSelect={(value: any) =>
                                    setStatusFilter(value)
                                }
                            >
                                {" "}
                                <Option
                                    value=""
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Sem filtro
                                </Option>
                                {statusCred.map((i: any) => {
                                    return (
                                        <Option key={uuidv4()} value={i.id}>
                                            {i.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={10}>
                        <Form.Item label="Data de Expiração" name="expire_date">
                            <RangePicker
                                locale={locale}
                                onChange={(val: any, val2: any) => {
                                    setExpiredDate([val2[0], val2[1]]);
                                }}
                                disabledDate={current => {
                                    // Can not select days after today and before start Date
                                    const start = moment(
                                        "2020-01-01",
                                        "YYYY-MM-DD"
                                    );
                                    return current < start;
                                }}
                                ranges={{
                                    Hoje: [moment().startOf("day"), moment()],
                                    Ontem: [
                                        moment()
                                            .subtract(1, "days")
                                            .startOf("day"),
                                        moment()
                                            .subtract(1, "days")
                                            .endOf("day"),
                                    ],
                                    "Última semana": [
                                        moment()
                                            .subtract(6, "days")
                                            .startOf("day"),
                                        moment(),
                                    ],
                                    "Último mês": [
                                        moment()
                                            .subtract(1, "months")
                                            .startOf("day"),
                                        moment(),
                                    ],
                                }}
                                placeholder={["Data inicial", "Data final"]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Nº da nota Fiscal" name="doc">
                            <Select
                                placeholder="Selecione"
                                onSelect={(value: any) => setDocFilter(value)}
                                showSearch
                                filterOption={(input, option: any) => {
                                    if (!isNaN(Number(input))) {
                                        return String(option?.value).includes(
                                            input + ""
                                        );
                                    } else {
                                        return String(option?.children)
                                            .toLowerCase()
                                            .includes(input.toLowerCase());
                                    }
                                }}
                            >
                                <Option
                                    value=""
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Sem filtro
                                </Option>
                                {props.credits ? (
                                    props.credits.length > 0 &&
                                    props.credits.map((i: any) => {
                                        if (i.doc !== "") {
                                            return (
                                                <Option
                                                    key={uuidv4()}
                                                    value={i.doc}
                                                >
                                                    {i.doc}
                                                </Option>
                                            );
                                        }
                                    })
                                ) : (
                                    <Option
                                        value=""
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Sem filtro
                                    </Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Cliente" name="person_customer_temp">
                            <Select
                                placeholder="Selecione"
                                onSelect={(value: any) =>
                                    setPersonCustomerFilter(value)
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option: any) => {
                                    if (!isNaN(Number(input))) {
                                        return String(option?.key).includes(
                                            input + ""
                                        );
                                    } else {
                                        return String(option?.children)
                                            .toLowerCase()
                                            .includes(input.toLowerCase());
                                    }
                                }}
                            >
                                <Option
                                    value=""
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Sem filtro
                                </Option>
                                {props.credits ? (
                                    personList.length > 0 &&
                                    personList.map((i: any) => {
                                        let nome: any = "";
                                        if (props.person.length > 0) {
                                            nome =
                                                props.person[
                                                    props.person.findIndex(
                                                        (p: IPerson) =>
                                                            p.id_person ===
                                                            i.person_customer_temp
                                                    )
                                                ].name;
                                        }
                                        return (
                                            <Option
                                                key={
                                                    props.person[
                                                        props.person.findIndex(
                                                            (p: IPerson) =>
                                                                p.id_person ===
                                                                i.person_customer_temp
                                                        )
                                                    ].identification
                                                }
                                                value={i.person_customer_temp}
                                            >
                                                {nome}
                                            </Option>
                                        );
                                    })
                                ) : (
                                    <Option
                                        value=""
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Sem filtro
                                    </Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ marginTop: "20px" }}>
                    <Button type="primary" htmlType="submit" block>
                        Aplicar Filtros
                    </Button>
                </div>
            </Form>
        </>
    );
}
