import { Button, Empty, Menu, Popconfirm, Table } from "antd";
import { Eye, Funnel, Paperclip, PencilSimple, Trash } from "phosphor-react";
import { useContext, useEffect, useState, useCallback } from "react";
import { FilterCredit } from "../components/FilterCredit";
import { FormCredit } from "../components/Forms/FormCredit";
import { Modal } from "../components/Modal";
import { CreditContext } from "../context/CreditContext";
import { PersonContext } from "../context/PersonContext";
import { ICredit } from "../types";
import styled from "styled-components";
import { TableRowSelection } from "antd/lib/table/interface";

import { Modal as ModalAntd } from "antd";
import { FormEditCredits } from "../components/FormEditCredits";
import { FormUploadExcel } from "../components/FormUploadExcel";
import { currencyFormatter, currencyFormatterGranos } from "../helpers";

export const IconItemMenu = styled.div`
    display: flex;
    align-items: center;
    column-gap: 5px;
`;

const { SubMenu } = Menu;

export interface ICreditProps {
    user: any;
}

export const types = [
    { id: "indication", label: "Indicação" },
    { id: "purchase", label: "Compra" },
    { id: "bonus", label: "Bonificação" },
];

export const statusCred = [
    { id: "pending", label: "Pendente" },
    { id: "waiting", label: "Liberado para faturamento" },
    { id: "processing", label: "Faturamento em processamento" },
    { id: "finished", label: "Concluído" },
];

export default function Credit(props: ICreditProps) {
    const { stateC, dispatch, getCredit, delCredit, getFilterCredit } =
        useContext(CreditContext);
    const { person, getPerson } = useContext(PersonContext);

    const [configModal, setConfigModal] = useState({
        title: "",
        content: <></>,
    });
    const [filterOps, setFilterOps] = useState({
        options: {
            status: "",
            type: "",
            personCustomer: "",
            doc: "",
            dateCreate: [],
            dateExpired: [],
        },
    });

    const [sendReq, setSendReq] = useState(false);
    const [selectCredits, setSelectedCredits] = useState<ICredit[]>([]);
    const [previewVisible, setPreviewVisible] = useState(false);

    const [selectedRowsArray, setSelectedRowsArray] = useState<any>([]);

    useEffect(() => {
        getPerson();
        getCredit();
    }, []);

    useEffect(() => {
        if (sendReq === true) {
            getFilterCredit({
                status: filterOps.options.status,
                type: filterOps.options.type,
                doc: filterOps.options.doc,
                personCustomer: filterOps.options.personCustomer,
                dateInCreate: filterOps.options.dateCreate[0],
                dateFinCreate: filterOps.options.dateCreate[1],
                dateInExpired: filterOps.options.dateExpired[0],
                dateFinExpired: filterOps.options.dateExpired[1],
            });
            setSendReq(false);
        }
    }, [sendReq, setSendReq]);

    const handleDisableSelec = useCallback(
        (value: any) => {
            if (props.user.login.type !== "MASTER") {
                if (value.status !== "pending" && value.status !== "waiting") {
                    return true;
                }
            }
            if (value.status === "finished") {
                return true;
            }
            return false;
        },
        [props.user]
    );

    const dataSource: ICredit[] = stateC.credits
        ? stateC.credits.data.map((i: ICredit, index: number) => {
              return { ...i, key: index };
          })
        : [];

    const columns: any = [
        {
            key: "1",
            title: "Cliente",
            dataIndex: "person_customer_temp",
            render: (text: any) => {
                let nome: any = "";
                if (person.length > 0) {
                    nome =
                        person[
                            person.findIndex((p: any) => p.id_person === text)
                        ].name;
                }
                return <>{nome}</>;
            },
        },
        {
            key: "2",
            title: "Data de Criação",
            dataIndex: "date",
            width: 105,
            render: (text: any) => {
                let a;
                if (text) {
                    a = `${text.split("T")[0].split("-")[2]}/${
                        text.split("T")[0].split("-")[1]
                    }/${text.split("T")[0].split("-")[0]}
                    `;
                }

                return <>{a}</>;
            },
        },
        {
            key: "3",
            title: "Data de Expiração",
            dataIndex: "expire_date",
            width: 105,
            render: (text: any) => {
                let a;
                if (text) {
                    a = `${text.split("T")[0].split("-")[2]}/${
                        text.split("T")[0].split("-")[1]
                    }/${text.split("T")[0].split("-")[0]}
                    `;
                }

                return <>{a}</>;
            },
        },
        {
            key: "4",
            title: "NFe",
            dataIndex: "doc",
        },
        {
            key: "5",
            title: "Valor em Reais",
            dataIndex: "value",
            width: 100,
            render: (text: any) => {
                return currencyFormatter(text);
            },
        },
        {
            key: "6",
            title: "Valor em Granos",
            dataIndex: "value_granos",
            render: (text: any) => {
                return currencyFormatterGranos(text);
            },
        },
        {
            key: "7",
            title: "Tipo do Crédito",
            dataIndex: "type",
            width: 110,
            render: (text: any) => {
                let t = "";
                if (text) {
                    t = types[types.findIndex((t: any) => t.id === text)].label;
                }
                return t;
            },
        },
        {
            key: "8",
            title: "Status",
            dataIndex: "status",
            render: (text: any) => {
                let s = "";
                if (text) {
                    s =
                        statusCred[
                            statusCred.findIndex((s: any) => s.id === text)
                        ].label;
                }
                return s;
            },

            sorter: (a: any, b: any) => {
                let nA: any = "";
                let nB: any = "";
                if (stateC.credits) {
                    nA =
                        statusCred[
                            statusCred.findIndex((s: any) => s.id === a.status)
                        ].label;
                    nB =
                        statusCred[
                            statusCred.findIndex((s: any) => s.id === b.status)
                        ].label;
                }
                return nA.localeCompare(nB);
            },
            sortDirections: ["ascend", "descend"],
            showSorterTooltip: false,
        },
        {
            key: "9",
            title: "Ações",
            render: (record: ICredit) => {
                return (
                    <>
                        <Button
                            type="link"
                            //disabled={record.status === "finished"}
                            onClick={() => {
                                handleEdit(record);
                            }}
                        >
                            {record.status === "finished" ? (
                                <Eye size={18} style={{ color: "black" }} />
                            ) : (
                                <PencilSimple size={18} />
                            )}
                        </Button>

                        <Button
                            type="link"
                            onClick={() => {
                                handleAnexo(record);
                            }}
                            style={{ color: "black" }}
                        >
                            <Paperclip size={18} />
                        </Button>
                        <Popconfirm
                            disabled={
                                record.status === "finished" ||
                                record.person_provider_temp !==
                                    props.user.login.id_person
                            }
                            title="Você tem certeza que vai cancelar esse crédito?"
                            okText="Sim"
                            cancelText="Cancelar"
                            okType="danger"
                            onConfirm={() =>
                                delCredit(
                                    record.person_customer_temp,
                                    record.id_credit
                                )
                            }
                            placement="topRight"
                        >
                            <Button
                                disabled={
                                    record.status === "finished" ||
                                    record.person_provider_temp !==
                                        props.user.login.id_person
                                }
                                style={{
                                    color:
                                        record.status === "finished" ||
                                        record.person_provider_temp !==
                                            props.user.login.id_person
                                            ? "rgba(0, 0, 0, 0.25)"
                                            : "red",
                                }}
                                type="link"
                                onClick={() => {}}
                            >
                                <Trash size={18} />
                            </Button>
                        </Popconfirm>
                    </>
                );
            },
        },
    ];

    // const expColumnCredit: any = [
    //     {
    //         key: "0",
    //         title: "Descrição",
    //         dataIndex: "detail",
    //     },
    // ];

    const rowSelection: TableRowSelection<any> = {
        selectedRowKeys: selectedRowsArray,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedCredits(selectedRows);
            setSelectedRowsArray(selectedRowKeys);
        },
        // onSelect: (record, selected, selectedRows) => {
        //     setSelectedCredits(selectedRows);
        // },
        // onSelectAll: (selected, selectedRows, changeRows) => {
        //     setSelectedCredits(selectedRows);
        // },

        getCheckboxProps: (record: any) => ({
            disabled: handleDisableSelec(record), // Column configuration not to be checked
            // name: record.status,
        }),
    };

    //Funções da page Credit
    function handleAdd() {
        setConfigModal({
            title: "Adicionar Crédito",
            content: (
                <FormCredit
                    type={0}
                    add={true}
                    typesCredit={types}
                    user={props.user}
                />
            ),
        });
        dispatch({ type: "set_open", payload: { open: true } });
    }

    function handleEdit(value: any) {
        setConfigModal({
            title:
                value.status === "finished"
                    ? "Visualizar Crédito"
                    : "Editar Crédito",
            content: (
                <FormCredit
                    type={value.status === "finished" ? 3 : 1}
                    add={false}
                    record={value}
                    typesCredit={types}
                    user={props.user}
                    setSelectedRowsArray={setSelectedRowsArray}
                    setSelectedCredits={setSelectedCredits}
                />
            ),
        });

        dispatch({ type: "set_open", payload: { open: true } });
    }

    function handleAnexo(value: any) {
        setConfigModal({
            title: "Enviar Anexo",
            content: <FormCredit type={2} record={value} />,
        });

        dispatch({ type: "set_open", payload: { open: true } });
    }

    function handleFilter() {
        setConfigModal({
            title: "Filtros",
            content: (
                <FilterCredit
                    person={person}
                    credits={stateC.credits?.data}
                    setFilterOps={setFilterOps}
                    filterOps={filterOps}
                    setSend={setSendReq}
                />
            ),
        });

        dispatch({ type: "set_open", payload: { open: true } });
    }

    function handleEditSelec() {
        setPreviewVisible(true);
        setConfigModal({
            title: "Editar Selecionados",
            content: (
                <FormEditCredits
                    user={props.user}
                    selectedCredits={selectCredits}
                    setVisible={setPreviewVisible}
                    setSelectedRowsArray={setSelectedRowsArray}
                    setSelectedCredits={setSelectedCredits}
                />
            ),
        });
    }
    function handleUploadExcel() {
        setPreviewVisible(true);
        setConfigModal({
            title: "Enviar Excel",
            content: <FormUploadExcel setVisible={setPreviewVisible} />,
        });
    }

    // const tableProps = {
    //     expandedRowRender: (record: ICredit) => {
    //         const expCredit: any = dataSource
    //             ? dataSource[
    //                   dataSource.findIndex(
    //                       (c: ICredit) => c.id_credit === record.id_credit
    //                   )
    //               ]
    //             : {};
    //         return (
    //             <div
    //                 style={{
    //                     display: "flex",
    //                     flexDirection: "column",
    //                     marginLeft: -40,
    //                     marginTop: 8,
    //                     marginBottom: 8,
    //                     marginRight: 8,
    //                 }}
    //             >
    //                 <Table
    //                     columns={expColumnCredit}
    //                     dataSource={[expCredit]}
    //                     pagination={false}
    //                     className="table-style"
    //                 />
    //             </div>
    //         );
    //     },
    // };

    return (
        <>
            <div
                style={{
                    width: "100%",
                    padding: "10px 50px 10px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <h1> Cadastro de Créditos:</h1>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        <Button
                            disabled={props.user.login.type !== "PROVIDER"}
                            onClick={() => handleUploadExcel()}
                        >
                            Enviar Excel
                        </Button>
                        <Button
                            disabled={selectCredits.length === 0}
                            onClick={() => handleEditSelec()}
                        >
                            Editar Selecionados
                        </Button>

                        <Button
                            disabled={props.user.login.type !== "PROVIDER"}
                            onClick={() => handleAdd()}
                        >
                            Adicionar
                        </Button>
                    </div>
                </div>
                <div
                    style={{
                        marginBottom: "10px",
                        // padding: "10px",
                    }}
                >
                    <Menu
                        mode="horizontal"
                        triggerSubMenuAction="click"
                        selectable={false}
                        onClick={(a: any) => {
                            handleFilter();
                        }}
                    >
                        <SubMenu
                            key="filtros"
                            title="Filtros"
                            popupOffset={[-15, 0]}
                        >
                            <Menu.Item key="filtros:1">
                                <IconItemMenu>
                                    <Funnel size={16} />
                                    Editar Filtros
                                </IconItemMenu>
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </div>
                <div>
                    <Table
                        // {...tableProps}
                        size="small"
                        columns={columns}
                        dataSource={dataSource}
                        sortDirections={[null]}
                        locale={{
                            emptyText: (
                                <Empty
                                    description="Nenhum crédito foi cadastrado"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            ),
                        }}
                        rowSelection={{ ...rowSelection }}
                        //UTILIZAR DESSA FORMA SE PRECISAR EXPANDIR
                        // expandable={{
                        //     expandedRowRender: record => <p>{record.detail}</p>,
                        // }}
                    ></Table>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "50%",
                            marginTop: "-40px",
                            marginRight: "1vw",
                        }}
                    >
                        <div>
                            Créditos por Compras:{" "}
                            <strong>{stateC.credits?.Count_purchase}</strong>
                        </div>
                        <div>
                            Créditos por Bonificação:{" "}
                            <strong>{stateC.credits?.Count_bonus}</strong>
                        </div>
                        <div>
                            Créditos por Indicação:{" "}
                            <strong>{stateC.credits?.Count_indication}</strong>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                title={configModal.title}
                isVisible={stateC.open ? stateC.open : false}
                width={800}
                setIsVisible={() =>
                    dispatch({
                        type: "set_uploading",
                        payload: { open: false },
                    })
                }
            >
                {configModal.content}
            </Modal>

            <ModalAntd
                title={configModal.title}
                visible={previewVisible}
                onCancel={() => setPreviewVisible(false)}
                footer={null}
                width={500}
                destroyOnClose
            >
                {configModal.content}
            </ModalAntd>
        </>
    );
}
