import { Button, Col, Form, Input, Row, Select } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { ProductGroupContext } from "../../context/ProductGroupContext";
import { types } from "../../pages/ProductGroup";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;

interface FormProductGroup {
    setOpen: any;
    cont?: any;
}

export function FormProductGroup(props: FormProductGroup) {
    const { postProductGroup } = useContext(ProductGroupContext);
    const [validateName, setValidateName] = useState(props.cont ? true : false);
    const [validateType, setValidateType] = useState(props.cont ? true : false);
    const [validate, setValidate] = useState(false);

    useEffect(() => {
        if (validateType && validateName) {
            setValidate(true);
        } else {
            setValidate(false);
        }
    }, [validateName, validateType]);

    return (
        <>
            <Form
                layout="vertical"
                onFinish={(v: any) => {
                    postProductGroup(v);
                    props.setOpen(false);
                }}
                initialValues={props.cont}
            >
                {props.cont && (
                    <Form.Item
                        name="id_product_group"
                        style={{ display: "none" }}
                    >
                        <Input type="hidden" name="id_product_group"></Input>
                    </Form.Item>
                )}
                <Row gutter={[12, 0]}>
                    <Col span={16}>
                        <Form.Item
                            label="Nome da Categoria:"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Coloque um Nome",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setValidateName(false),
                                                Promise.reject()
                                            );
                                        }
                                        setValidateName(true);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="Digite o nome" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Tipo:" name="type">
                            <Select
                                placeholder="Selecione o tipo"
                                onChange={() => {
                                    setValidateType(true);
                                }}
                            >
                                {types.map((t: any) => {
                                    return (
                                        <Option value={t.id} key={uuidv4()}>
                                            {t.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        disabled={!validate}
                    >
                        Salvar Cadastro
                    </Button>
                </div>
            </Form>
        </>
    );
}
