import {
    Button,
    Col,
    DatePicker,
    Divider,
    Empty,
    Form,
    Input,
    InputNumber,
    List,
    Popconfirm,
    Row,
    Select,
    Upload,
} from "antd";
import { Modal as ModalAntd } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload";
import { PersonContext } from "../../context/PersonContext";
import { CreditContext } from "../../context/CreditContext";
import {
    convertGranos,
    currencyFormatter,
    currencyFormatterGranos,
    currencyParser,
} from "../../helpers";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import "moment/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";
import { LoginContext } from "../../context/LoginContext";
import { statusCred } from "../../pages/Credit";

const { Option } = Select;

const { TextArea } = Input;

export interface FormCreditProps {
    add?: boolean;
    type: Number;
    record?: any;
    typesCredit?: any;
    user?: any;

    setSelectedRowsArray?: any;
    setSelectedCredits?: any;
}

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });

export function FormCredit(props: FormCreditProps) {
    const [isDisable, setIsDisable] = useState(false);
    const [disabledCredit, setDisabledCredit] = useState(true);
    const [anexAdd, setAnexAdd] = useState(true);
    const [date, setDate] = useState<any>(
        props.record ? props.record.expire_date.split("T")[0] : ""
    );
    // const [cDate, setCDate] = useState(false);
    const [validateDate, setValidateDate] = useState(false);
    const [validateValue, setValidateValue] = useState(false);
    const [customer, setCustomer] = useState(false);
    const [valueCred, setValueCred] = useState(
        props.record ? props.record.value_granos : 0
    );
    const [status, setStatus] = useState(
        props.record ? props.record.status : statusCred[0].id
    );
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [validateType, setValidateType] = useState(
        props.record ? true : false
    );
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");

    const [validateDesc, setValidateDesc] = useState(false);

    const {
        stateC,
        dispatch,
        postCredit,
        getCreditFiles,
        uploadCreditFile,
        delCreditFiles,
    } = useContext(CreditContext);
    const { person } = useContext(PersonContext);
    const { stateL } = useContext(LoginContext);

    const [form] = useForm();

    const propss: UploadProps = {
        beforeUpload: file => {
            setFiles(files.concat(file));

            return false;
        },
    };

    const handleDisable = useCallback(() => {
        if (validateDesc === true) {
            return false;
        }
        return true;
    }, [validateDesc]);

    useEffect(() => {
        if (props.type === 2) {
            getCreditFiles(props.record.id_credit);
        }
    }, []);

    // useEffect(() => {
    //     if (data) {
    //         let dataTemp = new Date(data.getTime())
    //             .toISOString()
    //             .split("T")[0]
    //             .concat("T23:59:59");
    //         form.setFieldsValue({
    //             date: new Date(Date.now())
    //                 .toISOString()
    //                 .split("T")[0]
    //                 .concat("T23:59:59"),
    //             expire_date: dataTemp,
    //         });
    //         setExp(true);
    //     }
    // }, [data]);

    useEffect(() => {
        if (
            (validateDate && validateValue && customer && validateType) ||
            !props.add
        ) {
            setDisabledCredit(false);
        }
        if (props.type === 2) {
            setDisabledCredit(true);
        }
    }, [validateDate, validateValue, customer, validateType]);

    function handleSelect(value: any) {
        form.setFieldsValue({ person_customer: value });
        setCustomer(true);
    }

    const handleUpload = (f: any) => {
        dispatch({ type: "set_uploading", payload: { uploading: true } });
        files.forEach(file => {
            let formData = new FormData();
            formData.append("file", file.originFileObj as RcFile);
            if (f.description) {
                formData.append("content", f.description + " - " + file.name);
            }
            formData.append("credit_temp", props.record.id_credit);

            uploadCreditFile(formData);
        });
    };

    const handleSave = (v: any) => {
        let dateFormat = date.concat("T23:59:59");
        if (props.record) {
            let obj = {
                id_credit: props.record.id_credit,
                date: props.record.date,
                detail: v.detail,
                expire_date: dateFormat,
                person_customer_temp: props.record.person_customer_temp,
                person_provider_temp: props.record.person_provider_temp,
                status: v.status,
                type: v.type,
                value: v.value,
                value_granos: valueCred,
                doc: v.doc,
            };
            postCredit(obj);
            props.setSelectedRowsArray([]);
            props.setSelectedCredits([]);
        } else {
            let obj = {
                date: "",
                detail: v.detail,
                expire_date: dateFormat,
                person_customer_temp: v.person_customer_temp,
                status: v.status,
                type: v.type,
                value: v.value,
                value_granos: valueCred,
                doc: v.doc,
            };

            postCredit(obj);
        }
    };

    const handleFiles: UploadProps["onChange"] = ({
        fileList: newFileList,
    }) => {
        setFiles(newFileList);
    };

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewVisible(true);
    };

    useEffect(() => {
        if (props.record !== undefined) {
            if (props.record.status === "finished") {
                setIsDisable(true);
            }
        }
    }, [props.record]);

    useEffect(() => {
        if (stateC.creditFiles) {
            if (stateC.creditFiles.length > 0) {
                setValidateDesc(true);
            }
        }
    }, [stateC]);

    return (
        <>
            {props.type === 2 && (
                <>
                    {" "}
                    {stateC.creditFiles && stateC.creditFiles.length > 0 ? (
                        <Button
                            disabled={isDisable}
                            style={{
                                display:
                                    stateC.creditFiles &&
                                    stateC.creditFiles.length > 0
                                        ? "hidden"
                                        : "inline-block",
                                textAlign: "end",
                                marginBottom: "10px",
                                marginTop: "-10px",
                            }}
                            type="link"
                            block
                            onClick={() => {
                                setAnexAdd(!anexAdd);
                                setValidateDesc(false);
                            }}
                        >
                            {stateC.creditFiles &&
                                stateC.creditFiles.length > 0 &&
                                anexAdd &&
                                "Adicionar Anexo"}
                            {stateC.creditFiles &&
                                stateC.creditFiles.length > 0 &&
                                !anexAdd &&
                                "Cancelar Anexo"}
                        </Button>
                    ) : (
                        <>
                            {isDisable ? (
                                <>
                                    {" "}
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Empty
                                            description="Não foram carregados arquivos de mídia para esse pedido."
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Form
                                        layout="vertical"
                                        onFinish={f => handleUpload(f)}
                                    >
                                        <Row gutter={12}>
                                            <Col span={10}>
                                                <Form.Item
                                                    label="Descrição:"
                                                    name="description"
                                                    style={{ width: "100%" }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Coloque uma Descrição",
                                                        },
                                                        () => ({
                                                            validator(
                                                                _,
                                                                value
                                                            ) {
                                                                if (!value) {
                                                                    return (
                                                                        setValidateDesc(
                                                                            false
                                                                        ),
                                                                        Promise.reject()
                                                                    );
                                                                }
                                                                setValidateDesc(
                                                                    true
                                                                );
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <Input
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        placeholder="Escreva a descrição do anexo"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item
                                                    label="Arquivo:"
                                                    name="file"
                                                >
                                                    <Upload
                                                        listType="picture"
                                                        //className="upload-credit"
                                                        {...propss}
                                                        onPreview={
                                                            handlePreview
                                                        }
                                                        onChange={handleFiles}
                                                    >
                                                        <Button
                                                            icon={
                                                                <UploadOutlined />
                                                            }
                                                        >
                                                            Selecione o arquivo
                                                        </Button>
                                                    </Upload>
                                                    <ModalAntd
                                                        visible={previewVisible}
                                                        onCancel={() =>
                                                            setPreviewVisible(
                                                                false
                                                            )
                                                        }
                                                        footer={null}
                                                        width={500}
                                                    >
                                                        <img
                                                            alt="example"
                                                            style={{
                                                                width: "100%",
                                                                padding: "20px",
                                                            }}
                                                            src={previewImage}
                                                        />
                                                    </ModalAntd>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={stateC.uploading}
                                            block
                                            disabled={
                                                files.length <= 0 ||
                                                handleDisable()
                                            }
                                        >
                                            Salvar anexo
                                        </Button>
                                    </Form>
                                </>
                            )}
                        </>
                    )}
                    {stateC.creditFiles &&
                        stateC.creditFiles.length > 0 &&
                        !anexAdd && (
                            <>
                                <Form
                                    layout="vertical"
                                    onFinish={f => handleUpload(f)}
                                >
                                    <Row gutter={12}>
                                        <Col span={10}>
                                            <Form.Item
                                                label="Descrição:"
                                                name="description"
                                                style={{ width: "100%" }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Coloque uma Descrição",
                                                    },
                                                    () => ({
                                                        validator(_, value) {
                                                            if (!value) {
                                                                return (
                                                                    setValidateDesc(
                                                                        false
                                                                    ),
                                                                    Promise.reject()
                                                                );
                                                            }
                                                            setValidateDesc(
                                                                true
                                                            );
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input
                                                    style={{ width: "100%" }}
                                                    placeholder="Escreva a descrição do anexo"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={14}>
                                            <Form.Item
                                                label="Arquivo:"
                                                name="file"
                                            >
                                                <Upload
                                                    listType="picture"
                                                    // className="upload-credit"
                                                    {...propss}
                                                    onPreview={handlePreview}
                                                    onChange={handleFiles}
                                                >
                                                    <Button
                                                        icon={
                                                            <UploadOutlined />
                                                        }
                                                    >
                                                        Selecione o arquivo
                                                    </Button>
                                                </Upload>
                                                <ModalAntd
                                                    visible={previewVisible}
                                                    onCancel={() =>
                                                        setPreviewVisible(false)
                                                    }
                                                    footer={null}
                                                    width={500}
                                                >
                                                    <img
                                                        alt="example"
                                                        style={{
                                                            width: "100%",
                                                            padding: "20px",
                                                        }}
                                                        src={previewImage}
                                                    />
                                                </ModalAntd>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={stateC.uploading}
                                        disabled={
                                            files.length <= 0 || handleDisable()
                                        }
                                        block
                                    >
                                        Salvar anexo
                                    </Button>
                                </Form>
                            </>
                        )}
                    {stateC.creditFiles &&
                        stateC.creditFiles.length > 0 &&
                        anexAdd && (
                            <>
                                {" "}
                                <List
                                    dataSource={stateC.creditFiles}
                                    renderItem={item => (
                                        <List.Item
                                            actions={[
                                                <>
                                                    <Button
                                                        type="link"
                                                        onClick={() => {
                                                            let link: any =
                                                                document.createElement(
                                                                    "a"
                                                                );
                                                            fetch(
                                                                `${process.env.REACT_APP_BASE_URL_API}api/credit_files/download/${item.id_credit_files}`,
                                                                {
                                                                    method: "GET",
                                                                    headers: {
                                                                        Authorization:
                                                                            "Bearer " +
                                                                            localStorage.getItem(
                                                                                "token"
                                                                            ),
                                                                    },
                                                                }
                                                            )
                                                                .then(
                                                                    (
                                                                        response: any
                                                                    ) => {
                                                                        if (
                                                                            response.ok
                                                                        ) {
                                                                            return response.blob();
                                                                        } else {
                                                                            if (
                                                                                response.status ===
                                                                                401
                                                                            ) {
                                                                                console.log(
                                                                                    "erro no token"
                                                                                );
                                                                            }
                                                                        }
                                                                    }
                                                                )
                                                                .then(res => {
                                                                    if (
                                                                        res.erro
                                                                    ) {
                                                                        console.log(
                                                                            res.erro
                                                                        );
                                                                    } else {
                                                                        let teste =
                                                                            URL.createObjectURL(
                                                                                res
                                                                            );
                                                                        link.href =
                                                                            teste; //
                                                                        link.setAttribute(
                                                                            "download",
                                                                            `${
                                                                                item.description
                                                                            }.${
                                                                                item.file.split(
                                                                                    "."
                                                                                )[1]
                                                                            }`
                                                                        );
                                                                        link.click();
                                                                        // setAva();
                                                                    }
                                                                })
                                                                .catch(
                                                                    error => {
                                                                        console.log(
                                                                            error
                                                                        );
                                                                    }
                                                                );
                                                        }}
                                                    >
                                                        Download
                                                    </Button>
                                                </>,
                                                <Popconfirm
                                                    disabled={isDisable}
                                                    title="Você tem certeza que vai cancelar esse arquivo?"
                                                    okText="Sim"
                                                    cancelText="Cancelar"
                                                    okType="danger"
                                                    onConfirm={() => {
                                                        delCreditFiles(
                                                            item.id_credit_files
                                                        );
                                                    }}
                                                >
                                                    <Button
                                                        disabled={isDisable}
                                                        type="link"
                                                        style={{
                                                            marginLeft: 12,
                                                            color: isDisable
                                                                ? "rgba(0, 0, 0, 0.25)"
                                                                : "red",
                                                        }}
                                                    >
                                                        Excluir
                                                    </Button>
                                                </Popconfirm>,
                                            ]}
                                        >
                                            <List.Item.Meta
                                                title={item.description}
                                            />
                                        </List.Item>
                                    )}
                                >
                                    {" "}
                                </List>
                            </>
                        )}
                </>
            )}
            {props.type !== 2 && (
                <>
                    <Form
                        initialValues={
                            props.record
                                ? {
                                      ...props.record,
                                      expire_date: moment(
                                          props.record.expire_date,
                                          "YYYY-MM-DDTHH:mm:ss"
                                      ),
                                  }
                                : {
                                      status: status,
                                  }
                        }
                        form={form}
                        layout="vertical"
                        onFinish={v => {
                            handleSave(v);
                        }}
                        disabled={props.type === 3}
                    >
                        {!props.add && (
                            <>
                                <Form.Item
                                    name="id_credit"
                                    style={{ display: "none" }}
                                >
                                    <Input type="hidden" />
                                </Form.Item>
                            </>
                        )}
                        <Row gutter={[24, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    name="date"
                                    style={{ display: "none" }}
                                >
                                    <Input type="hidden" />
                                </Form.Item>

                                <Form.Item
                                    valuePropName="select"
                                    name="person_customer_temp"
                                    className="form-credit"
                                    label="Consumidor"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Selecione um consumidor",
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={!props.add}
                                        onChange={c => handleSelect(c)}
                                        showSearch
                                        placeholder="Selecione um Consumidor"
                                        defaultValue={
                                            props.record
                                                ? props.record
                                                      .person_customer_temp
                                                : undefined
                                        }
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            if (!isNaN(Number(input))) {
                                                return String(
                                                    option?.key
                                                ).includes(input + "");
                                            } else {
                                                return String(option?.children)
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    );
                                            }
                                        }}
                                    >
                                        {person.map((p: any) => (
                                            <Option
                                                value={p.id_person}
                                                key={p.identification}
                                            >
                                                {p.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Selecione uma data",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidateDate(false),
                                                        Promise.reject()
                                                    );
                                                }
                                                setValidateDate(true);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                    label="Data de expiração"
                                    name="expire_date"
                                >
                                    {/* <Input
                                        placeholder="Selecione a data"
                                        readOnly={true}
                                        onClick={() => setCDate(true)}
                                    /> */}
                                    <DatePicker
                                        locale={locale}
                                        format="YYYY-MM-DD"
                                        onChange={(val: any, val2: any) => {
                                            setDate(val2);
                                        }}
                                        //calendarClassName="calendar-credit"
                                        disabledDate={current => {
                                            // Can not select days after today and before start Date

                                            return (
                                                current <
                                                moment().startOf("day")
                                            );
                                        }}
                                        style={{
                                            color: "black",
                                            width: "100%",
                                        }}
                                        // isCalendarOpen={cDate}
                                        // onCalendarClose={() => setCDate(false)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col span={6}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Selecione uma opção",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidateType(false),
                                                        Promise.reject()
                                                    );
                                                }
                                                setValidateType(true);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                    label="Tipo do Crédito:"
                                    name="type"
                                >
                                    <Select placeholder="Selecione">
                                        {props.typesCredit.map((i: any) => {
                                            return (
                                                <Option
                                                    key={uuidv4()}
                                                    value={i.id}
                                                >
                                                    {i.label}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Nº Nota Fiscal:" name="doc">
                                    <Input placeholder="Digite o nº da nota" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    style={{ width: "100%" }}
                                    label="Status"
                                    name="status"
                                >
                                    <Select
                                        placeholder="Selecione"
                                        // disabled={
                                        //     props.user.login.type !== "MASTER"
                                        // }
                                    >
                                        {statusCred.map((i: any) => {
                                            return (
                                                <Option
                                                    key={uuidv4()}
                                                    value={i.id}
                                                    disabled={
                                                        props.user.login
                                                            .type !== "MASTER"
                                                            ? i.id !==
                                                                  "pending" &&
                                                              i.id !== "waiting"
                                                            : props.record
                                                                  .status ===
                                                                  "pending" &&
                                                              i.id !== "pending"
                                                    }
                                                >
                                                    {i.label}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col span={12}>
                                <Form.Item label="Detalhes:" name="detail">
                                    <TextArea
                                        placeholder="Digite os detalhes do crédito"
                                        showCount
                                        maxLength={100}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider
                            style={{
                                marginTop: "5px",
                                marginBottom: "10px",
                            }}
                        />{" "}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: "10px",
                                fontStyle: "italic",
                                color: "rgba(0, 0, 0, 0.6)",
                            }}
                        >
                            Informações de valor e conversão em Granos
                        </div>
                        <Row gutter={[24, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    label="Valor do Crédito:"
                                    name="value"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Digite um valor",
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidateValue(false),
                                                        Promise.reject()
                                                    );
                                                }
                                                if (value > 0) {
                                                    return (
                                                        setValidateValue(true),
                                                        Promise.resolve()
                                                    );
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        min={0.01}
                                        addonAfter="$"
                                        placeholder="Valor em Reais"
                                        step={10}
                                        formatter={value =>
                                            currencyFormatter(value)
                                        }
                                        parser={val => currencyParser(val)}
                                        onChange={(value: any) => {
                                            setValueCred(convertGranos(value));
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Valor em Granos:"
                                    name="value_granos"
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            fontStyle: "italic",
                                            color: "rgba(0, 0, 0, 0.6)",
                                            border: "1px solid rgba(0, 0, 0, 0.1)",
                                            borderRadius: "2px",
                                            padding: "0 11px",
                                            height: "30px",
                                            marginTop: "8px",
                                        }}
                                    >
                                        {currencyFormatterGranos(valueCred)}
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={disabledCredit}
                            block
                        >
                            {props.add
                                ? "Salvar novo crédito"
                                : "Editar crédito"}
                        </Button>
                    </Form>
                </>
            )}
        </>
    );
}
