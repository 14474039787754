import { EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { SignIn, UserCircle } from "phosphor-react";
import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../context/LoginContext";
import { IPerson } from "../types";
import { FormPerson } from "./Forms/FormPerson";
import { Modal } from "./Modal";

interface MenuUserProps {
    user: any;
    setPopOpen?: any;
}

export function MenuUser(props: MenuUserProps) {
    const { stateL, getPersonLogin } = useContext(LoginContext);
    const [open, setOpen] = useState(false);
    const [configModal, setConfigModal] = useState({
        title: "",
        content: <></>,
    });
    useEffect(() => {
        getPersonLogin();
    }, []);

    function handleEdit(person: any) {
        setConfigModal({
            title: "Editar Pessoa",
            content: (
                <FormPerson setOpen={setOpen} cont={person} user={props.user} />
            ),
        });

        setOpen(true);
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "10px 20px",
                width: "250px",
                fontSize: "25px",
            }}
        >
            <UserCircle size={80} />
            <div>{stateL.PersonLogin?.name}</div>
            <hr style={{ width: "85%", margin: "0px" }} />
            <div
                style={{
                    fontSize: "15px",
                    textOverflow: "ellipsis",
                }}
            >
                {stateL.PersonLogin?.admin_email}
                <Button
                    onClick={() => {
                        props.setPopOpen(false);
                        handleEdit(stateL.PersonLogin);
                    }}
                    type="text"
                    style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginTop: "10px",
                    }}
                >
                    <EditOutlined />
                    Gerenciar Informaçoes
                </Button>
            </div>
            <div style={{ display: "flex", columnGap: "10px" }}>
                <Popconfirm
                    title="Você tem certeza que deseja sair?"
                    okText="Sim"
                    cancelText="Cancelar"
                    okType="danger"
                    onConfirm={() => {
                        localStorage.removeItem("token");
                        window.location.reload();
                    }}
                >
                    <Button
                        type="primary"
                        style={{
                            display: "flex",
                            marginTop: "20px",
                        }}
                    >
                        <SignIn
                            size={20}
                            style={{
                                marginRight: "10px",
                            }}
                        />
                        Sair
                    </Button>
                </Popconfirm>
            </div>

            <Modal
                title={configModal.title}
                isVisible={open}
                width={800}
                setIsVisible={() => setOpen(false)}
            >
                {configModal.content}
            </Modal>
        </div>
    );
}
