import { Button, Empty, Popconfirm, Table } from "antd";
import { PencilSimple, Trash } from "phosphor-react";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { FormProductGroup } from "../components/Forms/FormProductGroup";
import { Modal } from "../components/Modal";
import { ProductGroupContext } from "../context/ProductGroupContext";

export const types = [
    { id: "0", label: "Produto" },
    { id: "1", label: "Serviço" },
];

export function ProductGroup() {
    const [open, setOpen] = useState(false);
    const [which, setWhich] = useState(0);
    const [configModal, setConfigModal] = useState({
        title: "",
        content: <></>,
    });
    const { productGroup, getProductGroup, delProductGroup } =
        useContext(ProductGroupContext);

    function handleAdd() {
        setConfigModal({
            title: "Adicionar Categoria",
            content: <FormProductGroup setOpen={setOpen} />,
        });

        setOpen(true);
    }

    function handleDelete(id: any) {
        delProductGroup(id);
    }

    function handleEdit(value: any) {
        // const productKey = defaultProduct.products?.findIndex(
        //     (prod: any) => prod.id_product === id
        // );
        setConfigModal({
            title: "Editar Produto",
            content: <FormProductGroup setOpen={setOpen} cont={value} />,
        });
        setOpen(true);
    }

    useEffect(() => {
        getProductGroup();
    }, []);

    const dataSource = productGroup;
    const columns = [
        // {
        //     key: "1",
        //     title: "ID",
        //     dataIndex: "id",
        // },
        {
            key: "1",
            title: "Nome da categoria",
            dataIndex: "name",
        },
        {
            key: "2",
            title: "Grupo",
            dataIndex: "type",
            render: (n: any) => {
                let t = "";
                if (n) {
                    t = types[types.findIndex((t: any) => t.id === n)].label;
                }

                return t;
            },
        },

        {
            key: "3",
            title: "Ações",
            render: (record: any) => {
                return (
                    <>
                        <Button
                            //disabled={!isVisible}
                            type="link"
                            onClick={() => {
                                setWhich(record.id_product_group);
                                handleEdit(record);
                            }}
                        >
                            <PencilSimple size={18} />
                        </Button>

                        <Popconfirm
                            title="Você tem certeza que vai deletar essa categoria?"
                            okText="Sim"
                            cancelText="Cancelar"
                            okType="danger"
                            onConfirm={() => handleDelete(which)}
                            placement="topRight"
                        >
                            <Button
                                type="link"
                                onClick={() => {
                                    setWhich(record.id_product_group);
                                }}
                                style={{
                                    color: "red",
                                }}
                            >
                                <Trash size={18} />
                            </Button>
                        </Popconfirm>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div
                style={{
                    width: "100%",
                    padding: "10px 50px 10px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <h1> Cadastro de categorias:</h1>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Button onClick={() => handleAdd()}>Adicionar</Button>
                    </div>
                </div>
                <div>
                    <Table
                        size="small"
                        columns={columns}
                        dataSource={dataSource}
                        locale={{
                            emptyText: (
                                <Empty
                                    description="Nenhuma categoria foi cadastrada"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            ),
                        }}
                    ></Table>
                </div>
            </div>
            <Modal
                title={configModal.title}
                isVisible={open}
                width={600}
                setIsVisible={() => setOpen(false)}
            >
                {configModal.content}
            </Modal>
        </>
    );
}
