import { Button, Empty, Pagination, Popconfirm, Table, Tooltip } from "antd";
import { Eye, PencilSimple, Trash } from "phosphor-react";
import { useContext, useEffect, useState } from "react";
import { FormPerson } from "../components/Forms/FormPerson";
import { Modal } from "../components/Modal";
import { PersonContext } from "../context/PersonContext";
import { currencyFormatterGranos } from "../helpers";

export const types = [
    { id: "CUSTOMER", label: "Cliente" },
    { id: "PARTNER", label: "Parceiro" },
    { id: "PROVIDER", label: "Empresa" },
];

export const occupations = [
    { id: "0", label: "Propriedade Rural/Fazenda" },
    { id: "1", label: "Grupo Agrícola" },
    { id: "2", label: "Consultoria" },
    { id: "3", label: "Cooperativa" },
    { id: "4", label: "Escritório Contábil" },
    { id: "5", label: "Revenda de Insumos" },
    { id: "6", label: "Outro" },
];

export const from = [
    { id: "0", label: "Anúncio" },
    { id: "1", label: "Indicação" },
    { id: "2", label: "Redes Sociais" },
    { id: "3", label: "Pesquisa no Google" },
    { id: "4", label: "Outro" },
];

export interface IPersonProps {
    user: any;
}

export function Person(props: IPersonProps) {
    const [open, setOpen] = useState(false);
    const [configModal, setConfigModal] = useState({
        title: "",
        content: <></>,
    });
    const [which, setWhich] = useState(0);
    const [isVisible, setIsVisible] = useState(true);

    const { person, delPerson, getPerson, setLoading } =
        useContext(PersonContext);

    useEffect(() => {
        getPerson();
    }, []);

    useEffect(() => {
        if (props.user.login !== undefined) {
            if (props.user.login.type !== "MASTER") {
                setIsVisible(false);
            }
        }
    }, [props.user]);

    function handleDelete(id: any) {
        delPerson(id);
    }

    function handleAdd() {
        setConfigModal({
            title: "Adicionar Pessoa",
            content: <FormPerson setOpen={setOpen} user={props.user} />,
        });
        setOpen(true);
        setLoading(true);
    }
    function handleEdit(id: any) {
        const personKey = person.findIndex((per: any) => per.id_person === id);
        setConfigModal({
            title: isVisible ? "Editar Pessoa" : "Visualizar Pessoa",
            content: (
                <FormPerson
                    typeForm={isVisible ? 0 : 1}
                    setOpen={setOpen}
                    user={props.user}
                    cont={person[personKey]}
                />
            ),
        });

        setOpen(true);
        setLoading(true);
    }

    const dataSource = person;
    const columns = [
        // {
        //     key: "1",
        //     title: "ID",
        //     dataIndex: "id",
        // },
        {
            key: "1",
            title: "Nome",
            dataIndex: "name",
        },
        {
            key: "2",
            title: "Apelido",
            dataIndex: "alias",
        },
        {
            key: "3",
            title: "Endereço",
            dataIndex: "address",
            width: 400,
            ellipsis: {
                showTitle: false,
            },
            render: (address: any) => (
                <Tooltip placement="topLeft" title={address}>
                    {address}
                </Tooltip>
            ),
        },
        {
            key: "4",
            title: "Perfil",
            dataIndex: "type",
            render: (text: any) => {
                let t = "";
                if (text) {
                    if (text !== "MASTER") {
                        t =
                            types[types.findIndex((t: any) => t.id === text)]
                                .label;
                    } else {
                        t = text;
                    }
                }

                return t;
            },
        },
        {
            key: "5",
            title: "Saldo",
            render: (record: any) => {
                let res;
                if (record.type !== "CUSTOMER") {
                    res = "";
                } else res = currencyFormatterGranos(record.cash) + " granos";
                return res;
            },
        },
        {
            key: "6",
            title: "Ações",
            render: (record: any) => {
                return (
                    <>
                        <Button
                            //disabled={!isVisible}
                            type="link"
                            onClick={() => {
                                setWhich(record.id_person);

                                handleEdit(record.id_person);
                            }}
                        >
                            {" "}
                            {isVisible ? (
                                <PencilSimple size={18} />
                            ) : (
                                <Eye size={18} style={{ color: "black" }} />
                            )}
                        </Button>

                        <Popconfirm
                            disabled={!isVisible}
                            title="Você tem certeza que vai deletar esse contato?"
                            okText="Sim"
                            cancelText="Cancelar"
                            okType="danger"
                            onConfirm={() => handleDelete(which)}
                            placement="topRight"
                        >
                            <Button
                                disabled={!isVisible}
                                type="link"
                                onClick={() => {
                                    setWhich(record.id_person);
                                }}
                                style={{
                                    color: isVisible
                                        ? "red"
                                        : "rgba(0, 0, 0, 0.25)",
                                }}
                            >
                                <Trash size={18} />
                            </Button>
                        </Popconfirm>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div
                style={{
                    width: "100%",
                    padding: "10px 50px 10px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <h1> Cadastro de pessoa:</h1>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Button onClick={() => handleAdd()}>Adicionar</Button>
                    </div>
                </div>
                <div>
                    <Table
                        size="small"
                        columns={columns}
                        dataSource={dataSource}
                        locale={{
                            emptyText: (
                                <Empty
                                    description="Nenhum usuário foi cadastrado"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            ),
                        }}
                    ></Table>{" "}
                    {/* <div
                        style={{
                            display: "flex",
                            justifyContent: "right",
                            marginTop: "10px",
                            marginRight: "1vw",
                        }}
                    ></div> */}
                </div>
            </div>

            <Modal
                title={configModal.title}
                isVisible={open}
                width={850}
                setIsVisible={() => setOpen(false)}
            >
                {configModal.content}
            </Modal>
        </>
    );
}
