export function convertGranos(value: any) {
    if (isNaN(value / 0.02)) {
        return 0;
    } else {
        return parseFloat((value / 0.02).toFixed(2));
    }
}

export function currencyFormatterGranos(value: any) {
    return new Intl.NumberFormat("pt-br").format(value);
}

export function currencyFormatter(value: any) {
    return new Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL",
    }).format(value);
}

export function currencyParser(val: any) {
    try {
        // for when the input gets clears
        if (typeof val === "string" && !val.length) {
            val = "0.0";
        }

        // detecting and parsing between comma and dot
        var group = new Intl.NumberFormat("pt-br")
            .format(1111)
            .replace(/1/g, "");
        var decimal = new Intl.NumberFormat("pt-br")
            .format(1.1)
            .replace(/1/g, "");
        var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
        reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
        //  => 1232.21 €

        // removing everything except the digits and dot
        reversedVal = reversedVal.replace(/[^0-9.]/g, "");
        //  => 1232.21

        // appending digits properly
        const digitsAfterDecimalCount = (reversedVal.split(".")[1] || [])
            .length;
        const needsDigitsAppended = digitsAfterDecimalCount > 2;

        if (needsDigitsAppended) {
            reversedVal =
                reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
        }

        return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
        console.error(error);
    }
}
