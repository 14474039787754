import { ICredit } from "../types";
import { v4 as uuidv4 } from "uuid";
import { Button, Col, Form, Row, Select } from "antd";
import { CreditContext } from "../context/CreditContext";
import { useContext, useState } from "react";
import { statusCred } from "../pages/Credit";

const { Option } = Select;

export interface EditCreditProps {
    selectedCredits: ICredit[];
    setVisible: any;
    user: any;

    setSelectedRowsArray: any;
    setSelectedCredits: any;
}

export function FormEditCredits(props: EditCreditProps) {
    const { postCredit } = useContext(CreditContext);

    const [disableButton, setDisableButton] = useState(true);

    return (
        <>
            <Form
                layout="vertical"
                onFinish={(v: any) =>
                    props.selectedCredits.forEach((c: ICredit) => {
                        let obj = {
                            id_credit: c.id_credit,
                            date: c.date,
                            detail: c.detail,
                            expire_date: c.date,
                            person_customer_temp: c.person_customer_temp,
                            person_provider_temp: c.person_provider_temp,
                            status: v.status,
                            type: c.type,
                            value: c.value,
                            value_granos: c.value_granos,
                            doc: c.doc,
                        };
                        postCredit(obj);

                        props.setVisible(false);
                        props.setSelectedRowsArray([]);
                        props.setSelectedCredits([]);
                    })
                }
            >
                <Row gutter={[24, 0]}>
                    <Col span={12}>
                        <Form.Item
                            style={{ width: "100%" }}
                            label="Status"
                            name="status"
                            rules={[
                                {
                                    required: true,
                                    message: "Selecione um status",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) {
                                            return (
                                                setDisableButton(true),
                                                Promise.reject()
                                            );
                                        }
                                        setDisableButton(false);
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Select placeholder="Selecione">
                                {statusCred.map((i: any) => {
                                    return (
                                        <Option
                                            key={uuidv4()}
                                            value={i.id}
                                            disabled={
                                                props.user.login.type !==
                                                "MASTER"
                                                    ? i.id !== "pending" &&
                                                      i.id !== "waiting"
                                                    : false
                                            }
                                        >
                                            {i.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Button
                    disabled={disableButton}
                    type="primary"
                    htmlType="submit"
                    block
                >
                    Salvar alterações
                </Button>
            </Form>
        </>
    );
}
