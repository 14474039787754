import { createContext, useContext, useEffect, useState } from "react";
import { IPerson } from "../types";
import { LoginContext } from "./LoginContext";

import { message } from "antd";

const personObj: any = {
    person: [{} as IPerson],
    getPerson: () => {},
};

export const PersonContext = createContext(personObj);

export function PersonContextProvider({ children }: { children: any }) {
    const [person, setPerson] = useState<IPerson[]>([]);
    const [loading, setLoading] = useState(true);
    const { dispatchL } = useContext(LoginContext);

    function getPerson() {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/person/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    setPerson(res);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const addPerson = (value = {}) => {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/person/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 403) {
                        message.error("Usuário já cadastrado");
                    }
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    message.error("Ocorreu um erro ao criar usuário!");
                } else {
                    message.success("Usuário salvo com sucesso!");
                    setLoading(false);
                    getPerson();
                }
            })
            .catch(error => {
                console.log("catch", error);
            });
    };

    const editPerson = (value = {}) => {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/person/update`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    message.error("Ocorreu um erro ao editar usuário!");
                } else {
                    message.success("Usuário editado com sucesso!");
                    setLoading(false);
                    getPerson();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const delPerson = (value: string) => {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/person/` + value, {
            method: "delete",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    message.error("Ocorreu um erro ao deletar usuário!");
                } else {
                    message.success("Usuário deletado com sucesso!");
                    setLoading(false);
                    getPerson();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    return (
        <PersonContext.Provider
            value={{
                person,
                loading,
                setLoading,
                addPerson,
                editPerson,
                delPerson,
                getPerson,
            }}
        >
            {children}
        </PersonContext.Provider>
    );
}

export default PersonContextProvider;
