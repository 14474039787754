import { UploadOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Collapse,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Upload,
} from "antd";
import { UploadFile } from "antd/es/upload/interface";
import { RcFile, UploadProps } from "antd/es/upload";
import { useContext, useEffect, useState, useCallback } from "react";
import { PreferenceContext } from "../../context/PreferenceContext";
import { Modal as ModalAntd } from "antd";
import { useForm } from "antd/lib/form/Form";
import { IStateL } from "../../context/LoginContext";
import ReactInputMask from "react-input-mask";
import TextArea from "antd/lib/input/TextArea";

const { Panel } = Collapse;

interface FormPreferenceProps {
    cont?: any;
    setOpen: any;
    landscapeImg?: any;
    thumbImg?: any;
    bannerLength?: number;

    user?: IStateL;
}

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });

export function FormPreference(props: FormPreferenceProps) {
    const [form] = useForm();
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [thumbFile, setThumbFile] = useState<any>();
    const [navbarFile, setNavbarFile] = useState<any>();
    const [landscapeFile, setLandscapeFile] = useState<any>();
    const [validateZipCode, setValidateZipCode] = useState(
        props.cont ? true : false
    );
    // const [validateDistrict, setValidateDistrict] = useState(
    //     props.cont ? true : false
    // );
    // const [validateStreet, setValidateStreet] = useState(
    //     props.cont ? true : false
    // );
    // const [validateNumber, setValidateNumber] = useState(
    //     props.cont ? true : false
    // );

    const arr: any = props.cont && props.cont.address?.split(" - ");

    // const handleDisable = useCallback(() => {
    //     if (
    //         validateZipCode &&
    //         validateDistrict &&
    //         validateStreet &&
    //         validateNumber
    //     ) {
    //         return false;
    //     }
    //     return true;
    // }, [validateZipCode, validateDistrict, validateStreet, validateNumber]);

    const { addPreference, postPreferenceFiles, editPreference } =
        useContext(PreferenceContext);

    const props_landscape: UploadProps = {
        accept: ".jpg, .jpeg, .png",
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                let maxSize = 5 * 1024 * 1024; //5MB
                let isMax = file.size > maxSize;

                let img = new Image();
                img.src = window.URL.createObjectURL(file);
                img.onload = () => {
                    if (isMax) {
                        message.error("Tamanho maior que o aceito");
                        resolve(true);
                    }
                    //no site a imagem fica na div 1450x250
                    if (
                        img.height > 305 &&
                        (img.width < 1080 || img.width > 1920)
                    ) {
                        message.error(
                            `${file.name} não corresponde as medidas necessárias`
                        );
                        resolve(true);
                    }
                    resolve(false);
                    setFileList(fileList.concat(file));
                };
            });
        },
    };
    const props_thumb: UploadProps = {
        accept: ".jpg, .jpeg, .png",
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                let maxSize = 5 * 1024 * 1024; //5MB
                let isMax = file.size > maxSize;

                let img = new Image();
                img.src = window.URL.createObjectURL(file);
                img.onload = () => {
                    if (isMax) {
                        message.error("Tamanho maior que o aceito");
                        resolve(true);
                    }
                    // if (img.width / img.height > 2) {
                    //     message.error(
                    //         `${file.name} não corresponde as medidas necessárias`
                    //     );
                    //     resolve(true);
                    // }

                    //no site a imagem fica na div 250x250
                    if (img.width > 300 && img.height > 300) {
                        message.error(
                            `${file.name} não corresponde as medidas necessárias`
                        );
                        resolve(true);
                    }
                    resolve(false);
                    setFileList(fileList.concat(file));
                };
            });
        },
    };
    const props_navbar_img: UploadProps = {
        accept: ".jpg, .jpeg, .png",
        beforeUpload(file, FileList) {
            return new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                let maxSize = 5 * 1024 * 1024; //5MB
                let isMax = file.size > maxSize;

                reader.addEventListener("load", event => {
                    const _loadedImageUrl = event.target?.result;
                    const image = document.createElement("img");
                    image.src = _loadedImageUrl as string;

                    image.addEventListener("load", () => {
                        const { width, height } = image;

                        if (isMax) {
                            message.error("Tamanho maior que o aceito");
                            resolve(true);
                        }
                        //no site a imagem fica na div 100x80
                        //validando pela altura
                        if (height > 75) {
                            message.error(
                                `${file.name} não corresponde as medidas necessárias`
                            );
                            resolve(true);
                        }

                        resolve(false);
                        setFileList(fileList.concat(file));
                    });
                });
            });
        },
    };

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewVisible(true);
    };

    const handleNavbar: UploadProps["onChange"] = (value: any) => {
        if (value.file.status === "error") {
            value.file.response = "Erro ao carregar a imagem";
        }
        setNavbarFile(value.file);
    };
    const handleThumb: UploadProps["onChange"] = (value: any) => {
        if (value.file.status === "error") {
            value.file.response = "Erro ao carregar a imagem";
        }
        setThumbFile(value.file);
    };
    const handleLandscape: UploadProps["onChange"] = ({
        fileList: newFileList,
        file,
    }) => {
        if (file.status === "error") {
            file.response = "Erro ao carregar a imagem";
        }
        setLandscapeFile(newFileList);
    };

    const handleSave = (v: any) => {
        let content;
        let validation = Object.values(v).filter((i: any) => i !== undefined);

        if (validation.length > 0) {
            content = JSON.stringify({
                contact_email: v.contact_email ? v.contact_email : "",
                invoice_email: v.invoice_email ? v.invoice_email : "",
                marketing_email: v.marketing_email ? v.marketing_email : "",
                document_exchange_rate: v.document_exchange_rate
                    ? v.document_exchange_rate.toString()
                    : "",
                phone: v.phone ? v.phone.replace(/[^a-z0-9]/gi, "") : "",
                address:
                    v.zip_code +
                    " - " +
                    v.street +
                    " - " +
                    v.number +
                    " - " +
                    v.district +
                    " - " +
                    (v.aditional ? v.aditional + " - " : "") +
                    v.city +
                    " - " +
                    v.state,
                web_page: v.web_page ? v.web_page : "",
                bio: v.bio ? v.bio : "",
                style: "",
            });
        }
        if (landscapeFile !== undefined) {
            landscapeFile.forEach((l: any) => {
                let landscapeFormData = new FormData();
                landscapeFormData.append("file", l.originFileObj);
                landscapeFormData.append(
                    "preference_temp",
                    props.cont.id_preference
                );

                postPreferenceFiles(landscapeFormData);
            });
        }
        if (props.cont) {
            let formData = new FormData();
            if (navbarFile !== undefined) {
                formData.append("navbar_img", navbarFile);
            } else {
                formData.append("navbar_img", props.cont.navbar_img);
            }

            if (thumbFile !== undefined) {
                formData.append("thumb", thumbFile);
            } else {
                formData.append("thumb", props.cont.thumb);
            }

            if (content) {
                formData.append("content", content);
            }
            editPreference(formData);
        } else {
            if (content !== undefined) {
                let formData = new FormData();
                if (navbarFile !== undefined) {
                    formData.append("navbar_img", navbarFile);
                } else {
                    formData.append("navbar_img", "");
                }
                if (thumbFile !== undefined) {
                    formData.append("thumb", thumbFile);
                } else {
                    formData.append("thumb", "");
                }

                if (content) {
                    formData.append("content", content);
                }
                addPreference(formData);
            } else {
                props.setOpen(false);
            }
        }
    };

    const callCepApi = async (value: any) => {
        if (value.length < 8) {
            return setValidateZipCode(false);
        } else {
            var cep = value.replace(/\D/g, "");
            var validacep = /^[0-9]{8}$/;
            if (validacep.test(cep)) {
                await fetch(`https://viacep.com.br/ws/${cep}/json/`)
                    .then(res => {
                        return res.json();
                    })
                    .then(data => {
                        if (data.hasOwnProperty("erro")) {
                            setValidateZipCode(false);
                            form.setFieldsValue({
                                city: "",
                                state: "",
                            });
                        } else {
                            setValidateZipCode(true);
                            form.setFieldsValue({
                                city: data.localidade,
                                state: data.uf,
                            });
                        }
                    });
            }
        }
    };

    return (
        <Form
            layout="vertical"
            form={form}
            initialValues={{
                ...props.cont,
                landscape: props.landscapeImg ? props.landscapeImg : "",
                thumb: props.thumbImg ? props.thumbImg : "",
                zip_code: arr ? arr[0] : "",
                city: arr ? arr[4] : "",
                state: arr ? arr[5] : "",
                district: arr ? arr[3] : "",
                street: arr ? arr[1] : "",
                number: arr ? arr[2] : "",
                aditional: arr ? arr[6] : "",
            }}
            onFinish={(v: any) => {
                props.setOpen(false);
                handleSave(v);
            }}
            scrollToFirstError
        >
            {" "}
            <>
                <Collapse accordion defaultActiveKey={1}>
                    <Panel header="Dados Pessoais" key={1}>
                        <Row gutter={[24, 0]}>
                            <Col span={8}>
                                <Form.Item
                                    label="Email para comercial:"
                                    name="invoice_email"
                                >
                                    <Input
                                        type={"email"}
                                        placeholder="Digite seu e-mail"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Email para marketing:"
                                    name="marketing_email"
                                >
                                    <Input
                                        type={"email"}
                                        placeholder="Digite seu e-mail"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Email para contato:"
                                    name="contact_email"
                                >
                                    <Input
                                        type={"email"}
                                        placeholder="Digite seu e-mail"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col span={8}>
                                <Form.Item label="Telefone:" name="phone">
                                    <ReactInputMask
                                        mask="(99) 9 9999-9999"
                                        id="phone"
                                        placeholder="Celular"
                                        className="input-mask"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                {props.user?.PersonLogin?.type !== "MASTER" && (
                                    <Form.Item
                                        label="Webpage: (site ou rede social)"
                                        name="web_page"
                                    >
                                        <Input
                                            type="url"
                                            placeholder="URL da sua webpage"
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                            <Col span={8}>
                                {props.user?.PersonLogin?.type ===
                                    "PROVIDER" && (
                                    <Form.Item
                                        label="Porcentagem do valor da NF:"
                                        name="document_exchange_rate"
                                    >
                                        <InputNumber
                                            defaultValue={0.01}
                                            min={0}
                                            step={0.01}
                                            formatter={value => `${value}%`}
                                            parser={(value: any) =>
                                                value!.replace("%", "")
                                            }
                                            placeholder="Digite o valor"
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col span={16}>
                                {props.user?.PersonLogin?.type !== "MASTER" && (
                                    <Form.Item label="Bio:" name="bio">
                                        <TextArea
                                            placeholder="Digite uma breve descrição sobre a empresa"
                                            showCount
                                            maxLength={1500}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                    </Panel>
                    <Panel
                        header="Dados de Endereço"
                        key={2}
                        forceRender={props.cont}
                    >
                        {" "}
                        <Row gutter={[24, 0]}>
                            <Col span={6}>
                                <Form.Item
                                    label="CEP:"
                                    name="zip_code"
                                    validateTrigger="onBlur"
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (value) {
                                                    if (
                                                        validateZipCode ===
                                                        false
                                                    ) {
                                                        return Promise.reject(
                                                            new Error(
                                                                "CEP inválido"
                                                            )
                                                        );
                                                    }
                                                    return Promise.resolve();
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        placeholder="Digite o CEP"
                                        //onBlur={e => callCepApi(e.target.value)}
                                        onChange={(e: any) =>
                                            callCepApi(e.target.value)
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label="Cidade:" name="city">
                                    <Input
                                        disabled
                                        placeholder="Cidade"
                                        name="city"
                                        id="city"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label="Estado:" name="state">
                                    <Input disabled placeholder="Estado" />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label="Bairro:" name="district">
                                    <Input placeholder="Bairro" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col span={8}>
                                <Form.Item
                                    label="Logradouro (rua, Avenida, etc):"
                                    name="street"
                                >
                                    <Input placeholder="Logradouro" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Número:" name="number">
                                    <Input placeholder="Número" />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    label="Complemento:"
                                    name="aditional"
                                >
                                    <Input placeholder="Complemento" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Panel>
                    <Panel
                        header="Upload de Imagens"
                        key={3}
                        forceRender={props.cont}
                    >
                        {" "}
                        <Row gutter={[24, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    label="Logo Barra de Navegação (até 5MB / altura máx. 80px)"
                                    name="navbar_img"
                                >
                                    <Upload
                                        listType="picture"
                                        maxCount={1}
                                        onPreview={handlePreview}
                                        {...props_navbar_img}
                                        onChange={handleNavbar}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            {" "}
                                            Upload
                                        </Button>
                                    </Upload>
                                    <ModalAntd
                                        visible={previewVisible}
                                        onCancel={() =>
                                            setPreviewVisible(false)
                                        }
                                        footer={null}
                                        width={500}
                                    >
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                                padding: "20px",
                                            }}
                                            src={previewImage}
                                        />
                                    </ModalAntd>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Logo da Empresa (até 5MB / 250x250)"
                                    name="thumb"
                                >
                                    <Upload
                                        listType="picture"
                                        maxCount={1}
                                        onPreview={handlePreview}
                                        {...props_thumb}
                                        onChange={handleThumb}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            {" "}
                                            Upload
                                        </Button>
                                    </Upload>
                                    <ModalAntd
                                        visible={previewVisible}
                                        onCancel={() =>
                                            setPreviewVisible(false)
                                        }
                                        footer={null}
                                        width={500}
                                    >
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                                padding: "20px",
                                            }}
                                            src={previewImage}
                                        />
                                    </ModalAntd>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    label="Banner (até 5MB / 1920x305)"
                                    name="landscape"
                                >
                                    <Upload
                                        listType="picture"
                                        maxCount={
                                            props.bannerLength
                                                ? 5 - props.bannerLength
                                                : 5
                                        }
                                        onPreview={handlePreview}
                                        {...props_landscape}
                                        onChange={handleLandscape}
                                    >
                                        <Button icon={<UploadOutlined />}>
                                            {" "}
                                            Upload
                                        </Button>
                                    </Upload>
                                    <ModalAntd
                                        visible={previewVisible}
                                        onCancel={() =>
                                            setPreviewVisible(false)
                                        }
                                        footer={null}
                                        width={500}
                                    >
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                                padding: "20px",
                                            }}
                                            src={previewImage}
                                        />
                                    </ModalAntd>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>

                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        //disabled={handleDisable()}
                        block
                    >
                        Salvar Preferências
                    </Button>
                </div>
            </>
        </Form>
    );
}
