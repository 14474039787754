import { Empty, Table } from "antd";
import { useContext, useEffect } from "react";
import { IndicationContext } from "../context/IndicationContext";
import { IIndication } from "../types";

export function Indication() {
    const { indications, getIndication } = useContext(IndicationContext);

    useEffect(() => {
        getIndication();
    }, []);

    const dataSource = indications.map((i: IIndication) => {
        return { ...i, key: i.id_form_indication };
    });
    const columns = [
        // {
        //     key: "1",
        //     title: "ID",
        //     dataIndex: "id",
        // },

        {
            key: "1",
            title: "Nome do escritório e/ou propriedade rural indicado",
            dataIndex: "company",
        },
        {
            key: "2",
            title: "Nome do resp. indicado",
            dataIndex: "resp_name",
        },
        {
            key: "3",
            title: "Celular indicado",
            dataIndex: "phone",
        },
        {
            key: "4",
            title: "Origem da Indicação",
            dataIndex: "name_person",
        },
        {
            key: "5",
            title: "Data da indicação",
            dataIndex: "date",
            render: (text: any) => {
                let a;
                if (text) {
                    a = `${text.split("T")[0].split("-")[2]}/${
                        text.split("T")[0].split("-")[1]
                    }/${text.split("T")[0].split("-")[0]}
                    `;
                }

                return <>{a}</>;
            },
        },
    ];

    return (
        <>
            <div
                style={{
                    width: "100%",
                    padding: "10px 50px 10px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <h1> Indicações:</h1>
                </div>
                <div>
                    <Table
                        size="small"
                        columns={columns}
                        dataSource={dataSource}
                        locale={{
                            emptyText: (
                                <Empty
                                    description="Nenhuma indicação foi encontrada!"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            ),
                        }}
                        expandable={{
                            expandedRowRender: record => (
                                <div style={{ marginLeft: 50 }}>
                                    <strong>
                                        Outras informações da origem da
                                        indicação:
                                    </strong>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <div>
                                            Email origem: {record.email_person}
                                        </div>
                                        <div>
                                            Celular origem:{" "}
                                            {record.phone_person}
                                        </div>
                                    </div>
                                    <strong>
                                        Outras informações do indicado:
                                    </strong>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginBottom: "15px",
                                        }}
                                    >
                                        <div>
                                            Email indicado: {record.email}
                                        </div>
                                        <div>
                                            Localização: {record.city},{" "}
                                            {record.state}
                                        </div>
                                    </div>
                                </div>
                            ),
                        }}
                    ></Table>{" "}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "right",
                            marginTop: "10px",
                            marginRight: "1vw",
                        }}
                    ></div>
                </div>
            </div>
        </>
    );
}
