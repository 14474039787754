import { IDispatchL, IStateL } from "../context/LoginContext";

export function loginReducer(state: IStateL, action: IDispatchL) {
    switch (action.type) {
        case "set_login":
            return { ...state, ...action.payload };

        case "set_step":
            return { ...state, ...action.payload };
        case "set_token":
            return { ...state, ...action.payload };
        case "set_personLogin":
            return { ...state, ...action.payload };
    }
    throw Error("Action não encontrada: " + action.type);
}
