import { useState } from "react";
import Login from "./components/Login";
import { ModalTokenExpired } from "./components/ModalTokenExpired";
import { LoginContextProvider } from "./context/LoginContext";
import { Index } from "./layout";

function App() {
    const [auth, setAuth] = useState(false);

    return (
        <>
            {" "}
            <LoginContextProvider setAuth={setAuth}>
                {auth === false ? (
                    <Login />
                ) : (
                    <div>
                        <Index />
                        <ModalTokenExpired />
                    </div>
                )}{" "}
            </LoginContextProvider>
        </>
    );
}

export default App;
