import { Button, Empty, Popconfirm, Table } from "antd";
import { Paperclip, PencilSimple, XCircle } from "phosphor-react";
import React, { useContext, useEffect, useState } from "react";
import { FormInvoice } from "../components/FormInvoice";
import { Modal } from "../components/Modal";
import { InvoiceContext } from "../context/InvoiceContext";
import { LoginContext } from "../context/LoginContext";
import { PersonContext } from "../context/PersonContext";
import { ProductContext } from "../context/ProductContext";
import { ProductGroupContext } from "../context/ProductGroupContext";
import { currencyFormatter, currencyFormatterGranos } from "../helpers";
import { IInvoice } from "../types";
import { types } from "./Person";

export interface IInvoiceProps {
    user: any;
}

export function Invoice(props: IInvoiceProps) {
    const [isDisable, setIsDisable] = useState(false);
    const [which, setWhich] = useState<any>();

    const { defaultProduct, getProduct } = useContext(ProductContext);
    const { getProductGroup, productGroup } = useContext(ProductGroupContext);
    const { person, getPerson } = useContext(PersonContext);
    const { stateI, dispatch, getInvoices, editInvoices } =
        useContext(InvoiceContext);

    var statusInvoice = [
        { id: "pending", label: "Pendente" },
        { id: "processing", label: "Em processamento" },
        { id: "finished", label: "Finalizado" },
        { id: "canceled", label: "Cancelado" },
    ];

    useEffect(() => {
        getProduct();
        getProductGroup();
        getPerson();
        getInvoices();
    }, []);

    useEffect(() => {
        if (props.user.login !== undefined) {
            if (props.user.login.type !== "MASTER") {
                setIsDisable(true);
            }
        }
    }, [props.user]);

    const [configModal, setConfigModal] = useState({
        title: "",
        content: <></>,
    });
    const dataSource: any[] = stateI.invoices
        ? stateI.invoices.data.map((i: IInvoice) => {
              return { ...i, key: i.number };
          })
        : [];
    const columns: any = [
        {
            key: "1",
            title: "Produto",
            dataIndex: "product_temp",
            render: (text: any) => {
                let produto: any = "";
                if (defaultProduct.products) {
                    produto =
                        defaultProduct.products[
                            defaultProduct.products.findIndex(
                                (p: any) => p.id_product === text
                            )
                        ].description;
                }
                return <>{produto}</>;
            },
        },
        {
            key: "2",
            title: "Consumidor",
            dataIndex: "person_customer_temp",
            render: (text: any) => {
                let nome: any = "";
                if (person[0]) {
                    nome =
                        person[
                            person.findIndex((p: any) => p.id_person === text)
                        ].name;
                }
                return <>{nome}</>;
            },
            // sorter: (a: any, b: any) => {
            //     let nA: any = "";
            //     let nB: any = "";
            //     if (person[0]) {
            //         nA =
            //             person[
            //                 person.findIndex(
            //                     (p: any) =>
            //                         p.id_person === a.person_customer_temp
            //                 )
            //             ].name;
            //         nB =
            //             person[
            //                 person.findIndex(
            //                     (p: any) =>
            //                         p.id_person === b.person_customer_temp
            //                 )
            //             ].name;
            //     }
            //     return nA.localeCompare(nB);
            // },
            // sortDirections: ["descend", "ascend"],
        },
        {
            key: "3",
            title: "Numero do pedido",
            dataIndex: "number",
            // sorter: (a: any, b: any) => parseInt(a.number) - parseInt(b.number),
            // sortDirections: ["descend", "ascend"],
        },
        {
            key: "4",
            title: "Status",
            dataIndex: "status",
            render: (text: any) => {
                let s = "";
                if (text) {
                    s =
                        statusInvoice[
                            statusInvoice.findIndex((s: any) => s.id === text)
                        ].label;
                }
                return s;
            },
            sorter: (a: any, b: any) => {
                let nA: any = "";
                let nB: any = "";
                if (person[0]) {
                    nA =
                        statusInvoice[
                            statusInvoice.findIndex(
                                (s: any) => s.id === a.status
                            )
                        ].label;
                    nB =
                        statusInvoice[
                            statusInvoice.findIndex(
                                (s: any) => s.id === b.status
                            )
                        ].label;
                }
                return nA.localeCompare(nB);
            },
            sortDirections: ["ascend", "descend"],
            showSorterTooltip: false,
        },
        {
            key: "5",
            title: "Data",
            dataIndex: "date",
            render: (text: any) => {
                let a;
                if (text) {
                    a = `${text.split("T")[0].split("-")[2]}/${
                        text.split("T")[0].split("-")[1]
                    }/${text.split("T")[0].split("-")[0]}
                    `;
                }

                return <>{a}</>;
            },
        },
        {
            key: "6",
            title: "Ações",
            render: (record: IInvoice) => {
                return (
                    <>
                        <Button
                            disabled={
                                isDisable ||
                                record.status === "finished" ||
                                record.status === "canceled"
                            }
                            type="link"
                            onClick={() => {
                                handleEdit(record);
                            }}
                        >
                            <PencilSimple size={18} />
                        </Button>
                        <Button
                            type="link"
                            style={{
                                color: "black",
                            }}
                            onClick={() => {
                                handleAnexo(record);
                            }}
                        >
                            <Paperclip size={18} />
                        </Button>
                        <Popconfirm
                            disabled={
                                isDisable ||
                                record.status === "finished" ||
                                record.status === "canceled"
                            }
                            title="Você tem certeza que vai cancelar esse pedido?"
                            okText="Sim"
                            cancelText="Cancelar"
                            okType="danger"
                            onConfirm={() => handleCancel(which)}
                            placement="topRight"
                        >
                            <Button
                                disabled={
                                    isDisable ||
                                    record.status === "finished" ||
                                    record.status === "canceled"
                                }
                                type="link"
                                style={{
                                    color:
                                        isDisable ||
                                        record.status === "finished" ||
                                        record.status === "canceled"
                                            ? "rgba(0, 0, 0, 0.25)"
                                            : "red",
                                }}
                                onClick={() => setWhich(record)}
                            >
                                <XCircle size={18} />
                            </Button>
                        </Popconfirm>
                    </>
                );
            },
        },
    ];
    const expcolumnProduct: any = [
        {
            key: "1",
            title: "Descrição do Produto",
            dataIndex: "description",
        },
        {
            key: "2",
            title: "Detalhes do Produto",
            dataIndex: "detail",
        },
        {
            key: "3",
            title: "Valor do Pedido em Reais",
            dataIndex: "value",
            render: (text: any) => {
                return currencyFormatter(text);
            },
        },
        {
            key: "4",
            title: "Valor do Pedido em Granos",
            dataIndex: "value_granos",
            render: (text: any) => {
                return currencyFormatterGranos(text);
            },
        },
    ];
    const expcolumnPerson: any = [
        {
            key: "1",
            title: "Nome",
            dataIndex: "name",
        },
        {
            key: "3",
            title: "Email",
            dataIndex: "admin_email",
        },
        {
            key: "4",
            title: "CPF",
            dataIndex: "identification",
        },
        {
            key: "5",
            title: "Celular",
            dataIndex: "phone",
        },
        {
            key: "6",
            title: "Endereço",
            dataIndex: "address",
        },
    ];
    const expcolumnDelivery: any = [
        {
            key: "1",
            title: "Detalhes da entrega",
            dataIndex: "adress_detail",
        },
        {
            key: "2",
            title: "Vendedor",
            dataIndex: "invoice_partner",
        },
    ];
    const tableProps = {
        expandedRowRender: (record: IInvoice) => {
            let ProductGroup = defaultProduct.products
                ? defaultProduct.products[
                      defaultProduct.products.findIndex(
                          (p: any) => p.id_product === record.product_temp
                      )
                  ].product_group_temp
                : "";

            let ProductType =
                productGroup[
                    productGroup.findIndex(
                        (g: any) => g.id_product_group === ProductGroup
                    )
                ].type;

            let address = stateI.invoices
                ? stateI.invoices.data[
                      stateI.invoices.data.findIndex((i: any) => {
                          return i.id_invoice === record.id_invoice;
                      })
                  ].address
                : "";
            let invoice_partner_name = stateI.invoices
                ? stateI.invoices.data[
                      stateI.invoices.data.findIndex(
                          (i: any) => i.product_temp === record.product_temp
                      )
                  ].person_partner_temp
                : "";
            const Perso =
                person[
                    person.findIndex(
                        (p: any) => p.id_person === record.person_customer_temp
                    )
                ];

            const InvoiceDetail = {
                description: defaultProduct.products
                    ? defaultProduct.products[
                          defaultProduct.products.findIndex(
                              (p: any) => p.id_product === record.product_temp
                          )
                      ].description
                    : "",
                detail: defaultProduct.products
                    ? defaultProduct.products[
                          defaultProduct.products.findIndex(
                              (p: any) => p.id_product === record.product_temp
                          )
                      ].detail
                    : "",
                value: stateI.invoices
                    ? stateI.invoices.data[
                          stateI.invoices.data.findIndex(
                              (i: any) => i.product_temp === record.product_temp
                          )
                      ].value
                    : "",
                value_granos: stateI.invoices
                    ? stateI.invoices.data[
                          stateI.invoices.data.findIndex(
                              (i: any) => i.product_temp === record.product_temp
                          )
                      ].value_granos
                    : "",
            };
            const InvoiceAdress = {
                adress_detail:
                    ProductType === "0"
                        ? address
                            ? address
                            : "Optou por retirar na loja, entrar em contato com o cliente"
                        : "Entrar em contato com o cliente",
                invoice_partner:
                    props.user.login.type === "MASTER"
                        ? person[
                              person.findIndex(
                                  (p: any) =>
                                      p.id_person === invoice_partner_name
                              )
                          ].name
                        : "",
            };
            return (
                <>
                    <Table
                        columns={expcolumnPerson}
                        dataSource={[Perso]}
                        pagination={false}
                        className="table-style"
                    />
                    <Table
                        columns={expcolumnProduct}
                        dataSource={[InvoiceDetail]}
                        pagination={false}
                        className="table-style"
                    />
                    <Table
                        columns={expcolumnDelivery}
                        dataSource={[InvoiceAdress]}
                        pagination={false}
                        className="table-style"
                    />
                </>
            );
        },
    };

    function handleAnexo(value: any) {
        setConfigModal({
            title: "Enviar Anexo",
            content: <FormInvoice record={value} type={2} />,
        });

        dispatch({ type: "set_open", payload: { open: true } });
    }

    function handleEdit(value: any) {
        setConfigModal({
            title: "Editar Pedido",
            content: (
                <FormInvoice
                    type={1}
                    record={value}
                    user={props.user}
                    person={
                        person[
                            person.findIndex(
                                (p: any) =>
                                    p.id_person === value.person_customer_temp
                            )
                        ].name
                    }
                    partner={
                        person[
                            person.findIndex(
                                (p: any) =>
                                    p.id_person === value.person_partner_temp
                            )
                        ].name
                    }
                    product={
                        defaultProduct.products
                            ? defaultProduct.products[
                                  defaultProduct.products.findIndex(
                                      (p: any) =>
                                          p.id_product === value.product_temp
                                  )
                              ].description
                            : ""
                    }
                    statusInvoice={statusInvoice}
                />
            ),
        });

        dispatch({ type: "set_open", payload: { open: true } });
    }

    function handleCancel(value: any) {
        let obj = {
            id_invoice: value.id_invoice,
            date: value.date,
            number: value.number,
            person_customer_temp: value.person_customer_temp,
            person_partner_temp: value.person_partner_temp,
            product_temp: value.product_temp,
            status: "canceled",
            value: value.value,
            value_granos: value.value_granos,
        };
        editInvoices(obj);
    }

    return (
        <>
            <div
                style={{
                    width: "100%",
                    padding: "10px 50px 10px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <h1> Pedidos:</h1>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    ></div>
                </div>
                <div>
                    <Table
                        {...tableProps}
                        size="small"
                        columns={columns}
                        dataSource={dataSource}
                        sortDirections={[null]}
                        locale={{
                            emptyText: (
                                <Empty
                                    description="Nenhum pedido foi cadastrado"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            ),
                        }}
                    ></Table>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "right",
                            marginTop: "10px",
                            marginRight: "1vw",
                        }}
                    ></div>
                </div>
            </div>

            <Modal
                title={configModal.title}
                isVisible={stateI.open ? stateI.open : false}
                width={800}
                setIsVisible={() =>
                    dispatch({
                        type: "set_uploading",
                        payload: { open: false },
                    })
                }
            >
                {configModal.content}
            </Modal>
        </>
    );
}
