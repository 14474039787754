import { message } from "antd";
import { createContext, useContext, useState } from "react";
import { ICredit, IInvoice } from "../types";
import { InvoiceContextProvider } from "./InvoiceContext";
import { LoginContext } from "./LoginContext";

interface IPendentes {
    Credits_waiting?: ICredit[];
    Credits_processing?: ICredit[];
    Invoices?: IInvoice[];
    invoices_Yearly?: IInvoice[];
    invoices_Monthly?: IInvoice[];
    invoices_Pendings?: IInvoice[];

    Credits_pending?: ICredit[];
    Credits_sum_finished?: number;
    Credits_sum_expired?: number;
    Circulation?: number;
    Count_bonus?: number;
    Count_indication?: number;
    Count_purchase?: number;
}

const DashboardDefault = {
    pendentes: {} as IPendentes,
    getPendentes: () => {},
};
export const DashboardContext = createContext(DashboardDefault);
export function DashboardContextProvider({ children }: any) {
    const [pendentes, setPendentes] = useState({} as IPendentes);
    const { stateL, dispatchL } = useContext(LoginContext);
    function getPendentes() {
        fetch(
            `${
                process.env.REACT_APP_BASE_URL_API
            }api/dashboard/${stateL.login?.type.toLowerCase()}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    message.error("Ocorreu um erro!");
                } else {
                    setPendentes(res);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <InvoiceContextProvider>
            <DashboardContext.Provider value={{ pendentes, getPendentes }}>
                {children}
            </DashboardContext.Provider>
        </InvoiceContextProvider>
    );
}
