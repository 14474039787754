import { Button, Card, Col, Divider, List, Popconfirm, Row } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { FormPreference } from "../components/Forms/FormPreference";
import { Modal } from "../components/Modal";
import { PreferenceContext } from "../context/PreferenceContext";
import styled from "styled-components";
import { IPreference } from "../types";
import { LoginContext } from "../context/LoginContext";
import {
    DeleteOutlined,
    DownloadOutlined,
    EyeOutlined,
} from "@ant-design/icons";

const StyleCard = styled.div`
    font-size: 0.9rem;
    color: black;
    width: fit-content;
    padding: 5px;
    padding-left: 0;
    background-color: transparent;
    display: flex;
    align-items: baseline;
    justify-content: start;
    margin-bottom: 25px;
    max-height: 175px;
    overflow: auto;
`;
const StyleCardImage = styled.div`
    height: 400px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #cbd5e1;
    border-radius: 20px;
`;

export function Preference() {
    const [landscape, setLandscape] = useState("");
    const [thumb, setThumb] = useState("");
    const [navbar, setNavbar] = useState("");
    const [open, setOpen] = useState(false);
    const [configModal, setConfigModal] = useState({
        title: "",
        content: <></>,
    });
    const [openCard, setOpenCard] = useState(false);
    const [img, setImg] = useState("");

    const {
        getPreference,
        getPreferenceFiles,
        deletePreferenceFile,
        preference,
        preferenceFile,
    } = useContext(PreferenceContext);
    const { stateL } = useContext(LoginContext);

    const data = useCallback(() => {
        let returnPrefs: IPreference = {
            ...preference,
        };

        return returnPrefs;
    }, [preference]);

    function savePreference() {
        setConfigModal({
            title:
                preference !== undefined
                    ? "Editar preferências"
                    : "Adicionar preferências",
            content:
                preference !== undefined ? (
                    <FormPreference
                        bannerLength={preferenceFile.length}
                        setOpen={setOpen}
                        cont={preference}
                        thumbImg={thumb}
                        landscapeImg={landscape}
                        user={stateL}
                    />
                ) : (
                    <FormPreference setOpen={setOpen} user={stateL} />
                ),
        });
        setOpen(true);
    }

    const fetchThumb = (value: any) => {
        let link: any = document.createElement("a");
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/preference/download_thumb/${value.id_preference}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.blob();
                } else {
                    if (response.status === 401) {
                        console.log("erro no token");
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    const imageObjURL = URL.createObjectURL(res);

                    setThumb(imageObjURL);
                    link.src = imageObjURL;
                    link.setAttribute(
                        "src",
                        `image.${value.thumb.split(".")[1]}`
                    );

                    link.click();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
    const fetchNavbar = (value: any) => {
        let link: any = document.createElement("a");

        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/preference/download_navbar_img/${value.id_preference}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.blob();
                } else {
                    if (response.status === 401) {
                        console.log("erro no token");
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    const imageObjURL = URL.createObjectURL(res);

                    setNavbar(imageObjURL);
                    link.src = imageObjURL;
                    link.setAttribute(
                        "src",
                        `image.${value.navbar_img.split(".")[1]}`
                    );

                    link.click();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
    const downloadPreferenceFile = (value: any) => {
        let link: any = document.createElement("a");

        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/preference_files/download/${value.id_preference_files}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.blob();
                } else {
                    if (response.status === 401) {
                        console.log("erro no token");
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    const imageObjURL = URL.createObjectURL(res);
                    link.href = imageObjURL;
                    link.setAttribute(
                        "download",
                        `image.${value.id_preference_files}.${
                            value.file.split(".")[1]
                        }`
                    );

                    link.click();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
    const fetchPreferenceFile = (value: any) => {
        let link: any = document.createElement("a");

        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/preference_files/download/${value.id_preference_files}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.blob();
                } else {
                    if (response.status === 401) {
                        console.log("erro no token");
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    const imageObjURL = URL.createObjectURL(res);

                    setImg(imageObjURL);
                    link.src = imageObjURL;
                    link.setAttribute(
                        "src",
                        `${value.id_preference_files}.${
                            value.file.split(".")[1]
                        }`
                    );

                    link.click();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        getPreference();
    }, []);

    useEffect(() => {
        if (preference) {
            if (Object.keys(preference).length > 0) {
                getPreferenceFiles(preference.id_preference);
            }
        }
    }, [preference]);

    useEffect(() => {
        if (preference !== undefined) {
            if (preference.id_preference !== undefined) {
                if (preference.thumb !== "") {
                    fetchThumb(preference);
                }
                if (preference.navbar_img !== "") {
                    fetchNavbar(preference);
                }
            }
        }
    }, [preference]);

    return (
        <>
            {" "}
            <div
                style={{
                    width: "100%",
                    padding: "10px 50px 10px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <h1> Cadastro de preferências:</h1>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Button onClick={() => savePreference()}>
                            {preference !== undefined ? "Editar" : "Salvar"}
                        </Button>
                    </div>
                </div>

                <div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                        }}
                    >
                        {thumb !== "" ? (
                            <img
                                alt="example"
                                src={thumb}
                                style={{
                                    width: "75px",
                                    height: "75px",
                                    borderRadius: "10px",
                                }}
                            />
                        ) : (
                            <></>
                        )}
                        <h2>{stateL.PersonLogin?.name}</h2>
                    </div>
                    <Divider />
                    <Row gutter={[24, 0]}>
                        <Col span={6}>
                            <div>
                                <h3>E-mail para contato</h3>
                                <StyleCard>{data().contact_email}</StyleCard>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div>
                                <h3>E-mail para marketing</h3>
                                <StyleCard>{data().marketing_email}</StyleCard>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div>
                                <h3>E-mail para resgates</h3>
                                <StyleCard>{data().invoice_email}</StyleCard>
                            </div>
                        </Col>{" "}
                        <Col span={6}>
                            <div>
                                <h3>Telefone</h3>
                                <StyleCard>{data().phone}</StyleCard>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={6}>
                            <div>
                                <h3>Endereço</h3>
                                <StyleCard>{data().address}</StyleCard>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div>
                                <h3>Bio</h3>
                                <StyleCard>{data().bio}</StyleCard>
                            </div>
                        </Col>
                        <Col span={6}>
                            {stateL.PersonLogin?.type !== "MASTER" && (
                                <div>
                                    <h3>Webpage</h3>
                                    <StyleCard>{data().web_page}</StyleCard>
                                </div>
                            )}
                        </Col>
                        {stateL.PersonLogin?.type === "PROVIDER" && (
                            <Col span={6}>
                                <div>
                                    <h3>Porcentagem do valor da NF</h3>
                                    <StyleCard>
                                        {data().document_exchange_rate} %
                                    </StyleCard>
                                </div>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <div
                                style={{
                                    width: "215px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <h3>Icone na barra de navegação</h3>

                                {navbar !== "" ? (
                                    <Card
                                        style={{
                                            backgroundColor: "#EAEAEA",
                                            height: "120px",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            alt="example"
                                            src={navbar}
                                            style={{
                                                width: "75px",
                                                height: "75px",
                                                borderRadius: "10px",
                                            }}
                                        />
                                    </Card>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Col>
                        <Col span={12}>
                            <h3>Banners (Máximo 5)</h3>
                            <>
                                <List
                                    dataSource={preferenceFile}
                                    renderItem={(item: any) => (
                                        <List.Item
                                            style={{
                                                borderBottom: "1px solid grey",
                                            }}
                                            actions={[
                                                <Popconfirm
                                                    title="Você tem certeza que vai cancelar esse arquivo?"
                                                    okText="Sim"
                                                    cancelText="Cancelar"
                                                    okType="danger"
                                                    onConfirm={() => {
                                                        deletePreferenceFile(
                                                            item
                                                        );
                                                    }}
                                                >
                                                    {" "}
                                                    <DeleteOutlined
                                                        key="delete"
                                                        style={{
                                                            cursor: "pointer",
                                                            color: "red",
                                                        }}
                                                    />
                                                </Popconfirm>,
                                                <DownloadOutlined
                                                    onClick={() => {
                                                        downloadPreferenceFile(
                                                            item
                                                        );
                                                        setOpenCard(false);
                                                    }}
                                                />,
                                                <EyeOutlined
                                                    onClick={() => {
                                                        fetchPreferenceFile(
                                                            item
                                                        );
                                                        setOpenCard(!openCard);
                                                    }}
                                                />,
                                            ]}
                                        >
                                            <List.Item.Meta
                                                title={item.id_preference_files}
                                            />
                                        </List.Item>
                                    )}
                                ></List>
                                <div>
                                    {openCard && (
                                        <>
                                            <Card
                                                style={{
                                                    width: 300,
                                                    marginTop: "25px",
                                                }}
                                                cover={
                                                    <img
                                                        alt="example"
                                                        src={img}
                                                        style={{
                                                            width: "400px",
                                                            height: "200px",
                                                        }}
                                                    />
                                                }
                                            ></Card>
                                        </>
                                    )}
                                </div>
                            </>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal
                title={configModal.title}
                isVisible={open}
                width={800}
                setIsVisible={() => setOpen(false)}
            >
                {configModal.content}
            </Modal>
        </>
    );
}
