import { IDispatchProduct, IStateProduct } from "../context/ProductContext";

export function productReducer(state: IStateProduct, action: IDispatchProduct) {
    switch (action.type) {
        case "set_product":
            return { ...state, ...action.payload };
        case "set_productFiles":
            return { ...state, ...action.payload };
        case "set_uploading":
            return { ...state, ...action.payload };
    }
    throw Error("Action não encontrada: " + action.type);
}
