import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import { UploadProps } from "antd/es/upload";
import { useState, useContext } from "react";
import { CreditContext } from "../context/CreditContext";

export interface IFormUploadExcelProps {
    setVisible: any;
}

export function FormUploadExcel(props: IFormUploadExcelProps) {
    const { postExcelFile } = useContext(CreditContext);
    const [file, setFile] = useState<any>();

    const propsUp: UploadProps = {
        accept: ".xlsx",
        beforeUpload: () => {
            return false;
        },
    };
    const handleFiles: UploadProps["onChange"] = ({
        fileList: newFileList,
    }) => {
        setFile(newFileList[0]);
    };
    const handleUpload = () => {
        props.setVisible(false);
        let formData = new FormData();
        formData.append("file", file.originFileObj);
        postExcelFile(formData);
    };

    return (
        <>
            <Form onFinish={handleUpload}>
                <Form.Item name="file">
                    <Upload {...propsUp} onChange={handleFiles}>
                        <Button icon={<UploadOutlined />}>
                            Selecione um arquivo
                        </Button>
                    </Upload>
                </Form.Item>
                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={file ? false : true}
                        block
                    >
                        Salvar
                    </Button>
                </div>
            </Form>
        </>
    );
}
