import {
    Alert,
    Button,
    Col,
    Collapse,
    Divider,
    Form,
    Input,
    Radio,
    RadioChangeEvent,
    Row,
    Select,
} from "antd";
import { MaskedInput, IMask } from "antd-mask-input";
import { useCallback, useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import { v4 as uuidv4 } from "uuid";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { types, occupations, from } from "../../pages/Person";
import { useForm } from "antd/lib/form/Form";
import ReactInputMask from "react-input-mask";

const { Option } = Select;
const { Panel } = Collapse;

interface FormPersonProps {
    cont?: any;
    setOpen: any;
    user: any;

    typeForm?: Number;
}

const options = [
    { label: "Pessoa Fisica", value: false },
    { label: "Pessoa Juridica", value: true },
];

export function FormPerson(props: FormPersonProps) {
    const [isLegalPerson, setIsLegalPerson] = useState(false);
    const [validateName, setValidateName] = useState(props.cont ? true : false);
    const [validateIdentification, setValidateIdentification] = useState(
        props.cont ? true : false
    );
    const [validateEmail, setValidateEmail] = useState(
        props.cont ? true : false
    );
    const [validatePhone, setValidatePhone] = useState(
        props.cont ? true : false
    );
    const [validateType, setValidateType] = useState(props.cont ? true : false);
    const [validateZipCode, setValidateZipCode] = useState(
        props.cont ? true : false
    );
    const [validateDistrict, setValidateDistrict] = useState(
        props.cont ? true : false
    );
    const [validateStreet, setValidateStreet] = useState(
        props.cont ? true : false
    );
    const [validateNumber, setValidateNumber] = useState(
        props.cont ? true : false
    );
    const [validateOcc, setValidateOcc] = useState(props.cont ? true : false);
    const [validateFrom, setValidateFrom] = useState(props.cont ? true : false);

    const [isDisable, setIsDisable] = useState(false);

    const [isOther, setIsOther] = useState(false);
    const [isOtherFrom, setIsOtherFrom] = useState(false);

    const [selecType, setSelecType] = useState(
        props.cont ? props.cont.type : ""
    );

    // const [arr, setArr] = useState<any>(
    //     props.cont && props.cont.address.split(" - ")
    // );

    const { addPerson, editPerson, loading } = useContext(PersonContext);

    const [form] = useForm();

    const arr: any = props.cont && props.cont.address?.split(" - ");

    const handleDisable = useCallback(() => {
        if (
            validateName &&
            validateIdentification &&
            validateEmail &&
            validatePhone &&
            validateType &&
            validateZipCode &&
            validateDistrict &&
            validateStreet &&
            validateNumber &&
            validateFrom &&
            validateOcc
        ) {
            return false;
        }
        return true;
    }, [
        validateName,
        validateIdentification,
        validateEmail,
        validatePhone,
        validateType,
        validateZipCode,
        validateDistrict,
        validateStreet,
        validateNumber,
        validateFrom,
        validateOcc,
    ]);

    const setTypes = useCallback(() => {
        let returnTypes: any = [];

        if (props.cont) {
            returnTypes.push(types);
        }

        if (props.user.login !== undefined) {
            switch (props.user.login.type) {
                case "MASTER":
                    returnTypes.push(types);
                    break;
                case "PROVIDER":
                    returnTypes.push(
                        types.filter((t: any) => t.id === "CUSTOMER")
                    );
            }
        }
        return returnTypes;
    }, [props.user, props.cont]);

    const callCepApi = async (value: any) => {
        var cep = value.replace(/\D/g, "");
        var validacep = /^[0-9]{8}$/;
        if (validacep.test(cep)) {
            await fetch(`https://viacep.com.br/ws/${cep}/json/`)
                .then(res => res.json())
                .then(data => {
                    form.setFieldsValue({
                        city: data.localidade,
                        state: data.uf,
                    });
                    // setCity(data.localidade);
                    // document
                    //     .getElementById("city")
                    //     ?.setAttribute("value", data.localidade);
                });
        }
    };

    const handleSave = (v: any) => {
        if (props.cont) {
            let obj = {
                id_person: props.cont.id_person,
                name: v.name,
                alias: v.alias,
                identification: props.cont.identification,
                resp_name: v.resp_name,
                resp_cpf: v.resp_cpf
                    ? v.resp_cpf.replace(/[^a-z0-9]/gi, "")
                    : "",
                admin_email: v.admin_email,
                address:
                    v.street +
                    " - " +
                    v.number +
                    " - " +
                    v.district +
                    " - " +
                    (v.aditional ? v.aditional + " - " : "") +
                    " - " +
                    v.city +
                    " - " +
                    v.state,
                zip_code: v.zip_code,
                type: v.type,
                phone: v.phone.replace(/[^a-z0-9]/gi, ""),
                occupation: v.occupation,
                other_occupation: v.other_occupation ? v.other_occupation : "",
                came_from: v.came_from,
                other_from: v.other_from ? v.other_from : "",
                url: v.url ? v.url : "",
            };
            editPerson(obj);
            props.setOpen(loading);
        } else {
            let obj = {
                admin_email: v.admin_email,
                alias: v.alias,
                address:
                    v.street +
                    " - " +
                    v.number +
                    " - " +
                    v.district +
                    " - " +
                    (v.aditional ? v.aditional + " - " : "") +
                    v.city +
                    " - " +
                    v.state,
                identification: v.identification.replace(/[^a-z0-9]/gi, ""),
                name: v.name,

                zip_code: v.zip_code,
                phone: v.phone.replace(/[^a-z0-9]/gi, ""),
                type: v.type,
                first_login: false,
                resp_name: v.resp_name ? v.resp_name : "",
                resp_cpf: v.resp_cpf
                    ? v.resp_cpf.replace(/[^a-z0-9]/gi, "")
                    : "",
                occupation: v.occupation,
                other_occupation: v.other_occupation ? v.other_occupation : "",
                came_from: v.came_from,
                other_from: v.other_from ? v.other_from : "",
                url: v.url ? v.url : "",
            };
            addPerson(obj);
        }
    };

    useEffect(() => {
        if (props.cont) {
            if (props.cont.identification !== null) {
                if (props.cont.identification.length === 14) {
                    setIsLegalPerson(true);
                }
            }
        }
    }, [props.cont]);

    useEffect(() => {
        if (props.cont) {
            if (props.user.login !== undefined) {
                if (props.user.login.type !== "MASTER") {
                    setIsDisable(true);
                }
            }
        }
    }, [props.cont, props.user]);

    useEffect(() => {
        props.setOpen(loading);
    }, [loading]);

    useEffect(() => {
        form.resetFields();
    }, [isLegalPerson]);

    return (
        <>
            {props.user.login.type === "MASTER" && (
                <Radio.Group
                    options={options}
                    onChange={e => setIsLegalPerson(e.target.value)}
                    defaultValue={isLegalPerson}
                    optionType="button"
                    buttonStyle="solid"
                    style={{
                        marginTop: "-5px",
                        marginBottom: "20px",
                    }}
                    disabled={props.cont}
                />
            )}

            <Form
                layout="vertical"
                initialValues={{
                    ...props.cont,
                    city: arr ? arr[4] : "",
                    state: arr ? arr[5] : "",
                    district: arr ? arr[2] : "",
                    street: arr ? arr[0] : "",
                    number: arr ? arr[1] : "",
                    aditional: arr ? arr[3] : "",
                }}
                onFinish={(v: any) => {
                    //props.setOpen(false);
                    handleSave(v);
                }}
                form={form}
                disabled={props.typeForm && props.typeForm === 1}
            >
                <Collapse accordion defaultActiveKey={1}>
                    <Panel header="Dados Pessoais" key={1}>
                        {" "}
                        {props.cont && (
                            <Form.Item
                                name="id_person"
                                style={{ display: "none" }}
                            >
                                <Input type="hidden" name="id_person"></Input>
                            </Form.Item>
                        )}
                        <Row gutter={[24, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Coloque um Nome",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidateName(false),
                                                        Promise.reject()
                                                    );
                                                }
                                                setValidateName(true);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                    label="Nome:"
                                    name="name"
                                >
                                    <Input placeholder="Digite o nome" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="Apelido:" name="alias">
                                    <Input placeholder="Digite o apelido" />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Selecione um perfil",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidateType(false),
                                                        Promise.reject()
                                                    );
                                                }
                                                setSelecType(value);
                                                setValidateType(true);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                    label="Perfil:"
                                    name="type"
                                >
                                    <Select
                                        disabled={isDisable}
                                        placeholder="Selecione"
                                    >
                                        {setTypes()[0].map((i: any) => {
                                            return (
                                                <Option
                                                    key={uuidv4()}
                                                    value={i.id}
                                                >
                                                    {i.label}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Digite um email",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidateEmail(false),
                                                        Promise.reject()
                                                    );
                                                }
                                                setValidateEmail(true);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                    label="Email:"
                                    name="admin_email"
                                >
                                    <Input
                                        type="email"
                                        placeholder="Digite o e-mail"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Digite um telefone para contato",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidatePhone(false),
                                                        Promise.reject()
                                                    );
                                                }
                                                setValidatePhone(true);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                    label="Celular:"
                                    name="phone"
                                >
                                    <ReactInputMask
                                        mask="(99) 9 9999-9999"
                                        id="phone"
                                        placeholder="Celular"
                                        className="input-mask"
                                        disabled={
                                            props.typeForm &&
                                            props.typeForm === 1
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={isLegalPerson ? "CPNJ" : "CPF"}
                                    name="identification"
                                    rules={[
                                        {
                                            required: true,
                                            message: `${
                                                isLegalPerson
                                                    ? "Digite um CNPJ"
                                                    : "Digite um CPF"
                                            }`,
                                        },
                                        () => ({
                                            validator(_, value) {
                                                let validate = false;
                                                if (isLegalPerson) {
                                                    validate =
                                                        cnpj.isValid(value);
                                                } else {
                                                    validate =
                                                        cpf.isValid(value);
                                                }

                                                if (!value || validate) {
                                                    setValidateIdentification(
                                                        true
                                                    );
                                                } else {
                                                    return (
                                                        setValidateIdentification(
                                                            false
                                                        ),
                                                        Promise.reject(
                                                            new Error(
                                                                isLegalPerson
                                                                    ? "CNPJ inválido"
                                                                    : "CPF inválido"
                                                            )
                                                        )
                                                    );
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    {isLegalPerson ? (
                                        <ReactInputMask
                                            mask="99.999.999/9999-99"
                                            id="identification"
                                            placeholder="CNPJ"
                                            className="input-mask"
                                            readOnly={props.cont}
                                            disabled={
                                                props.typeForm &&
                                                props.typeForm === 1
                                            }
                                        />
                                    ) : (
                                        <ReactInputMask
                                            mask="999.999.999-99"
                                            id="identification"
                                            placeholder="CPF"
                                            className="input-mask"
                                            readOnly={props.cont}
                                            disabled={
                                                props.typeForm &&
                                                props.typeForm === 1
                                            }
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {isLegalPerson && (
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Coloque um Nome",
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value) {
                                                            return Promise.reject();
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                            label="Nome do responsavel:"
                                            name="resp_name"
                                        >
                                            <Input placeholder="Digite nome completo" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Digite o CPF",
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        if (
                                                            !value ||
                                                            cpf.isValid(value)
                                                        ) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error(
                                                                "CPF inválido"
                                                            )
                                                        );
                                                    },
                                                }),
                                            ]}
                                            label="CPF do responsavel:"
                                            name="resp_cpf"
                                        >
                                            <ReactInputMask
                                                mask="999.999.999-99"
                                                id="resp_cpf"
                                                placeholder="CPF do responsavel"
                                                className="input-mask"
                                                disabled={
                                                    props.typeForm &&
                                                    props.typeForm === 1
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Row gutter={[24, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Selecione uma ocupação",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidateOcc(false),
                                                        Promise.reject()
                                                    );
                                                }
                                                setValidateOcc(true);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                    label="Ocupação:"
                                    name="occupation"
                                >
                                    <Select
                                        placeholder="Selecione"
                                        onChange={(e: any) => {
                                            if (e === "6") {
                                                setIsOther(!isOther);
                                            } else setIsOther(false);
                                        }}
                                    >
                                        {occupations.map((i: any) => {
                                            return (
                                                <Option
                                                    key={uuidv4()}
                                                    value={i.id}
                                                >
                                                    {i.label}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "Selecione uma origem",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidateFrom(false),
                                                        Promise.reject()
                                                    );
                                                }
                                                setValidateFrom(true);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                    label="Origem do cadastro:"
                                    name="came_from"
                                >
                                    <Select
                                        placeholder="Selecione"
                                        onChange={(e: any) => {
                                            if (e === "4") {
                                                setIsOtherFrom(!isOtherFrom);
                                            } else setIsOtherFrom(false);
                                        }}
                                    >
                                        {from.map((i: any) => {
                                            return (
                                                <Option
                                                    key={uuidv4()}
                                                    value={i.id}
                                                >
                                                    {i.label}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 0]}>
                            {isOther && (
                                <Col span={12}>
                                    <Form.Item
                                        label="Qual outra ocupação:"
                                        name="other_occupation"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Digite uma ocupação",
                                            },
                                            () => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.reject();
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input placeholder="Digite outra ocupação" />
                                    </Form.Item>
                                </Col>
                            )}
                            {isOtherFrom && (
                                <Col span={12}>
                                    <Form.Item
                                        label="Qual outra origem:"
                                        name="other_from"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Digite uma origem",
                                            },
                                            () => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.reject();
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input placeholder="Digite outra origem" />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={[24, 0]}>
                            {isLegalPerson && (
                                <Col span={12}>
                                    <Form.Item
                                        label="URL"
                                        name="url"
                                        hidden={
                                            selecType === "CUSTOMER" ||
                                            (props.user.login.type ===
                                                "MASTER" &&
                                                selecType === "MASTER")
                                        }
                                        rules={[
                                            {
                                                required: true,
                                                message: "Coloque uma url",
                                            },
                                            {
                                                pattern: new RegExp(
                                                    /^[a-z-/-/]+$/
                                                ),
                                                warningOnly: true,
                                                message: "Carácter inválido",
                                            },
                                            () => ({
                                                validator(_, value) {
                                                    if (!value) {
                                                        return Promise.reject();
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input
                                            disabled={
                                                props.user.login.type !==
                                                "MASTER"
                                            }
                                            placeholder="URL para o site"
                                            suffix={".programagranos.com.br"}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </Panel>

                    <Panel
                        header="Dados de Endereço"
                        key={2}
                        forceRender={props.cont}
                    >
                        <Row gutter={[24, 0]}>
                            <Col span={6}>
                                <Form.Item
                                    label="CEP:"
                                    name="zip_code"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Digite um cep",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidateZipCode(
                                                            false
                                                        ),
                                                        Promise.reject()
                                                    );
                                                }
                                                setValidateZipCode(true);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        placeholder="Digite o CEP"
                                        onBlur={e => callCepApi(e.target.value)}
                                        // onChange={(e: any) =>
                                        //     setZipCode(e.target.value)
                                        // }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label="Cidade:" name="city">
                                    <Input
                                        disabled
                                        placeholder="Cidade"
                                        name="city"
                                        id="city"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label="Estado:" name="state">
                                    <Input disabled placeholder="Estado" />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    label="Bairro:"
                                    name="district"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Digite o bairro",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidateDistrict(
                                                            false
                                                        ),
                                                        Promise.reject()
                                                    );
                                                }
                                                setValidateDistrict(true);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="Bairro" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[24, 0]}>
                            <Col span={8}>
                                <Form.Item
                                    label="Logradouro (rua, Avenida, etc):"
                                    name="street"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Digite o logradouro",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidateStreet(
                                                            false
                                                        ),
                                                        Promise.reject()
                                                    );
                                                }
                                                setValidateStreet(true);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="Logradouro" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Número:"
                                    name="number"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Digite o número",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    return (
                                                        setValidateNumber(
                                                            false
                                                        ),
                                                        Promise.reject()
                                                    );
                                                }
                                                setValidateNumber(true);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="Número" />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    label="Complemento:"
                                    name="aditional"
                                >
                                    <Input placeholder="Complemento" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>

                <div style={{ marginTop: "20px" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={handleDisable()}
                        block
                    >
                        Salvar Cadastro
                    </Button>
                </div>
            </Form>
        </>
    );
}
