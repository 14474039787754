import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { LoginContext, LoginContextProvider } from "../context/LoginContext";
import "../styles/login.css";
import Codigo from "./FormsLogin/Codigo";
import Username from "./FormsLogin/Username";
import VerifCodigo from "./FormsLogin/VerifCodigo";

const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: rgb(22, 43, 117);
    background: linear-gradient(
        180deg,
        rgba(22, 43, 117, 1) 0%,
        rgba(166, 206, 57, 1) 100%
    );
`;

function Login() {
    const { stateL } = useContext(LoginContext);

    switch (stateL.step) {
        case 1:
            return (
                <Container>
                    <Username />
                </Container>
            );
        case 2:
            return (
                <Container>
                    <VerifCodigo />
                </Container>
            );
        case 3:
            return (
                <Container>
                    <Codigo />
                </Container>
            );
    }
    return <></>;
}

export default Login;
