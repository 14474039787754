import { message } from "antd";
import {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";
import { invoiceReducer } from "../Reducers/InvoiceReducers";
import { IInvoice, IInvoiceFiles, IInvoiceFilter } from "../types";
import { LoginContext } from "./LoginContext";
import PersonContextProvider from "./PersonContext";
import { ProductContextProvider } from "./ProductContext";
export interface IStateI {
    invoices?: IInvoiceFilter;
    invoiceFiles?: IInvoiceFiles[];
    open?: boolean;
    uploading?: boolean;
}
export interface IDispatchI {
    type: string;
    payload: IStateI;
}
const invoiceDefault = {
    stateI: {} as IStateI,
    dispatch: (value: IDispatchI) => {},
    getInvoices: () => {},
    editInvoices: (value: IInvoice) => {},
    getInvoiceFiles(value: string) {},
    postInvoiceFiles(value: FormData) {},
    delInvoiceFiles(value: string) {},
};

export const InvoiceContext = createContext(invoiceDefault);

export function InvoiceContextProvider({ children }: any) {
    const [stateI, dispatch] = useReducer(invoiceReducer, {
        open: false,
        uploading: false,
    });
    const { dispatchL } = useContext(LoginContext);
    function getInvoices() {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/invoice/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.error) {
                    console.log(res.erro);
                } else {
                    dispatch({
                        type: "set_invoice",
                        payload: { invoices: res },
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function editInvoices(value: IInvoice) {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/invoice/`, {
            method: "post",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 406) {
                        message.error(
                            "Saldo insuficiente para finalizar pedido"
                        );
                    }
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.error) {
                    message.error("Ocorreu um erro ao salvar o pedido!");
                } else {
                    message.success("Pedido salvo com sucesso!");
                    dispatch({
                        type: "set_open",
                        payload: { open: false, uploading: false },
                    });
                    return getInvoices();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function getInvoiceFiles(value: string) {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/invoice_files/${value}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.error) {
                    console.log(res.error);
                } else {
                    dispatch({
                        type: "set_invoiceFiles",
                        payload: { invoiceFiles: res },
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    function postInvoiceFiles(value: FormData) {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/invoice_files/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: value,
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.error) {
                    message.error("Ocorreu um erro ao enviar o arquivo!");
                } else {
                    message.success("Arquivo enviado com sucesso!");
                    dispatch({
                        type: "set_open",
                        payload: { open: false, uploading: false },
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    function delInvoiceFiles(value: string) {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/invoice_files/${value}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.error) {
                    message.error("Ocorreu um erro ao deletar o arquivo!");
                } else {
                    message.success("Arquivo deletado com sucesso!");
                    dispatch({
                        type: "set_open",
                        payload: { open: false, uploading: false },
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <ProductContextProvider>
            <PersonContextProvider>
                <InvoiceContext.Provider
                    value={{
                        stateI,
                        dispatch,
                        getInvoices,
                        editInvoices,
                        getInvoiceFiles,
                        postInvoiceFiles,
                        delInvoiceFiles,
                    }}
                >
                    {children}
                </InvoiceContext.Provider>
            </PersonContextProvider>
        </ProductContextProvider>
    );
}
