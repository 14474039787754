import { Button, Empty, Pagination, Popconfirm, Table } from "antd";
import { Paperclip, PencilSimple, Trash } from "phosphor-react";
import { useContext, useEffect, useState } from "react";
import { FormProduct } from "../components/Forms/FormsProduct/FormProduct";
import { FormProductFiles } from "../components/Forms/FormsProduct/FormProductFiles";
import { Modal } from "../components/Modal";
import { PersonContext } from "../context/PersonContext";
import { ProductContext } from "../context/ProductContext";
import { ProductGroupContext } from "../context/ProductGroupContext";
import { currencyFormatter, currencyFormatterGranos } from "../helpers";

export interface IProductProps {
    user: any;
}

export function Product(props: IProductProps) {
    const [open, setOpen] = useState(false);
    const [which, setWhich] = useState(0);
    const [configModal, setConfigModal] = useState({
        title: "",
        content: <></>,
    });

    const { defaultProduct, getProduct, delProduct, getProductFiles } =
        useContext(ProductContext);
    const { getProductGroup, productGroup } = useContext(ProductGroupContext);
    const { person, getPerson } = useContext(PersonContext);

    useEffect(() => {
        getProduct();
        getProductGroup();
        getPerson();
    }, []);

    function handleAdd() {
        setConfigModal({
            title: "Adicionar Produto",
            content: (
                <FormProduct setOpen={setOpen} productGroup={productGroup} />
            ),
        });
        setOpen(true);
    }

    function handleEdit(value: any) {
        // const productKey = defaultProduct.products?.findIndex(
        //     (prod: any) => prod.id_product === id
        // );
        setConfigModal({
            title: "Editar Produto",
            content: (
                <FormProduct
                    setOpen={setOpen}
                    cont={value}
                    productGroup={productGroup}
                    personPartner={
                        props.user.login.type === "MASTER"
                            ? person[
                                  person.findIndex(
                                      (p: any) =>
                                          p.id_person ===
                                          value.person_partner_temp
                                  )
                              ].name
                            : ""
                    }
                />
            ),
        });
        setOpen(true);
    }

    function handleFiles(value: any) {
        getProductFiles(value.id_product);
        setConfigModal({
            title: "Adicionar Midia",
            content: <FormProductFiles setOpen={setOpen} product={value} />,
        });

        setOpen(true);
    }

    function handleDelete(id: any) {
        delProduct(id);
    }

    const dataSource = defaultProduct.products;
    const columns = [
        // {
        //     key: "1",
        //     title: "ID",
        //     dataIndex: "id",
        // },
        {
            key: "1",
            title: "Descrição",
            dataIndex: "description",
        },
        {
            key: "2",
            title: "Estoque",
            dataIndex: "available",
        },
        {
            key: "3",
            title: "Valor em Reais",
            dataIndex: "value_product",
            render: (text: any) => {
                return currencyFormatter(text);
            },
        },
        {
            key: "4",
            title: "Valor em Granos",
            dataIndex: "value_granos",
            render: (text: any) => {
                return currencyFormatterGranos(text);
            },
        },
        {
            key: "5",
            title: "Ações",
            render: (record: any) => {
                return (
                    <>
                        <Button
                            type="link"
                            onClick={() => {
                                setWhich(record.id_product);
                                handleEdit(record);
                            }}
                        >
                            <PencilSimple size={18} />
                        </Button>
                        <Button
                            type="link"
                            //disabled={true}
                            onClick={() => {
                                handleFiles(record);
                            }}
                            style={{ color: "black" }}
                        >
                            <Paperclip size={18} />
                        </Button>
                        <Popconfirm
                            title="Você tem certeza que vai deletar esse produto?"
                            okText="Sim"
                            cancelText="Cancelar"
                            okType="danger"
                            onConfirm={() => handleDelete(which)}
                            placement="topRight"
                        >
                            <Button
                                type="link"
                                style={{ color: "red" }}
                                onClick={() => {
                                    setWhich(record.id_product);
                                }}
                            >
                                <Trash size={18} />
                            </Button>
                        </Popconfirm>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div
                style={{
                    width: "100%",
                    padding: "10px 50px 10px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <h1> Cadastro de produtos:</h1>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            disabled={props.user.login.type === "MASTER"}
                            onClick={() => handleAdd()}
                        >
                            Adicionar
                        </Button>
                    </div>
                </div>
                <div>
                    <Table
                        size="small"
                        columns={columns}
                        dataSource={dataSource}
                        locale={{
                            emptyText: (
                                <Empty
                                    description="Nenhum produto foi cadastrado"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                />
                            ),
                        }}
                    ></Table>{" "}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "right",
                            marginTop: "10px",
                            marginRight: "1vw",
                        }}
                    ></div>
                    {/* <table
                        style={{
                            width: "100%",
                        }}
                    >
                        <thead>
                            <tr>
                                <th>Descrição</th>
                                <th>Estoque</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {product.map((p: any) => {
                                return (
                                    <tr
                                        style={{
                                            border: "1px solid black",
                                        }}
                                    >
                                        <td
                                            style={{
                                                background: "red",
                                            }}
                                        >
                                            <div>{p.description}</div>
                                        </td>
                                        <td
                                            style={{
                                                background: "green",
                                            }}
                                        >
                                            <div>{p.available}</div>
                                        </td>
                                        <td
                                            style={{
                                                background: "blue",
                                            }}
                                        >
                                            <div>{p.value}</div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table> */}
                    {/* <ul
                        style={{
                            backgroundColor: "red",
                        }}
                    >
                        {product.map((p: any) => {
                            return (
                                <li key={p.id_product}>
                                    <div>{p.description}</div>
                                </li>
                            );
                        })}
                    </ul> */}
                </div>
            </div>
            <Modal
                title={configModal.title}
                isVisible={open}
                width={800}
                setIsVisible={() => setOpen(false)}
            >
                {configModal.content}
            </Modal>
        </>
    );
}
