import { UploadOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Divider,
    Empty,
    Form,
    Input,
    List,
    Popconfirm,
    Row,
    Select,
    Upload,
    UploadFile,
    UploadProps,
} from "antd";
import { Modal as ModalAntd } from "antd";
import { RcFile } from "antd/lib/upload";
import React, { useContext, useEffect, useState } from "react";
import { InvoiceContext } from "../context/InvoiceContext";
import { v4 as uuidv4 } from "uuid";
import { currencyFormatter, currencyFormatterGranos } from "../helpers";

const { Option } = Select;

export interface IInvoiceProps {
    type: Number;
    record?: any;
    user?: any;
    statusInvoice?: any;
    person?: any;
    product?: any;
    partner?: any;
}

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });

export function FormInvoice(props: IInvoiceProps) {
    const [isDisable, setIsDisable] = useState(false);

    const {
        stateI,
        dispatch,
        getInvoiceFiles,
        editInvoices,
        postInvoiceFiles,
        delInvoiceFiles,
    } = useContext(InvoiceContext);
    const [anexAdd, setAnexAdd] = useState(true);
    const [files, setFiles] = useState<UploadFile[]>([]);

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");

    const propss: UploadProps = {
        beforeUpload: file => {
            setFiles(files.concat(file));

            return false;
        },
    };

    const handleUpload = (f: any) => {
        dispatch({ type: "set_uploading", payload: { uploading: true } });
        files.forEach(file => {
            let formData = new FormData();
            formData.append("file", file.originFileObj as RcFile);
            if (f.description) {
                formData.append("content", f.description + " - " + file.name);
            }
            formData.append("invoice_temp", props.record.id_invoice);
            postInvoiceFiles(formData);
        });
    };

    const handleSave = (v: any) => {
        if (props.record) {
            let obj = {
                id_invoice: props.record.id_invoice,
                date: props.record.date,
                number: props.record.number,
                person_customer_temp: props.record.person_customer_temp,
                product_temp: props.record.product_temp,
                status: v.status,
                value: props.record.value,
                value_granos: props.record.value_granos,
                address: props.record.address,
                person_partner_temp: props.record.person_partner_temp,
            };
            editInvoices(obj);
        }
    };

    const handleFiles: UploadProps["onChange"] = ({
        fileList: newFileList,
    }) => {
        setFiles(newFileList);
    };

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewVisible(true);
    };

    useEffect(() => {
        if (props.record !== undefined) {
            if (
                props.record.status === "canceled" ||
                props.record.status === "finished"
            ) {
                setIsDisable(true);
            }
        }
    }, [props.record]);

    useEffect(() => {
        getInvoiceFiles(props.record.id_invoice);
    }, []);

    return (
        <>
            {props.type === 2 && (
                <>
                    {stateI.invoiceFiles && stateI.invoiceFiles.length > 0 ? (
                        <>
                            <Button
                                disabled={isDisable}
                                style={{
                                    display:
                                        stateI.invoiceFiles &&
                                        stateI.invoiceFiles.length > 0
                                            ? "hidden"
                                            : "inline-block",
                                    textAlign: "end",
                                    marginBottom: "10px",
                                    marginTop: "-10px",
                                }}
                                type="link"
                                block
                                onClick={() => setAnexAdd(!anexAdd)}
                            >
                                {anexAdd && "Adicionar Anexo"}
                                {!anexAdd && "Cancelar Anexo"}
                            </Button>
                        </>
                    ) : (
                        <>
                            {isDisable ? (
                                <>
                                    {" "}
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Empty
                                            description="Não foram carregados arquivos de mídia para esse pedido."
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                    </div>
                                </>
                            ) : (
                                <Form
                                    layout="vertical"
                                    onFinish={f => handleUpload(f)}
                                >
                                    <Row gutter={12}>
                                        <Col span={10}>
                                            <Form.Item
                                                label="Descrição:"
                                                name="description"
                                                style={{ width: "100%" }}
                                            >
                                                <Input
                                                    style={{ width: "100%" }}
                                                    placeholder="Escreva a descrição do anexo"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={14}>
                                            <Form.Item
                                                label="Arquivo:"
                                                name="file"
                                            >
                                                <Upload
                                                    listType="picture"
                                                    //className="upload-credit"
                                                    {...propss}
                                                    onPreview={handlePreview}
                                                    onChange={handleFiles}
                                                >
                                                    <Button
                                                        icon={
                                                            <UploadOutlined />
                                                        }
                                                    >
                                                        Selecione o arquivo
                                                    </Button>
                                                </Upload>
                                                <ModalAntd
                                                    visible={previewVisible}
                                                    onCancel={() =>
                                                        setPreviewVisible(false)
                                                    }
                                                    footer={null}
                                                    width={500}
                                                >
                                                    <img
                                                        alt="example"
                                                        style={{
                                                            width: "100%",
                                                            padding: "20px",
                                                        }}
                                                        src={previewImage}
                                                    />
                                                </ModalAntd>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={stateI.uploading}
                                        block
                                    >
                                        Salvar anexo
                                    </Button>
                                </Form>
                            )}
                        </>
                    )}
                    {stateI.invoiceFiles &&
                        stateI.invoiceFiles.length > 0 &&
                        !anexAdd && (
                            <>
                                <Form
                                    layout="vertical"
                                    onFinish={f => handleUpload(f)}
                                >
                                    <Row gutter={12}>
                                        <Col span={10}>
                                            <Form.Item
                                                label="Descrição:"
                                                name="description"
                                                style={{ width: "100%" }}
                                            >
                                                <Input
                                                    style={{ width: "100%" }}
                                                    placeholder="Escreva a descrição do anexo"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={14}>
                                            <Form.Item
                                                label="Arquivo:"
                                                name="file"
                                            >
                                                <Upload
                                                    listType="picture"
                                                    //className="upload-credit"
                                                    {...propss}
                                                    onPreview={handlePreview}
                                                    onChange={handleFiles}
                                                >
                                                    <Button
                                                        icon={
                                                            <UploadOutlined />
                                                        }
                                                    >
                                                        Selecione o arquivo
                                                    </Button>
                                                </Upload>
                                                <ModalAntd
                                                    visible={previewVisible}
                                                    onCancel={() =>
                                                        setPreviewVisible(false)
                                                    }
                                                    footer={null}
                                                    width={500}
                                                >
                                                    <img
                                                        alt="example"
                                                        style={{
                                                            width: "100%",
                                                            padding: "20px",
                                                        }}
                                                        src={previewImage}
                                                    />
                                                </ModalAntd>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={stateI.uploading}
                                        block
                                    >
                                        Salvar anexo
                                    </Button>
                                </Form>
                            </>
                        )}
                    {stateI.invoiceFiles &&
                        stateI.invoiceFiles.length > 0 &&
                        anexAdd && (
                            <>
                                {" "}
                                <List
                                    dataSource={stateI.invoiceFiles}
                                    renderItem={item => (
                                        <List.Item
                                            actions={[
                                                <>
                                                    <Button
                                                        type="link"
                                                        onClick={() => {
                                                            let link: any =
                                                                document.createElement(
                                                                    "a"
                                                                );
                                                            fetch(
                                                                `${process.env.REACT_APP_BASE_URL_API}api/invoice_files/download/${item.id_invoice_files}`,
                                                                {
                                                                    method: "GET",
                                                                    headers: {
                                                                        Authorization:
                                                                            "Bearer " +
                                                                            localStorage.getItem(
                                                                                "token"
                                                                            ),
                                                                    },
                                                                }
                                                            )
                                                                .then(
                                                                    (
                                                                        response: any
                                                                    ) => {
                                                                        if (
                                                                            response.ok
                                                                        ) {
                                                                            return response.blob();
                                                                        } else {
                                                                            if (
                                                                                response.status ===
                                                                                401
                                                                            ) {
                                                                                console.log(
                                                                                    "erro no token"
                                                                                );
                                                                            }
                                                                        }
                                                                    }
                                                                )
                                                                .then(res => {
                                                                    if (
                                                                        res.erro
                                                                    ) {
                                                                        console.log(
                                                                            res.erro
                                                                        );
                                                                    } else {
                                                                        let teste =
                                                                            URL.createObjectURL(
                                                                                res
                                                                            );
                                                                        link.href =
                                                                            teste; //
                                                                        link.setAttribute(
                                                                            "download",
                                                                            `${
                                                                                item.description
                                                                            }.${
                                                                                item.file.split(
                                                                                    "."
                                                                                )[1]
                                                                            }`
                                                                        );
                                                                        link.click();
                                                                        // setAva();
                                                                    }
                                                                })
                                                                .catch(
                                                                    error => {
                                                                        console.log(
                                                                            error
                                                                        );
                                                                    }
                                                                );
                                                        }}
                                                    >
                                                        Download
                                                    </Button>
                                                </>,
                                                <Popconfirm
                                                    disabled={isDisable}
                                                    title="Você tem certeza que vai cancelar esse arquivo?"
                                                    okText="Sim"
                                                    cancelText="Cancelar"
                                                    okType="danger"
                                                    onConfirm={() => {
                                                        delInvoiceFiles(
                                                            item.id_invoice_files
                                                        );
                                                    }}
                                                >
                                                    <Button
                                                        disabled={isDisable}
                                                        type="link"
                                                        style={{
                                                            marginLeft: 12,
                                                            color: isDisable
                                                                ? "rgba(0, 0, 0, 0.25)"
                                                                : "red",
                                                        }}
                                                    >
                                                        Excluir
                                                    </Button>
                                                </Popconfirm>,
                                            ]}
                                        >
                                            <List.Item.Meta
                                                title={item.description}
                                            />
                                        </List.Item>
                                    )}
                                >
                                    {" "}
                                </List>
                            </>
                        )}
                </>
            )}
            {props.type === 1 && (
                <Form
                    layout="vertical"
                    onFinish={(v: any) => {
                        handleSave(v);
                    }}
                    initialValues={{
                        ...props.record,
                        product_temp: props.product,
                        person_customer_temp: props.person,
                        person_partner_temp: props.partner,
                        value: currencyFormatter(props.record.value),
                        value_granos: currencyFormatterGranos(
                            props.record.value_granos
                        ),
                    }}
                >
                    <Form.Item name="id_invoice" style={{ display: "none" }}>
                        <Input type="hidden" name="id_invoice"></Input>
                    </Form.Item>
                    <Form.Item name="date" style={{ display: "none" }}>
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item name="address" style={{ display: "none" }}>
                        <Input type="hidden" name="address" />
                    </Form.Item>

                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <Form.Item label="Número do pedido:" name="number">
                                <Input disabled={true} name="number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ width: "100%" }}
                                label="Status"
                                name="status"
                            >
                                <Select
                                    placeholder="Selecione"
                                    disabled={
                                        props.user.login.type !== "MASTER"
                                    }
                                >
                                    {props.statusInvoice.map((i: any) => {
                                        return (
                                            <Option key={uuidv4()} value={i.id}>
                                                {i.label}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <Form.Item label="Produto:" name="product_temp">
                                <Input disabled={true} name="product_temp" />
                            </Form.Item>
                        </Col>{" "}
                        <Col span={12}>
                            <Form.Item
                                label="Cliente:"
                                name="person_customer_temp"
                            >
                                <Input
                                    disabled={true}
                                    name="person_customer_temp"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="Vendedor:"
                                name="person_partner_temp"
                            >
                                <Input
                                    disabled={true}
                                    name="person_partner_temp"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider
                        style={{
                            marginTop: "0px",
                            marginBottom: "10px",
                        }}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "10px",
                            fontStyle: "italic",
                            color: "rgba(0, 0, 0, 0.6)",
                        }}
                    >
                        Informações de valor do pedido
                    </div>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <Form.Item label="Valor do pedido:" name="value">
                                <Input disabled={true} name="value" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Valor em Granos:"
                                name="value_granos"
                            >
                                <Input disabled={true} name="value_granos" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ marginTop: "20px" }}>
                        <Button type="primary" htmlType="submit" block>
                            Salvar Pedido
                        </Button>
                    </div>
                </Form>
            )}
        </>
    );
}
