import { Menu, Popover } from "antd";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { CreditContextProvider } from "../context/CreditContext";
import PersonContextProvider from "../context/PersonContext";
import Credit from "../pages/Credit";
import { PreferenceContextProvider } from "../context/PreferenceContext";
import { Person } from "../pages/Person";
import { Preference } from "../pages/Preference";
import { Product } from "../pages/Product";
import { ProductContextProvider } from "../context/ProductContext";
import { Invoice } from "../pages/Invoice";
import { InvoiceContextProvider } from "../context/InvoiceContext";
import { LoginContext } from "../context/LoginContext";
import {
    AddressBook,
    Bookmarks,
    Coins,
    GearSix,
    ListDashes,
    Package,
    ShoppingBag,
    UserList,
    Users,
} from "phosphor-react";
import { MenuUser } from "../components/MenuUser";
import { Dashboard } from "../pages/Dashboard";
import { DashboardContextProvider } from "../context/DashboardContext";
import { ProductGroup } from "../pages/ProductGroup";
import { ProductGroupContextProvider } from "../context/ProductGroupContext";
import { IndicationContextProvider } from "../context/IndicationContext";
import { Indication } from "../pages/Indication";

const EstiloHeader = styled.header`
    width: 100%;
    display: flex;
    left: 0;
    top: 0;
    height: 80px;
    justify-content: space-between;
    padding: 5px 10px 5px;
    background-color: #162b75;
`;

const EstiloSide = styled.aside`
    width: 224px;
    display: flex;
    top: 0px;
`;

const Conteudo = styled.div`
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
`;

export const LeftContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 20px;
    width: 25%;
`;

export const RightContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    margin: 20px;
`;

export const IconItemMenu = styled.div`
    display: flex;
    align-items: center;
    column-gap: 15px;
    color: white;
`;

let timeout: any = null;
export function Index() {
    const [which, setWhich] = useState(0);
    const [popOpen, setPopOpen] = useState(false);

    const { stateL, token, getPersonLogin } = useContext(LoginContext);

    useEffect(() => {
        timeout = setInterval(() => {
            token();
        }, 10000);
    }, []);
    useEffect(() => {
        return () => clearInterval(timeout);
    }, []);
    useEffect(() => {
        getPersonLogin();
    }, []);

    const renderContent = (which: any) => {
        switch (which) {
            case 1:
                return (
                    <PersonContextProvider>
                        <Person user={stateL} />
                    </PersonContextProvider>
                );
            case 2:
                return (
                    <PreferenceContextProvider>
                        <Preference />
                    </PreferenceContextProvider>
                );
            case 3:
                return (
                    <CreditContextProvider>
                        <Credit user={stateL} />
                    </CreditContextProvider>
                );
            case 4:
                return (
                    <InvoiceContextProvider>
                        <Invoice user={stateL} />
                    </InvoiceContextProvider>
                );
            case 5:
                return (
                    <ProductContextProvider>
                        <Product user={stateL} />
                    </ProductContextProvider>
                );
            case 6:
                return (
                    <ProductGroupContextProvider>
                        <ProductGroup />
                    </ProductGroupContextProvider>
                );
            case 7:
                return (
                    <IndicationContextProvider>
                        <Indication />
                    </IndicationContextProvider>
                );
            default:
                return (
                    <div
                        style={{
                            width: "100%",
                        }}
                    >
                        <DashboardContextProvider>
                            <Dashboard />
                        </DashboardContextProvider>
                    </div>
                );
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <EstiloHeader>
                <LeftContainer>
                    {" "}
                    <img
                        src={process.env.PUBLIC_URL + "GRANOS-White.svg"}
                        alt="logo"
                        height={"30px"}
                    />
                </LeftContainer>
                <RightContainer>
                    <div
                        style={{
                            marginLeft: "10px",
                            marginRight: "10px",
                            marginTop: "5px",
                            color: "#f1f5f9",
                        }}
                    >
                        {stateL.PersonLogin?.name}
                    </div>
                    <Popover
                        visible={!popOpen ? popOpen : undefined}
                        onVisibleChange={() => setPopOpen(!popOpen)}
                        placement="bottomLeft"
                        trigger="click"
                        destroyTooltipOnHide={popOpen}
                        content={
                            <PersonContextProvider>
                                {" "}
                                <MenuUser
                                    setPopOpen={setPopOpen}
                                    user={stateL}
                                />
                            </PersonContextProvider>
                        }
                    >
                        <UserList
                            size={30}
                            onClick={() => {
                                setPopOpen(!popOpen);
                            }}
                            style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                                marginRight: "10px",
                                color: "#f1f5f9",
                            }}
                        />
                    </Popover>
                </RightContainer>
            </EstiloHeader>
            <main
                style={{
                    display: "flex",
                    height: "calc(100vh - 80px)",
                }}
            >
                <EstiloSide>
                    {" "}
                    <Menu
                        mode="inline"
                        style={{
                            width: "100%",
                            //marginTop: "10px",
                            backgroundColor: "#3D4147",
                            paddingTop: "1.25rem" /* 20px */,
                            paddingBottom: "1.25rem" /* 20px */,
                        }}
                        defaultSelectedKeys={["dash"]}
                    >
                        <Menu.Item onClick={() => setWhich(0)} key={"dash"}>
                            <IconItemMenu>
                                {" "}
                                <ListDashes size={18} />
                                DashBoard
                            </IconItemMenu>
                        </Menu.Item>
                        {stateL.login?.type === "MASTER" ||
                        stateL.login?.type === "PROVIDER" ? (
                            <Menu.Item
                                onClick={() => setWhich(1)}
                                key={"person"}
                            >
                                <IconItemMenu>
                                    <Users size={18} />
                                    Pessoa
                                </IconItemMenu>
                            </Menu.Item>
                        ) : (
                            <></>
                        )}
                        <Menu.Item onClick={() => setWhich(2)} key={"prefs"}>
                            <IconItemMenu>
                                <GearSix size={18} />
                                Preferências
                            </IconItemMenu>
                        </Menu.Item>
                        {stateL.login?.type === "MASTER" ||
                        stateL.login?.type === "PROVIDER" ? (
                            <Menu.Item
                                onClick={() => setWhich(3)}
                                key={"credit"}
                            >
                                <IconItemMenu>
                                    <Coins size={18} />
                                    Créditos
                                </IconItemMenu>
                            </Menu.Item>
                        ) : (
                            <></>
                        )}

                        {/* {stateL.login?.type === "MASTER" ||
                        stateL.login?.type === "PARTNER" ? ( */}
                        <Menu.Item onClick={() => setWhich(4)} key={"invoice"}>
                            <IconItemMenu>
                                <ShoppingBag size={18} />
                                Pedidos
                            </IconItemMenu>
                        </Menu.Item>
                        {/* ) : (
                            <></>
                        )} */}

                        <Menu.Item onClick={() => setWhich(5)} key={"product"}>
                            <IconItemMenu>
                                <Package size={18} />
                                Produtos
                            </IconItemMenu>
                        </Menu.Item>

                        {stateL.login?.type === "MASTER" ? (
                            <Menu.Item
                                onClick={() => setWhich(6)}
                                key={"product_group"}
                            >
                                <IconItemMenu>
                                    <Bookmarks size={18} />
                                    Categorias
                                </IconItemMenu>
                            </Menu.Item>
                        ) : (
                            <></>
                        )}
                        {stateL.login?.type === "PROVIDER" ? (
                            <Menu.Item
                                onClick={() => setWhich(7)}
                                key={"indications"}
                            >
                                <IconItemMenu>
                                    <AddressBook size={18} />
                                    Indicações
                                </IconItemMenu>
                            </Menu.Item>
                        ) : (
                            <></>
                        )}
                    </Menu>
                </EstiloSide>{" "}
                <Conteudo> {renderContent(which)} </Conteudo>
            </main>
        </div>
    );
}
