import { Button, Checkbox, Form, Radio } from "antd";
import React, { useContext, useState } from "react";
import { LoginContext } from "../../context/LoginContext";
import "../../styles/login.css";

function VerifCodigo() {
    //Controle das checkBox
    const [chk, setChk] = useState(true);
    //Enviando o código (email ou celular)
    const [codeSending, SetCodeSending] = useState(false);

    const { stateL, send_key } = useContext(LoginContext);

    function handleFinishVerification(value: { type: String }) {
        send_key(value, SetCodeSending);
    }

    return (
        <>
            <Form
                className="form-login"
                onFinish={v => handleFinishVerification(v)}
            >
                <h2>Verificação de 2 etapas </h2>
                <h4>Por onde Deseja receber o código ?</h4>
                <Form.Item
                    name="type"
                    valuePropName="value"
                    style={{
                        marginTop: "8%",
                        marginBottom: "5px",
                    }}
                >
                    <Radio.Group
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "5px",
                        }}
                        onChange={() => setChk(false)}
                    >
                        <Radio
                            style={{ fontSize: "17px", fontWeight: "bolder" }}
                            value={"email"}
                        >
                            {stateL.login?.email}
                        </Radio>
                        <Radio
                            style={{
                                fontSize: "17px",
                                fontWeight: "bolder",
                                marginBottom: "20px",
                            }}
                            value={"phone"}
                        >
                            {stateL.login?.phone}
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                    }}
                >
                    <Form.Item style={{ alignItems: "flex-end", flex: 1 }}>
                        <Button
                            loading={codeSending}
                            disabled={chk}
                            type="primary"
                            block
                            htmlType="submit"
                            style={{ height: "6vh", width: "100%" }}
                            className={chk ? "" : "btn-login"}
                        >
                            Enviar
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
}

export default VerifCodigo;
