import { message } from "antd";
import { createContext, useEffect, useReducer } from "react";
import { loginReducer } from "../Reducers/LoginReducers";
import { IPerson, login } from "../types";

export interface IStateL {
    step?: number;
    login?: login;
    tokenExpired?: boolean;
    PersonLogin?: IPerson;
}
export interface IDispatchL {
    type: string;
    payload: IStateL;
}
const Logindefalt = {
    stateL: {} as IStateL,
    pre_login: (l: login, f: Function) => {},
    send_key: (k: { type: String }, f: Function) => {},
    finish_login: (fl: { pass: string }) => {},
    dispatchL: (value: IDispatchL) => {},
    token: () => {},
    getPersonLogin: () => {},
};

export const LoginContext = createContext(Logindefalt);

export function LoginContextProvider({ children, setAuth }: any) {
    const [stateL, dispatchL] = useReducer(loginReducer, {
        step: 1,
        tokenExpired: false,
    });

    function pre_login(value: login, SetSending: Function) {
        SetSending(true);
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/person/pre_login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(value),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 406) {
                        message.error("Usuário não encontrado");
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    message.error("Ocorreu um erro ao tentar acessar");
                    SetSending(false);
                } else {
                    if (res.type === "CUSTOMER") {
                        message.error("Permissões de acesso negadas");
                        SetSending(false);
                    } else {
                        SetSending(false);
                        dispatchL({
                            type: "set_login",
                            payload: { login: { ...value, ...res }, step: 2 },
                        });
                    }
                }
            })
            .catch(error => {
                console.log(error);
                SetSending(false);
            });
    }
    function send_key(value: { type: String }, SetSending: Function) {
        SetSending(true);
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/person/send_key`, {
            method: "POST",
            headers: { "Content-Type": "application/json;charset=UTF-8" },
            body: JSON.stringify({
                identification: stateL.login?.identification,
                ...value,
            }),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(response);
                }
            })
            .then(res => {
                if (res.erro) {
                    message.error("Ocorreu um erro ao enviar");
                    SetSending(false);
                } else {
                    SetSending(false);
                    dispatchL({
                        type: "set_step",
                        payload: { step: 3 },
                    });
                }
            })
            .catch(error => {
                console.log(error);
                SetSending(false);
            });
    }
    function finish_login(value: { pass: string }) {
        let body: Object = {
            identification: stateL.login?.identification,
            pass: value.pass,
        };
        fetch(`${process.env.REACT_APP_BASE_URL_API}login`, {
            method: "POST",
            headers: {
                "Content-Type": "text/plain;charset=UTF-8",
            },
            body: JSON.stringify(body),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(response);
                }
            })
            .then(res => {
                if (res.erro) {
                    message.error("Ocorreu um erro ao tentar fazer login");
                } else {
                    if (res.access_token) {
                        localStorage.removeItem("token");
                        localStorage.setItem("token", res.access_token);
                        setAuth(true);
                        dispatchL({
                            type: "set_login",
                            payload: { login: { ...res } },
                        });
                    }
                }
            })
            .catch(error => console.log(error));
    }

    function token() {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/login/keep_alive`, {
            method: "GET",
            headers: {
                "Content-Type": "text/plain;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.status === "ok") {
                    dispatchL({
                        type: "set_token",
                        payload: { tokenExpired: false },
                    });
                } else {
                    console.log("token invalido");
                    dispatchL({
                        type: "set_token",
                        payload: { tokenExpired: true },
                    });
                }
            });
    }

    function getPersonLogin() {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/person/${stateL.login?.id_person}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    dispatchL({
                        type: "set_personLogin",
                        payload: { PersonLogin: res },
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <LoginContext.Provider
            value={{
                stateL,
                pre_login,
                send_key,
                finish_login,
                dispatchL,
                token,
                getPersonLogin,
            }}
        >
            {children}
        </LoginContext.Provider>
    );
}
