import { Button, Form, Input } from "antd";
import React, { useContext } from "react";
import "../../styles/login.css";
import VerificationInput from "react-verification-input";
import { LoginContext } from "../../context/LoginContext";

function Codigo() {
    const { finish_login } = useContext(LoginContext);

    function handleCodigo(value: { pass: string }) {
        finish_login(value);
    }
    return (
        <Form
            className="form-login"
            onFinish={v => {
                handleCodigo(v);
            }}
        >
            <h2>Digite o código recebido</h2>
            <Form.Item name="pass" className="item-codigo">
                <VerificationInput
                    length={4}
                    validChars={"0-9"}
                    placeholder="*"
                    classNames={{
                        container: "container-codigo",
                        character: "caracter-codigo",
                        characterInactive: "caracter-codigo--inactive",
                        characterSelected: "caracter-codigo--selected",
                    }}
                />
            </Form.Item>
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    width: "100%",
                }}
            >
                <Form.Item style={{ alignItems: "flex-end", flex: 1 }}>
                    <Button block htmlType="submit" className={"btn-login"}>
                        Enviar
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
}

export default Codigo;
