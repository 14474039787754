import { message } from "antd";
import {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";
import { creditReducer } from "../Reducers/CreditReducers";
import { ICredit, ICreditFiles, ICreditFilter } from "../types";
import { LoginContext } from "./LoginContext";
import PersonContextProvider from "./PersonContext";

export interface IStateC {
    uploading?: boolean;
    credits?: ICreditFilter;
    creditFiles?: ICreditFiles[];
    open?: boolean;
    imgCredit?: any;
}
export interface IDispatchC {
    type: string;
    payload: IStateC;
}

const CreditDefault = {
    stateC: {} as IStateC,
    dispatch: (value: IDispatchC) => {},
    uploadCreditFile: (value: FormData) => {},
    getCredit: () => {},
    postCredit: (value: ICredit) => {},
    postExcelFile: (value: FormData) => {},
    delCredit: (id_customer: string, value: string | undefined) => {},
    getCreditFiles: (id_credit: string) => {},
    downloadCreditFile: (id_creditFiles: string) => {},
    delCreditFiles: (id_creditFiles: string) => {},

    getFilterCredit: (props: any) => {},
};
export const CreditContext = createContext(CreditDefault);

export function CreditContextProvider({ children }: any) {
    const [stateC, dispatch] = useReducer(creditReducer, {
        open: false,
        uploading: false,
    });
    const { dispatchL } = useContext(LoginContext);
    function getCredit() {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/credit/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    dispatch({ type: "set_credit", payload: { credits: res } });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function getFilterCredit(props: any) {
        dispatch({ type: "set_open", payload: { open: false } });

        const keys = Object.keys(props);
        var urlApi = "api/credit/?";
        keys.forEach((key, index) => {
            if (props[key] === "" || props[key] === "null") {
            } else {
                if (
                    key === "status" ||
                    key === "type" ||
                    key === "doc" ||
                    key === "personCustomer" ||
                    key === "dateInCreate" ||
                    key === "dateFinCreate" ||
                    key === "dateInExpired" ||
                    key === "dateFinExpired"
                )
                    urlApi += key + "=" + props[key] + "&";
            }
        });

        fetch(`${process.env.REACT_APP_BASE_URL_API}` + urlApi, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    dispatch({ type: "set_credit", payload: { credits: res } });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function postCredit(value: ICredit) {
        dispatch({ type: "set_open", payload: { open: false } });
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/credit/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    message.error("Ocorreu um erro ao criar um crédito novo!");
                } else {
                    message.success("Crédito salvo com sucesso!");
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => getCredit());
    }
    const postExcelFile = (value: any) => {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/credit/file`, {
            method: "post",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: value,
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    return getCredit();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    function delCredit(id_customer: string, value: string | undefined) {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/credit/${id_customer}/${value}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    message.error("Ocorreu um erro ao deletar o crédito!");
                } else {
                    message.success("Crédito deletado com sucesso!");
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => getCredit());
    }

    function getCreditFiles(value: string) {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/credit_files/${value}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    dispatch({
                        type: "set_creditFiles",
                        payload: { creditFiles: res },
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function uploadCreditFile(value: any) {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/credit_files/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: value,
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                message.success("Arquivo enviado com sucesso!");
                dispatch({
                    type: "set_open",
                    payload: { open: false, uploading: false },
                });
            })
            .catch(() => {
                message.error("Ocorreu um erro ao enviar o arquivo!");
                dispatch({
                    type: "set_uploading",
                    payload: { uploading: false },
                });
            });
    }
    function delCreditFiles(value: any) {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/credit_files/${value}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    message.error("Ocorreu um erro ao deletar o arquivo!");
                } else {
                    message.success("Arquivo deletado com sucesso!");
                    dispatch({
                        type: "set_open",
                        payload: { open: false, uploading: false },
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function downloadCreditFile(value: string) {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/credit_files/download/${value}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.blob();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    dispatch({
                        type: "set_credit",
                        payload: { imgCredit: URL.createObjectURL(res) },
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <PersonContextProvider>
            <CreditContext.Provider
                value={{
                    stateC,
                    dispatch,
                    getCredit,
                    postCredit,
                    postExcelFile,
                    delCredit,
                    uploadCreditFile,
                    getCreditFiles,
                    downloadCreditFile,
                    delCreditFiles,

                    getFilterCredit,
                }}
            >
                {children}
            </CreditContext.Provider>
        </PersonContextProvider>
    );
}
