import { IDispatchI, IStateI } from "../context/InvoiceContext";

export function invoiceReducer(state: IStateI, action: IDispatchI) {
    switch (action.type) {
        case "set_invoice":
            return { ...state, ...action.payload };
        case "set_open":
            return { ...state, ...action.payload };
        case "set_uploading":
            return { ...state, ...action.payload };
        case "set_invoiceFiles":
            return { ...state, ...action.payload };
    }
    throw Error("Action não encontrada: " + action.type);
}
