import { createContext, useContext, useState } from "react";
import { IIndication } from "../types";
import { LoginContext } from "./LoginContext";

const Indicationdefalt = {
    indications: [] as IIndication[],
    getIndication: () => {},
};

export const IndicationContext = createContext(Indicationdefalt);

export function IndicationContextProvider({ children }: any) {
    const [indications, setIndications] = useState([]);
    const { dispatchL } = useContext(LoginContext);

    function getIndication() {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/form_indication/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    setIndications(res);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <IndicationContext.Provider value={{ indications, getIndication }}>
            {children}
        </IndicationContext.Provider>
    );
}
