import { Card, Col, Empty, List, Row, Statistic, Tabs } from "antd";
import {
    Coins,
    Package,
    ShoppingBag,
    Sliders,
    TrendUp,
    Users,
} from "phosphor-react";
import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../context/DashboardContext";
import { InvoiceContext } from "../context/InvoiceContext";
import { LoginContext } from "../context/LoginContext";
import { PersonContext } from "../context/PersonContext";
import { ProductContext } from "../context/ProductContext";
import styled from "styled-components";
import { currencyFormatter } from "../helpers";

const StyleDashDiv = styled.div`
    width: 85vw;
    padding: 10px;
    padding-left: 50px;
    padding-right: 15px;
`;

const StyleDivList = styled.div`
    max-height: 300px;
    overflow-y: scroll;
`;

const StyleEmptyDiv = styled.div`
    display: flex;
    justify-content: center;
    background-color: #fff;
    height: 145px;
`;

const StyleTitleDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyleDash = styled.div`
    width: 100%;
    display: flex;
`;

const StyleFirtsDiv = styled.div`
    width: 50%;
    padding: 10px;
    padding-left: 50px;
    padding-right: 5px;
`;
const StyleSecondDiv = styled.div`
    width: 50%;
    padding: 10px;
    padding-right: 50px;
`;

const { TabPane } = Tabs;

export interface IDashboardProps {}

export function Dashboard(props: IDashboardProps) {
    const { pendentes, getPendentes } = useContext(DashboardContext);
    const { person, getPerson } = useContext(PersonContext);
    const { defaultProduct, getProduct } = useContext(ProductContext);
    const { stateI, getInvoices } = useContext(InvoiceContext);
    const { stateL } = useContext(LoginContext);
    const [isVisible, setIsVisible] = useState(false);

    const dataInvoice: any = pendentes.Invoices;
    const dataInvoicePartner: any = pendentes.invoices_Pendings;
    const dataCreditsWaiting: any = pendentes.Credits_waiting;
    const dataCreditsProcessing: any = pendentes.Credits_processing;
    const dataCreditsPending: any = pendentes.Credits_pending;

    var statusInvoice = [
        { id: "indication", label: "Indicação" },
        { id: "bonus", label: "Bonificação" },
        { id: "purchase", label: "Compra" },
    ];

    useEffect(() => {
        getPendentes();
        getPerson();
        getProduct();
        getInvoices();
    }, []);

    useEffect(() => {
        getPendentes();
    }, [defaultProduct, stateI]);

    useEffect(() => {
        if (defaultProduct.products) {
            if (defaultProduct.products.length > 0) {
                setIsVisible(true);
            }
        }
    }, [defaultProduct, pendentes]);

    return (
        <>
            {stateL.PersonLogin?.type === "MASTER" && (
                <>
                    <div className="site-card-wrapper">
                        <Row gutter={[24, 0]}>
                            <Col span={4}>
                                <Card style={{ backgroundColor: "#162b75" }}>
                                    <Statistic
                                        title="Pendencias totais"
                                        value={
                                            pendentes.Invoices &&
                                            pendentes.Credits_waiting &&
                                            pendentes.Credits_processing &&
                                            pendentes.Credits_waiting?.length +
                                                pendentes.Credits_processing
                                                    ?.length +
                                                pendentes.Invoices?.length
                                        }
                                        valueStyle={{
                                            color: "#f1f1f1",
                                            fontWeight: "bold",
                                        }}
                                        prefix={<Sliders size={22} />}
                                    />
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card style={{ backgroundColor: "#162b75" }}>
                                    <Statistic
                                        title="Total de Pedidos"
                                        value={
                                            stateI.invoices &&
                                            stateI.invoices?.data.length
                                        }
                                        valueStyle={{
                                            color: "#f1f1f1",
                                            fontWeight: "bold",
                                        }}
                                        prefix={<ShoppingBag size={22} />}
                                    />
                                </Card>
                            </Col>
                            <Col span={4}>
                                <Card style={{ backgroundColor: "#162b75" }}>
                                    <Statistic
                                        title="Total de Usuarios"
                                        value={person && person?.length}
                                        valueStyle={{
                                            color: "#f1f1f1",
                                            fontWeight: "bold",
                                        }}
                                        prefix={<Users size={22} />}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card style={{ backgroundColor: "#162b75" }}>
                                    <Statistic
                                        title="Total de Produtos Cadastrados"
                                        value={
                                            defaultProduct.products &&
                                            defaultProduct.products?.length
                                        }
                                        valueStyle={{
                                            color: "#f1f1f1",
                                            fontWeight: "bold",
                                        }}
                                        prefix={<Package size={22} />}
                                    />
                                </Card>
                            </Col>

                            <Col span={6}>
                                <Card
                                    style={{
                                        backgroundColor: "#162b75",
                                        marginRight: "10px",
                                    }}
                                >
                                    <Statistic
                                        title="Granos em circulação"
                                        value={
                                            pendentes.Circulation
                                                ? new Intl.NumberFormat(
                                                      "pt-br"
                                                  ).format(
                                                      pendentes.Circulation
                                                  )
                                                : 0
                                        }
                                        valueStyle={{
                                            color: "#f1f1f1",
                                            fontWeight: "bold",
                                        }}
                                        precision={2}
                                        prefix={<Coins size={22} />}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <StyleDash>
                        <StyleFirtsDiv>
                            <StyleTitleDiv>
                                <h1> Créditos Liberados para faturamento:</h1>
                            </StyleTitleDiv>
                            <StyleDivList>
                                {dataCreditsWaiting &&
                                dataCreditsWaiting.length > 0 ? (
                                    <List
                                        className="list-dash"
                                        dataSource={dataCreditsWaiting}
                                        size="large"
                                        renderItem={(item: any) => (
                                            <List.Item
                                                style={{
                                                    backgroundColor: "#fff",
                                                }}
                                            >
                                                <List.Item.Meta
                                                    title={
                                                        <>
                                                            <div>
                                                                Data de
                                                                expiração:{" "}
                                                                {
                                                                    item.expire_date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[2]
                                                                }
                                                                /
                                                                {
                                                                    item.expire_date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[1]
                                                                }
                                                                /
                                                                {
                                                                    item.expire_date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[0]
                                                                }
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Data de criação:{" "}
                                                                {
                                                                    item.date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[2]
                                                                }
                                                                /
                                                                {
                                                                    item.date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[1]
                                                                }
                                                                /
                                                                {
                                                                    item.date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[0]
                                                                }
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Cliente:{" "}
                                                                {person.length >
                                                                    0 &&
                                                                    person[
                                                                        person.findIndex(
                                                                            (
                                                                                p: any
                                                                            ) =>
                                                                                p.id_person ===
                                                                                item.person_customer_temp
                                                                        )
                                                                    ].name}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Provedor:{" "}
                                                                {person.length >
                                                                    0 &&
                                                                    person[
                                                                        person.findIndex(
                                                                            (
                                                                                p: any
                                                                            ) =>
                                                                                p.id_person ===
                                                                                item.person_provider_temp
                                                                        )
                                                                    ].name}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Valor:
                                                                {" " +
                                                                    currencyFormatter(
                                                                        item.value
                                                                    )}
                                                            </div>
                                                        </>
                                                    }
                                                />{" "}
                                            </List.Item>
                                        )}
                                    >
                                        {" "}
                                    </List>
                                ) : (
                                    <StyleEmptyDiv>
                                        <Empty
                                            style={{
                                                height: "50px",
                                            }}
                                            description="Não existem créditos liberados para faturamento."
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                    </StyleEmptyDiv>
                                )}
                            </StyleDivList>
                        </StyleFirtsDiv>

                        <StyleSecondDiv>
                            <StyleTitleDiv>
                                <h1> Créditos com faturamento pendente:</h1>
                            </StyleTitleDiv>
                            <StyleDivList>
                                {dataCreditsProcessing &&
                                dataCreditsProcessing.length > 0 ? (
                                    <List
                                        className="list-dash"
                                        dataSource={dataCreditsProcessing}
                                        size="large"
                                        renderItem={(item: any) => (
                                            <List.Item
                                                style={{
                                                    backgroundColor: "#fff",
                                                }}
                                            >
                                                <List.Item.Meta
                                                    title={
                                                        <>
                                                            <div>
                                                                Data de
                                                                expiração:{" "}
                                                                {
                                                                    item.expire_date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[2]
                                                                }
                                                                /
                                                                {
                                                                    item.expire_date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[1]
                                                                }
                                                                /
                                                                {
                                                                    item.expire_date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[0]
                                                                }
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Data de criação:{" "}
                                                                {
                                                                    item.date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[2]
                                                                }
                                                                /
                                                                {
                                                                    item.date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[1]
                                                                }
                                                                /
                                                                {
                                                                    item.date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[0]
                                                                }
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Cliente:{" "}
                                                                {person.length >
                                                                    0 &&
                                                                    person[
                                                                        person.findIndex(
                                                                            (
                                                                                p: any
                                                                            ) =>
                                                                                p.id_person ===
                                                                                item.person_customer_temp
                                                                        )
                                                                    ].name}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Provedor:{" "}
                                                                {person.length >
                                                                    0 &&
                                                                    person[
                                                                        person.findIndex(
                                                                            (
                                                                                p: any
                                                                            ) =>
                                                                                p.id_person ===
                                                                                item.person_provider_temp
                                                                        )
                                                                    ].name}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Valor:
                                                                {" " +
                                                                    currencyFormatter(
                                                                        item.value
                                                                    )}
                                                            </div>
                                                        </>
                                                    }
                                                />{" "}
                                            </List.Item>
                                        )}
                                    >
                                        {" "}
                                    </List>
                                ) : (
                                    <StyleEmptyDiv>
                                        {" "}
                                        <Empty
                                            style={{
                                                height: "50px",
                                            }}
                                            description="Não existem créditos com faturamento pendente."
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                    </StyleEmptyDiv>
                                )}
                            </StyleDivList>
                        </StyleSecondDiv>
                    </StyleDash>
                    <StyleDash>
                        <div
                            style={{
                                width: "50%",
                                padding: "10px",
                                paddingLeft: "50px",
                                paddingRight: "5px",
                                marginBottom: "25px",
                            }}
                        >
                            <StyleTitleDiv>
                                <h1> Pedidos Pendentes:</h1>
                            </StyleTitleDiv>
                            <StyleDivList>
                                {dataInvoice && dataInvoice.length > 0 ? (
                                    <List
                                        className="list-dash"
                                        dataSource={dataInvoice}
                                        size="large"
                                        renderItem={(item: any) => (
                                            <List.Item
                                                style={{
                                                    backgroundColor: "#fff",
                                                }}
                                            >
                                                <List.Item.Meta
                                                    title={
                                                        <>
                                                            <div>
                                                                Data do resgate:{" "}
                                                                {
                                                                    item.date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[2]
                                                                }
                                                                /
                                                                {
                                                                    item.date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[1]
                                                                }
                                                                /
                                                                {
                                                                    item.date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[0]
                                                                }
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Cliente:{" "}
                                                                {person.length >
                                                                    0 &&
                                                                    person[
                                                                        person.findIndex(
                                                                            (
                                                                                p: any
                                                                            ) =>
                                                                                p.id_person ===
                                                                                item.person_customer_temp
                                                                        )
                                                                    ].name}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Produto:{" "}
                                                                {defaultProduct.products
                                                                    ? defaultProduct
                                                                          .products[
                                                                          defaultProduct.products.findIndex(
                                                                              (
                                                                                  p: any
                                                                              ) =>
                                                                                  p.id_product ===
                                                                                  item.product_temp
                                                                          )
                                                                      ]
                                                                          .description
                                                                    : ""}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Fornecedor:{" "}
                                                                {person.length >
                                                                    0 &&
                                                                    person[
                                                                        person.findIndex(
                                                                            (
                                                                                p: any
                                                                            ) =>
                                                                                p.id_person ===
                                                                                item.person_partner_temp
                                                                        )
                                                                    ].name}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Valor:
                                                                {" " +
                                                                    currencyFormatter(
                                                                        item.value
                                                                    )}
                                                            </div>
                                                        </>
                                                    }
                                                />{" "}
                                            </List.Item>
                                        )}
                                    >
                                        {" "}
                                    </List>
                                ) : (
                                    <StyleEmptyDiv>
                                        {" "}
                                        <Empty
                                            style={{
                                                height: "50px",
                                            }}
                                            description="Não existem resgates pendentes."
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                    </StyleEmptyDiv>
                                )}
                            </StyleDivList>
                        </div>
                    </StyleDash>
                </>
            )}
            {stateL.PersonLogin?.type === "PARTNER" && (
                <>
                    <div className="site-card-wrapper">
                        <Row gutter={[24, 0]}>
                            <Col span={6}>
                                <Card style={{ backgroundColor: "#162b75" }}>
                                    <Statistic
                                        title="Pedidos no mês"
                                        value={
                                            pendentes.invoices_Monthly?.length
                                        }
                                        valueStyle={{
                                            color: "#f1f1f1",
                                            fontWeight: "bold",
                                        }}
                                        prefix={<TrendUp size={22} />}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card style={{ backgroundColor: "#162b75" }}>
                                    <Statistic
                                        title="Pedidos no ano"
                                        value={
                                            pendentes.invoices_Yearly?.length
                                        }
                                        valueStyle={{
                                            color: "#f1f1f1",
                                            fontWeight: "bold",
                                        }}
                                        prefix={<TrendUp size={22} />}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card style={{ backgroundColor: "#162b75" }}>
                                    <Statistic
                                        title="Total de Pedidos"
                                        value={stateI.invoices?.data.length}
                                        valueStyle={{
                                            color: "#f1f1f1",
                                            fontWeight: "bold",
                                        }}
                                        prefix={<ShoppingBag size={22} />}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card style={{ backgroundColor: "#162b75" }}>
                                    <Statistic
                                        title="Total de Produtos Cadastrados"
                                        value={
                                            defaultProduct.products &&
                                            defaultProduct.products?.length
                                        }
                                        valueStyle={{
                                            color: "#f1f1f1",
                                            fontWeight: "bold",
                                        }}
                                        prefix={<Package size={22} />}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <StyleDash>
                        <StyleDashDiv>
                            <StyleTitleDiv>
                                <h1> Pedidos Pendentes:</h1>
                            </StyleTitleDiv>
                            <StyleDivList>
                                {dataInvoicePartner &&
                                dataInvoicePartner.length > 0 ? (
                                    <List
                                        className="list-dash"
                                        dataSource={dataInvoicePartner}
                                        size="large"
                                        renderItem={(item: any) => (
                                            <List.Item
                                                style={{
                                                    backgroundColor: "#fff",
                                                }}
                                            >
                                                <List.Item.Meta
                                                    title={
                                                        <>
                                                            <div>
                                                                Data do resgate:{" "}
                                                                {
                                                                    item.date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[2]
                                                                }
                                                                /
                                                                {
                                                                    item.date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[1]
                                                                }
                                                                /
                                                                {
                                                                    item.date
                                                                        .split(
                                                                            "T"
                                                                        )[0]
                                                                        .split(
                                                                            "-"
                                                                        )[0]
                                                                }
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Cliente:{" "}
                                                                {person.length >
                                                                    0 &&
                                                                    person[
                                                                        person.findIndex(
                                                                            (
                                                                                p: any
                                                                            ) =>
                                                                                p.id_person ===
                                                                                item.person_customer_temp
                                                                        )
                                                                    ].name}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Produto:{" "}
                                                                {defaultProduct.products
                                                                    ? defaultProduct
                                                                          .products[
                                                                          defaultProduct.products.findIndex(
                                                                              (
                                                                                  p: any
                                                                              ) =>
                                                                                  p.id_product ===
                                                                                  item.product_temp
                                                                          )
                                                                      ]
                                                                          .description
                                                                    : ""}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    color: "#484848",
                                                                    fontWeight:
                                                                        "normal",
                                                                }}
                                                            >
                                                                Valor:
                                                                {" " +
                                                                    currencyFormatter(
                                                                        item.value
                                                                    )}
                                                            </div>
                                                        </>
                                                    }
                                                />{" "}
                                            </List.Item>
                                        )}
                                    >
                                        {" "}
                                    </List>
                                ) : (
                                    <StyleEmptyDiv>
                                        {" "}
                                        <Empty
                                            style={{
                                                height: "50px",
                                            }}
                                            description="Não existem resgates pendentes."
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                    </StyleEmptyDiv>
                                )}
                            </StyleDivList>
                        </StyleDashDiv>
                    </StyleDash>
                </>
            )}
            {stateL.PersonLogin?.type === "PROVIDER" && (
                <>
                    <div>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Créditos" key={1}>
                                <div className="site-card-wrapper">
                                    <Row gutter={[24, 0]}>
                                        <Col span={4}>
                                            <Card
                                                style={{
                                                    backgroundColor: "#162b75",
                                                    height: "155px",
                                                }}
                                            >
                                                <Statistic
                                                    title="Créditos por Indicação"
                                                    value={
                                                        pendentes.Count_indication
                                                    }
                                                    valueStyle={{
                                                        color: "#f1f1f1",
                                                        fontWeight: "bold",
                                                    }}
                                                    prefix={
                                                        <TrendUp size={22} />
                                                    }
                                                />
                                            </Card>
                                        </Col>
                                        <Col span={4}>
                                            <Card
                                                style={{
                                                    backgroundColor: "#162b75",
                                                    height: "155px",
                                                }}
                                            >
                                                <Statistic
                                                    title="Créditos por Bonificação"
                                                    value={
                                                        pendentes.Count_bonus
                                                    }
                                                    valueStyle={{
                                                        color: "#f1f1f1",
                                                        fontWeight: "bold",
                                                    }}
                                                    prefix={
                                                        <TrendUp size={22} />
                                                    }
                                                />
                                            </Card>
                                        </Col>
                                        <Col span={4}>
                                            <Card
                                                style={{
                                                    backgroundColor: "#162b75",
                                                    height: "155px",
                                                }}
                                            >
                                                <Statistic
                                                    title="Créditos por Compra"
                                                    value={
                                                        pendentes.Count_purchase
                                                    }
                                                    valueStyle={{
                                                        color: "#f1f1f1",
                                                        fontWeight: "bold",
                                                    }}
                                                    prefix={
                                                        <TrendUp size={22} />
                                                    }
                                                />
                                            </Card>
                                        </Col>
                                        <Col span={6}>
                                            <Card
                                                style={{
                                                    backgroundColor: "#162b75",
                                                    height: "155px",
                                                }}
                                            >
                                                <Statistic
                                                    title="Valor Total de Créditos Expirados"
                                                    value={
                                                        pendentes.Credits_sum_expired
                                                            ? new Intl.NumberFormat(
                                                                  "pt-br",
                                                                  {
                                                                      style: "currency",
                                                                      currency:
                                                                          "BRL",
                                                                  }
                                                              ).format(
                                                                  pendentes.Credits_sum_expired *
                                                                      0.02
                                                              )
                                                            : 0
                                                    }
                                                    precision={2}
                                                    valueStyle={{
                                                        color: "#f1f1f1",
                                                        fontWeight: "bold",
                                                    }}
                                                    prefix={<Coins size={22} />}
                                                />
                                            </Card>
                                        </Col>
                                        <Col span={6}>
                                            <Card
                                                style={{
                                                    backgroundColor: "#162b75",
                                                    height: "155px",
                                                    marginRight: "10px",
                                                }}
                                            >
                                                <Statistic
                                                    title="Valor Total de Créditos Pagos"
                                                    value={
                                                        pendentes.Credits_sum_finished
                                                            ? new Intl.NumberFormat(
                                                                  "pt-br",
                                                                  {
                                                                      style: "currency",
                                                                      currency:
                                                                          "BRL",
                                                                  }
                                                              ).format(
                                                                  pendentes.Credits_sum_finished
                                                              )
                                                            : 0
                                                    }
                                                    precision={2}
                                                    valueStyle={{
                                                        color: "#f1f1f1",
                                                        fontWeight: "bold",
                                                    }}
                                                    prefix={<Coins size={22} />}
                                                />
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                                <StyleDash>
                                    <StyleFirtsDiv>
                                        <StyleTitleDiv>
                                            <h1> Créditos Pendentes:</h1>
                                        </StyleTitleDiv>
                                        <StyleDivList>
                                            {dataCreditsPending &&
                                            dataCreditsPending.length > 0 ? (
                                                <List
                                                    className="list-dash"
                                                    dataSource={
                                                        dataCreditsPending
                                                    }
                                                    size="large"
                                                    renderItem={(item: any) => (
                                                        <List.Item
                                                            style={{
                                                                backgroundColor:
                                                                    "#fff",
                                                            }}
                                                        >
                                                            <List.Item.Meta
                                                                title={
                                                                    <>
                                                                        <div>
                                                                            Data
                                                                            de
                                                                            criação:{" "}
                                                                            {
                                                                                item.date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[2]
                                                                            }
                                                                            /
                                                                            {
                                                                                item.date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[1]
                                                                            }
                                                                            /
                                                                            {
                                                                                item.date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[0]
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#484848",
                                                                                fontWeight:
                                                                                    "normal",
                                                                            }}
                                                                        >
                                                                            Data
                                                                            de
                                                                            expiração:{" "}
                                                                            {
                                                                                item.expire_date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[2]
                                                                            }
                                                                            /
                                                                            {
                                                                                item.expire_date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[1]
                                                                            }
                                                                            /
                                                                            {
                                                                                item.expire_date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[0]
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#484848",
                                                                                fontWeight:
                                                                                    "normal",
                                                                            }}
                                                                        >
                                                                            Cliente:{" "}
                                                                            {person.length >
                                                                                0 &&
                                                                                person[
                                                                                    person.findIndex(
                                                                                        (
                                                                                            p: any
                                                                                        ) =>
                                                                                            p.id_person ===
                                                                                            item.person_customer_temp
                                                                                    )
                                                                                ]
                                                                                    .name}
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#484848",
                                                                                fontWeight:
                                                                                    "normal",
                                                                            }}
                                                                        >
                                                                            Tipo
                                                                            de
                                                                            Crédito:{" "}
                                                                            {
                                                                                statusInvoice[
                                                                                    statusInvoice.findIndex(
                                                                                        (
                                                                                            s: any
                                                                                        ) =>
                                                                                            s.id ===
                                                                                            item.type
                                                                                    )
                                                                                ]
                                                                                    .label
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#484848",
                                                                                fontWeight:
                                                                                    "normal",
                                                                            }}
                                                                        >
                                                                            Valor:
                                                                            {" " +
                                                                                currencyFormatter(
                                                                                    item.value
                                                                                )}
                                                                        </div>
                                                                    </>
                                                                }
                                                            />{" "}
                                                        </List.Item>
                                                    )}
                                                >
                                                    {" "}
                                                </List>
                                            ) : (
                                                <StyleEmptyDiv>
                                                    {" "}
                                                    <Empty
                                                        style={{
                                                            height: "50px",
                                                        }}
                                                        description="Não existem créditos pendentes."
                                                        image={
                                                            Empty.PRESENTED_IMAGE_SIMPLE
                                                        }
                                                    />
                                                </StyleEmptyDiv>
                                            )}
                                        </StyleDivList>
                                    </StyleFirtsDiv>
                                    <StyleSecondDiv>
                                        <StyleTitleDiv>
                                            <h1>
                                                {" "}
                                                Créditos Liberados para
                                                faturamento:
                                            </h1>
                                        </StyleTitleDiv>
                                        <StyleDivList>
                                            {dataCreditsWaiting &&
                                            dataCreditsWaiting.length > 0 ? (
                                                <List
                                                    className="list-dash"
                                                    dataSource={
                                                        dataCreditsWaiting
                                                    }
                                                    size="large"
                                                    renderItem={(item: any) => (
                                                        <List.Item
                                                            style={{
                                                                backgroundColor:
                                                                    "#fff",
                                                            }}
                                                        >
                                                            <List.Item.Meta
                                                                title={
                                                                    <>
                                                                        <div>
                                                                            Data
                                                                            de
                                                                            expiração:{" "}
                                                                            {
                                                                                item.expire_date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[2]
                                                                            }
                                                                            /
                                                                            {
                                                                                item.expire_date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[1]
                                                                            }
                                                                            /
                                                                            {
                                                                                item.expire_date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[0]
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#484848",
                                                                                fontWeight:
                                                                                    "normal",
                                                                            }}
                                                                        >
                                                                            Data
                                                                            de
                                                                            criação:{" "}
                                                                            {
                                                                                item.date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[2]
                                                                            }
                                                                            /
                                                                            {
                                                                                item.date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[1]
                                                                            }
                                                                            /
                                                                            {
                                                                                item.date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[0]
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#484848",
                                                                                fontWeight:
                                                                                    "normal",
                                                                            }}
                                                                        >
                                                                            Cliente:{" "}
                                                                            {person.length >
                                                                                0 &&
                                                                                person[
                                                                                    person.findIndex(
                                                                                        (
                                                                                            p: any
                                                                                        ) =>
                                                                                            p.id_person ===
                                                                                            item.person_customer_temp
                                                                                    )
                                                                                ]
                                                                                    .name}
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#484848",
                                                                                fontWeight:
                                                                                    "normal",
                                                                            }}
                                                                        >
                                                                            Tipo
                                                                            de
                                                                            Crédito:{" "}
                                                                            {
                                                                                statusInvoice[
                                                                                    statusInvoice.findIndex(
                                                                                        (
                                                                                            s: any
                                                                                        ) =>
                                                                                            s.id ===
                                                                                            item.type
                                                                                    )
                                                                                ]
                                                                                    .label
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#484848",
                                                                                fontWeight:
                                                                                    "normal",
                                                                            }}
                                                                        >
                                                                            Valor:
                                                                            {" " +
                                                                                currencyFormatter(
                                                                                    item.value
                                                                                )}
                                                                        </div>
                                                                    </>
                                                                }
                                                            />{" "}
                                                        </List.Item>
                                                    )}
                                                >
                                                    {" "}
                                                </List>
                                            ) : (
                                                <StyleEmptyDiv>
                                                    <Empty
                                                        style={{
                                                            height: "50px",
                                                        }}
                                                        description="Não existem créditos liberados para faturamento."
                                                        image={
                                                            Empty.PRESENTED_IMAGE_SIMPLE
                                                        }
                                                    />
                                                </StyleEmptyDiv>
                                            )}
                                        </StyleDivList>
                                    </StyleSecondDiv>
                                </StyleDash>
                                <StyleDash>
                                    <div
                                        style={{
                                            width: "50%",
                                            padding: "10px",
                                            paddingLeft: "50px",
                                            paddingRight: "5px",
                                            marginBottom: "25px",
                                        }}
                                    >
                                        <StyleTitleDiv>
                                            <h1>
                                                {" "}
                                                Créditos com faturamento
                                                pendente:
                                            </h1>
                                        </StyleTitleDiv>
                                        <StyleDivList>
                                            {dataCreditsProcessing &&
                                            dataCreditsProcessing.length > 0 ? (
                                                <List
                                                    className="list-dash"
                                                    dataSource={
                                                        dataCreditsProcessing
                                                    }
                                                    size="large"
                                                    renderItem={(item: any) => (
                                                        <List.Item
                                                            style={{
                                                                backgroundColor:
                                                                    "#fff",
                                                            }}
                                                        >
                                                            <List.Item.Meta
                                                                title={
                                                                    <>
                                                                        <div>
                                                                            Data
                                                                            de
                                                                            expiração:{" "}
                                                                            {
                                                                                item.expire_date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[2]
                                                                            }
                                                                            /
                                                                            {
                                                                                item.expire_date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[1]
                                                                            }
                                                                            /
                                                                            {
                                                                                item.expire_date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[0]
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#484848",
                                                                                fontWeight:
                                                                                    "normal",
                                                                            }}
                                                                        >
                                                                            Data
                                                                            de
                                                                            criação:{" "}
                                                                            {
                                                                                item.date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[2]
                                                                            }
                                                                            /
                                                                            {
                                                                                item.date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[1]
                                                                            }
                                                                            /
                                                                            {
                                                                                item.date
                                                                                    .split(
                                                                                        "T"
                                                                                    )[0]
                                                                                    .split(
                                                                                        "-"
                                                                                    )[0]
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#484848",
                                                                                fontWeight:
                                                                                    "normal",
                                                                            }}
                                                                        >
                                                                            Cliente:{" "}
                                                                            {person.length >
                                                                                0 &&
                                                                                person[
                                                                                    person.findIndex(
                                                                                        (
                                                                                            p: any
                                                                                        ) =>
                                                                                            p.id_person ===
                                                                                            item.person_customer_temp
                                                                                    )
                                                                                ]
                                                                                    .name}
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#484848",
                                                                                fontWeight:
                                                                                    "normal",
                                                                            }}
                                                                        >
                                                                            Tipo
                                                                            de
                                                                            Crédito:{" "}
                                                                            {
                                                                                statusInvoice[
                                                                                    statusInvoice.findIndex(
                                                                                        (
                                                                                            s: any
                                                                                        ) =>
                                                                                            s.id ===
                                                                                            item.type
                                                                                    )
                                                                                ]
                                                                                    .label
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                color: "#484848",
                                                                                fontWeight:
                                                                                    "normal",
                                                                            }}
                                                                        >
                                                                            Valor:
                                                                            {" " +
                                                                                currencyFormatter(
                                                                                    item.value
                                                                                )}
                                                                        </div>
                                                                    </>
                                                                }
                                                            />{" "}
                                                        </List.Item>
                                                    )}
                                                >
                                                    {" "}
                                                </List>
                                            ) : (
                                                <StyleEmptyDiv>
                                                    {" "}
                                                    <Empty
                                                        style={{
                                                            height: "50px",
                                                        }}
                                                        description="Não existem créditos com faturamento pendente."
                                                        image={
                                                            Empty.PRESENTED_IMAGE_SIMPLE
                                                        }
                                                    />
                                                </StyleEmptyDiv>
                                            )}
                                        </StyleDivList>
                                    </div>
                                </StyleDash>
                            </TabPane>
                            {isVisible && (
                                <TabPane tab="Produtos" key={2}>
                                    <div className="site-card-wrapper">
                                        <Row gutter={[24, 0]}>
                                            <Col span={6}>
                                                <Card
                                                    style={{
                                                        backgroundColor:
                                                            "#162b75",
                                                    }}
                                                >
                                                    <Statistic
                                                        title="Pedidos no mês"
                                                        value={
                                                            pendentes
                                                                .invoices_Monthly
                                                                ?.length
                                                        }
                                                        valueStyle={{
                                                            color: "#f1f1f1",
                                                            fontWeight: "bold",
                                                        }}
                                                        prefix={
                                                            <TrendUp
                                                                size={22}
                                                            />
                                                        }
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={6}>
                                                <Card
                                                    style={{
                                                        backgroundColor:
                                                            "#162b75",
                                                    }}
                                                >
                                                    <Statistic
                                                        title="Pedidos no ano"
                                                        value={
                                                            pendentes
                                                                .invoices_Yearly
                                                                ?.length
                                                        }
                                                        valueStyle={{
                                                            color: "#f1f1f1",
                                                            fontWeight: "bold",
                                                        }}
                                                        prefix={
                                                            <TrendUp
                                                                size={22}
                                                            />
                                                        }
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={6}>
                                                <Card
                                                    style={{
                                                        backgroundColor:
                                                            "#162b75",
                                                    }}
                                                >
                                                    <Statistic
                                                        title="Total de Pedidos"
                                                        value={
                                                            stateI.invoices
                                                                ?.data.length
                                                        }
                                                        valueStyle={{
                                                            color: "#f1f1f1",
                                                            fontWeight: "bold",
                                                        }}
                                                        prefix={
                                                            <ShoppingBag
                                                                size={22}
                                                            />
                                                        }
                                                    />
                                                </Card>
                                            </Col>
                                            <Col span={6}>
                                                <Card
                                                    style={{
                                                        backgroundColor:
                                                            "#162b75",
                                                    }}
                                                >
                                                    <Statistic
                                                        title="Total de Produtos Cadastrados"
                                                        value={
                                                            defaultProduct.products &&
                                                            defaultProduct
                                                                .products
                                                                ?.length
                                                        }
                                                        valueStyle={{
                                                            color: "#f1f1f1",
                                                            fontWeight: "bold",
                                                        }}
                                                        prefix={
                                                            <Package
                                                                size={22}
                                                            />
                                                        }
                                                    />
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                    <StyleDash>
                                        <StyleDashDiv>
                                            <StyleTitleDiv>
                                                <h1> Pedidos Pendentes:</h1>
                                            </StyleTitleDiv>
                                            <StyleDivList>
                                                {dataInvoicePartner &&
                                                dataInvoicePartner.length >
                                                    0 ? (
                                                    <List
                                                        className="list-dash"
                                                        dataSource={
                                                            dataInvoicePartner
                                                        }
                                                        size="large"
                                                        renderItem={(
                                                            item: any
                                                        ) => (
                                                            <List.Item
                                                                style={{
                                                                    backgroundColor:
                                                                        "#fff",
                                                                }}
                                                            >
                                                                <List.Item.Meta
                                                                    title={
                                                                        <>
                                                                            <div>
                                                                                Data
                                                                                do
                                                                                resgate:{" "}
                                                                                {
                                                                                    item.date
                                                                                        .split(
                                                                                            "T"
                                                                                        )[0]
                                                                                        .split(
                                                                                            "-"
                                                                                        )[2]
                                                                                }

                                                                                /
                                                                                {
                                                                                    item.date
                                                                                        .split(
                                                                                            "T"
                                                                                        )[0]
                                                                                        .split(
                                                                                            "-"
                                                                                        )[1]
                                                                                }

                                                                                /
                                                                                {
                                                                                    item.date
                                                                                        .split(
                                                                                            "T"
                                                                                        )[0]
                                                                                        .split(
                                                                                            "-"
                                                                                        )[0]
                                                                                }
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    color: "#484848",
                                                                                    fontWeight:
                                                                                        "normal",
                                                                                }}
                                                                            >
                                                                                Cliente:{" "}
                                                                                {person.length >
                                                                                    0 &&
                                                                                    person[
                                                                                        person.findIndex(
                                                                                            (
                                                                                                p: any
                                                                                            ) =>
                                                                                                p.id_person ===
                                                                                                item.person_customer_temp
                                                                                        )
                                                                                    ]
                                                                                        .name}
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    color: "#484848",
                                                                                    fontWeight:
                                                                                        "normal",
                                                                                }}
                                                                            >
                                                                                Produto:{" "}
                                                                                {defaultProduct.products
                                                                                    ? defaultProduct
                                                                                          .products[
                                                                                          defaultProduct.products.findIndex(
                                                                                              (
                                                                                                  p: any
                                                                                              ) =>
                                                                                                  p.id_product ===
                                                                                                  item.product_temp
                                                                                          )
                                                                                      ]
                                                                                          .description
                                                                                    : ""}
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    color: "#484848",
                                                                                    fontWeight:
                                                                                        "normal",
                                                                                }}
                                                                            >
                                                                                Valor:
                                                                                {" " +
                                                                                    currencyFormatter(
                                                                                        item.value
                                                                                    )}
                                                                            </div>
                                                                        </>
                                                                    }
                                                                />{" "}
                                                            </List.Item>
                                                        )}
                                                    >
                                                        {" "}
                                                    </List>
                                                ) : (
                                                    <StyleEmptyDiv>
                                                        {" "}
                                                        <Empty
                                                            style={{
                                                                height: "50px",
                                                            }}
                                                            description="Não existem resgates pendentes."
                                                            image={
                                                                Empty.PRESENTED_IMAGE_SIMPLE
                                                            }
                                                        />
                                                    </StyleEmptyDiv>
                                                )}
                                            </StyleDivList>
                                        </StyleDashDiv>
                                    </StyleDash>
                                </TabPane>
                            )}
                        </Tabs>
                    </div>
                </>
            )}
        </>
    );
}
