import { InfoCircleOutlined } from "@ant-design/icons";
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Popover,
    Row,
    Select,
    Switch,
} from "antd";
import { MaskedInput } from "antd-mask-input";
import { useCallback, useContext, useEffect, useState } from "react";
import { LoginContext } from "../../../context/LoginContext";
import { ProductContext } from "../../../context/ProductContext";
import { ProductGroupContext } from "../../../context/ProductGroupContext";
import {
    convertGranos,
    currencyFormatter,
    currencyFormatterGranos,
    currencyParser,
} from "../../../helpers";

const { TextArea } = Input;
const { Option } = Select;

interface FormProductProps {
    cont?: any;
    setOpen: any;
    productGroup?: any;
    personPartner?: any;
}

export function FormProduct(props: FormProductProps) {
    const [validateDesc, setValidateDesc] = useState(props.cont ? true : false);
    const [validateName, setValidateName] = useState(props.cont ? true : false);
    const [validateValue, setValidateValue] = useState(
        props.cont ? true : false
    );
    const [validateAv, setValidateAv] = useState(props.cont ? true : false);

    const [valueProd, setValueProd] = useState(
        props.cont ? props.cont.value_granos : 0
    );

    const [showI, setShowI] = useState(false);

    const { postProduct } = useContext(ProductContext);
    const { stateL } = useContext(LoginContext);

    const handleDisable = useCallback(() => {
        if (
            validateDesc === true &&
            validateValue === true &&
            validateAv === true
        ) {
            return false;
        }
        return true;
    }, [validateDesc, validateValue, validateAv]);

    // function formatCoin(value: any) {
    //     console.log(value.target.value);
    //     var v = value.target.value.replace(/\D/g, "");
    //     v = (v / 100).toFixed(2) + "";
    //     v = v.replace(".", ",");
    //     v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    //     v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    //     return (value.target.value = v);
    // }

    const handleSave = (v: any) => {
        if (props.cont) {
            let obj = {
                id_product: props.cont.id_product,
                description: v.description,
                detail: v.detail,
                value_product: v.value_product,
                value_granos: valueProd,
                available: v.available,
                product_group_temp: v.product_group_temp,
                main: v.main,
                exclusive: props.cont.exclusive,
            };
            postProduct(obj);
        } else {
            let obj = {
                description: v.description,
                detail: v.detail,
                value_product: v.value_product,
                value_granos: valueProd,
                available: v.available,
                product_group_temp: v.product_group_temp,
                main: v.main,
                exclusive:
                    stateL.PersonLogin?.type === "PROVIDER" ? true : false,
            };
            postProduct(obj);
        }
    };

    useEffect(() => {
        if (props.cont) {
            if (props.cont.exclusive === true) {
                setShowI(true);
            } else setShowI(false);
        }

        if (stateL.PersonLogin?.type === "PROVIDER") {
            setShowI(true);
        }
    }, [props.cont, stateL]);

    return (
        <Form
            layout="vertical"
            onFinish={(v: any) => {
                handleSave(v);
                props.setOpen(false);
            }}
            initialValues={props.cont}
        >
            {props.cont && (
                <Form.Item name="id_product" style={{ display: "none" }}>
                    <Input type="hidden" name="id_product"></Input>
                </Form.Item>
            )}
            {showI && (
                <Row gutter={[24, 0]}>
                    <Col span={24}>
                        <div
                            style={{
                                display: "flex",
                                flexGrow: "2",
                                justifyContent: "flex-end",
                                marginBottom: "-15px",
                                marginTop: "-10px",
                                cursor: "pointer",
                            }}
                        >
                            {" "}
                            <Popover
                                title="Produto exclusivo"
                                trigger="hover"
                                content={
                                    <div>
                                        Este produto será cadastrado <br></br>
                                        como exclusivo.
                                        <br></br>Nesse caso o produto só pode
                                        <br></br>
                                        ser adquirido com Granos<br></br>
                                        disponiblizados pela sua empresa.
                                    </div>
                                }
                            >
                                <InfoCircleOutlined
                                    style={{
                                        fontSize: "16px",
                                    }}
                                />
                            </Popover>
                        </div>
                    </Col>
                </Row>
            )}
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Coloque uma Descrição",
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value) {
                                        return (
                                            setValidateDesc(false),
                                            Promise.reject()
                                        );
                                    }
                                    setValidateDesc(true);
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                        label="Descrição:"
                        name="description"
                    >
                        <TextArea
                            placeholder="Digite uma breve descrição"
                            showCount
                            maxLength={100}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Detalhes:" name="detail">
                        <TextArea
                            placeholder="Digite os detalhes do produto"
                            showCount
                            maxLength={255}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        label="Disponibilidade do produto:"
                        name="available"
                        rules={[
                            {
                                required: true,
                                message: "Campo obrigatório",
                                validator(_, value) {
                                    if (value.length < 0) {
                                        return (
                                            setValidateAv(false),
                                            Promise.reject()
                                        );
                                    }
                                    if (value >= 0) {
                                        return (
                                            setValidateAv(true),
                                            Promise.resolve()
                                        );
                                    }
                                },
                            },
                        ]}
                    >
                        <InputNumber min={0} max={10} placeholder="Qtd." />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Categoria:" name="product_group_temp">
                        <Select
                            placeholder="Selecione o tipo"
                            onChange={() => {
                                setValidateName(true);
                            }}
                        >
                            {props.productGroup.map((p: any) => {
                                return (
                                    <Option
                                        key={p.id_product_group}
                                        value={p.id_product_group}
                                    >
                                        {p.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Divider
                style={{
                    marginTop: "0px",
                    marginBottom: "10px",
                }}
            />{" "}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "10px",
                    fontStyle: "italic",
                    color: "rgba(0, 0, 0, 0.6)",
                }}
            >
                Informações de valor e conversão em Granos
            </div>
            <Row gutter={[24, 0]}>
                <Col span={12}>
                    <Form.Item
                        label="Valor do Produto:"
                        name="value_product"
                        rules={[
                            {
                                required: true,
                                message: "Digite um valor",
                                validator(_, value) {
                                    if (value.length === 0) {
                                        return (
                                            setValidateValue(false),
                                            Promise.reject()
                                        );
                                    }
                                    if (value > 0) {
                                        return (
                                            setValidateValue(true),
                                            Promise.resolve()
                                        );
                                    }
                                },
                            },
                        ]}
                    >
                        <InputNumber
                            min={0.01}
                            addonAfter="$"
                            placeholder="Valor em Reais"
                            formatter={value => currencyFormatter(value)}
                            parser={val => currencyParser(val)}
                            onChange={(value: any) =>
                                setValueProd(convertGranos(value))
                            }
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Valor em Granos:" name="value_granos">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                fontStyle: "italic",
                                color: "rgba(0, 0, 0, 0.6)",
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                borderRadius: "2px",
                                padding: "0 11px",
                                height: "30px",
                            }}
                        >
                            {/* <Input type="hidden" name="value_granos"></Input> */}
                            {currencyFormatterGranos(valueProd)}
                        </div>
                    </Form.Item>
                </Col>
                <Form.Item
                    label="Produto em destaque:"
                    name="main"
                    hidden={stateL.PersonLogin?.type !== "MASTER"}
                >
                    <Switch
                        checkedChildren="Sim"
                        unCheckedChildren="Não"
                        defaultChecked={props.cont ? props.cont.main : false}
                    />
                </Form.Item>
            </Row>
            {stateL.PersonLogin?.type === "MASTER" && (
                <>
                    <Divider
                        style={{
                            marginTop: "0px",
                            marginBottom: "10px",
                        }}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "10px",
                            fontStyle: "italic",
                            color: "rgba(0, 0, 0, 0.6)",
                        }}
                    >
                        Informações da origem do produto
                    </div>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            Nome da empresa parceira:
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontStyle: "italic",
                                    color: "rgba(0, 0, 0, 0.6)",
                                    border: "1px solid rgba(0, 0, 0, 0.1)",
                                    borderRadius: "2px",
                                    padding: "0 11px",
                                    height: "30px",
                                    marginTop: "10px",
                                }}
                            >
                                {props.personPartner}
                            </div>
                        </Col>
                    </Row>
                </>
            )}
            <div style={{ marginTop: "20px" }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={handleDisable()}
                    block
                >
                    Salvar Produto
                </Button>
            </div>
        </Form>
    );
}
