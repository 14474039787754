import { Button, Form, Input } from "antd";
import React, { useContext, useState } from "react";
import { LoginContext } from "../../context/LoginContext";
import "../../styles/login.css";
import { login } from "../../types";

function Username() {
    const [loginSending, SetLoginSending] = useState(false);
    const { pre_login } = useContext(LoginContext);

    function handleSubmit(value: login) {
        pre_login(value, SetLoginSending);
    }
    const validationNumber = /\d{11}/g;
    return (
        <>
            <Form className="form-login" onFinish={v => handleSubmit(v)}>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                    }}
                >
                    <img
                        src={
                            process.env.REACT_APP_BASE_URL_ROOT +
                            "GRANOS-logo.svg"
                        }
                        alt="granos logo"
                        className="img-login"
                    />
                </div>
                <Form.Item
                    name="identification"
                    rules={[
                        {
                            required: true,
                            message: "Informe seu Usuário",
                        },
                        // {
                        //     pattern: validationNumber,
                        //     message: "Digite um CPF/CNPF válido",
                        // },
                        // {
                        //     max: 14,
                        //     message: "Digite um CPF/CNPF válido",
                        // },
                    ]}
                >
                    <Input placeholder="CNPJ" className="input-login" />
                </Form.Item>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        width: "100%",
                    }}
                >
                    <Form.Item style={{ alignItems: "flex-end", flex: 1 }}>
                        <Button
                            loading={loginSending}
                            type="primary"
                            block
                            htmlType="submit"
                            className="btn-login"
                        >
                            Entrar
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </>
    );
}

export default Username;
