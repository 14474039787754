import { Modal as ModalAntd } from "antd";

export function Modal({
    children,
    title,
    isVisible,
    setIsVisible,
    width,
}: //save,
{
    children: JSX.Element;
    title: string;
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    width: number;
    //save:
}) {
    return (
        <ModalAntd
            title={title}
            visible={isVisible}
            width={width}
            onCancel={() => setIsVisible(false)}
            destroyOnClose={true}
            footer={null}
        >
            {children}
        </ModalAntd>
    );
}
