import { LineOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useContext, useState } from "react";
import { LoginContext } from "../context/LoginContext";

export interface IModalTokenExpiredProps {}

export function ModalTokenExpired(props: IModalTokenExpiredProps) {
    const { stateL } = useContext(LoginContext);
    return (
        <div>
            <Modal
                centered={true}
                visible={stateL.tokenExpired}
                width={300}
                bodyStyle={{
                    height: "200px",
                }}
                destroyOnClose={true}
                closable={false}
                footer={null}
                maskClosable={false}
                className="modal_token"
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "10px",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                    }}
                >
                    <WarningOutlined
                        style={{
                            fontSize: "50px",
                            color: "#ff0000d6",
                        }}
                    />

                    <div style={{ marginBottom: "10px" }}>
                        <strong>A sua sessão expirou.</strong>
                    </div>
                    <Button
                        onClick={() => {
                            localStorage.removeItem("token");
                            window.location.reload();
                        }}
                    >
                        Fazer login novamente
                    </Button>
                </div>
            </Modal>
        </div>
    );
}
