import { createContext, useState, useContext } from "react";
import { IProductGroup } from "../types";
import { LoginContext } from "./LoginContext";

const productGroupObj: any = [
    {
        id_product_group: "",
        name: "",
        type: "",
    },
];

export const ProductGroupContext = createContext(productGroupObj);

export function ProductGroupContextProvider({ children }: { children: any }) {
    const [productGroup, setProductGroup] = useState<IProductGroup[]>([]);
    const { dispatchL } = useContext(LoginContext);

    function getProductGroup() {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/product_group/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    setProductGroup(res);
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function postProductGroup(value: IProductGroup) {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/product_group/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    getProductGroup();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function delProductGroup(value: string) {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/product_group/` + value,
            {
                method: "delete",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    getProductGroup();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <ProductGroupContext.Provider
            value={{
                productGroup,
                getProductGroup,
                postProductGroup,
                delProductGroup,
            }}
        >
            {children}
        </ProductGroupContext.Provider>
    );
}
