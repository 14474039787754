import { message } from "antd";
import {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";
import { productReducer } from "../Reducers/ProductReducers";

import { IProduct, IProductFiles } from "../types";
import { LoginContext } from "./LoginContext";
import PersonContextProvider from "./PersonContext";
import {
    ProductGroupContext,
    ProductGroupContextProvider,
} from "./ProductGroupContext";

export interface IStateProduct {
    uploading?: boolean;
    products?: IProduct[];
    productFiles?: IProductFiles[];
}

export interface IDispatchProduct {
    type: string;
    payload: IStateProduct;
}

const productObj = {
    defaultProduct: {} as IStateProduct,
    dispatch: (value: IDispatchProduct) => {},
    getProduct: () => {},
    postProduct: (value: IProduct) => {},
    delProduct: (id_product: string) => {},

    getProductFiles: (id_product: string) => {},
    uploadProductFiles: (value: FormData) => {},
    delProductFiles: (id_productFiles: string) => {},
};
export const ProductContext = createContext(productObj);

export function ProductContextProvider({ children }: { children: any }) {
    const [defaultProduct, dispatch] = useReducer(productReducer, {
        uploading: false,
    });
    const { dispatchL } = useContext(LoginContext);
    function getProduct() {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/product/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    dispatch({
                        type: "set_product",
                        payload: { products: res },
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function postProduct(value: IProduct) {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/product/`, {
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(value),
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    return getProduct();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function delProduct(id_product: string) {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/product/` + id_product,
            {
                method: "delete",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    getProduct();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function getProductFiles(value: string) {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/product_files/` + value,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json;charset=UTF-8",
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    dispatch({
                        type: "set_productFiles",
                        payload: { productFiles: res },
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    function uploadProductFiles(value: FormData) {
        fetch(`${process.env.REACT_APP_BASE_URL_API}api/product_files/`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: value,
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    message.success("Arquivo enviado com sucesso!");
                    dispatch({
                        type: "set_uploading",
                        payload: { uploading: false },
                    });
                    getProductFiles(res.product_temp);
                }
            })
            .catch(error => {
                console.log(error);
                message.error("Ocorreu um erro ao enviar o arquivo!");
                dispatch({
                    type: "set_uploading",
                    payload: { uploading: false },
                });
            });
    }

    function delProductFiles(value: any) {
        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/product_files/` + value,
            {
                method: "delete",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    if (response.status === 401) {
                        dispatchL({
                            type: "set_token",
                            payload: { tokenExpired: true },
                        });
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    message.success("Arquivo deletado com sucesso!");
                    dispatch({
                        type: "set_uploading",
                        payload: { uploading: false },
                    });
                }
            })
            .catch(error => {
                console.log(error);
                message.error("Ocorreu um erro ao deletar o arquivo!");
                dispatch({
                    type: "set_uploading",
                    payload: { uploading: false },
                });
            });
    }

    return (
        <ProductGroupContextProvider>
            <PersonContextProvider>
                <ProductContext.Provider
                    value={{
                        getProduct,
                        defaultProduct,
                        dispatch,
                        postProduct,
                        delProduct,
                        getProductFiles,
                        uploadProductFiles,
                        delProductFiles,
                    }}
                >
                    {children}
                </ProductContext.Provider>
            </PersonContextProvider>
        </ProductGroupContextProvider>
    );
}
