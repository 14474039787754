import { IDispatchC, IStateC } from "../context/CreditContext";

export function creditReducer(state: IStateC, action: IDispatchC) {
    switch (action.type) {
        case "set_credit":
            return { ...state, ...action.payload };
        case "set_open":
            return { ...state, ...action.payload };
        case "set_uploading":
            return { ...state, ...action.payload };
        case "set_creditFiles":
            return { ...state, ...action.payload };
    }
    throw Error("Action não encontrada: " + action.type);
}
