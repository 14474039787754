import {
    DeleteOutlined,
    DownloadOutlined,
    EyeOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    List,
    Popconfirm,
    Row,
    Upload,
} from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { Modal as ModalAntd } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { ProductContext } from "../../../context/ProductContext";

const { TextArea } = Input;

interface FormProductFilesProps {
    product?: any;
    setOpen: any;
}

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });

export function FormProductFiles(props: FormProductFilesProps) {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [img, setImg] = useState("");
    const [anexAdd, setAnexAdd] = useState(true);
    const [openCard, setOpenCard] = useState(false);

    const [validateDesc, setValidateDesc] = useState(false);

    const [file, setFile] = useState<any>([]);
    const [files, setFiles] = useState<any>([]);

    const {
        uploadProductFiles,
        dispatch,
        delProductFiles,
        defaultProduct,
        getProductFiles,
    } = useContext(ProductContext);

    const propss: UploadProps = {
        accept: ".jpg, .jpeg, .png, .tiff,.heic,.svg,.pdf,.mp4, .webm",
        beforeUpload: file => {
            setFileList(fileList.concat(file));
            return false;
        },
    };

    const handleFile: UploadProps["onChange"] = ({ fileList: newFileList }) => {
        setFile(newFileList);
    };
    const handleFiles: UploadProps["onChange"] = ({
        fileList: newFileList,
    }) => {
        setFiles(newFileList);
    };

    const handleUpload = (v: any) => {
        // let content = JSON.stringify({
        //     description: v.description,
        //     product_temp: props.product.id_product,
        //     // thumb_product: v.thumb_product,
        // });

        dispatch({ type: "set_uploading", payload: { uploading: true } });
        // fileList.forEach(file => {
        //     console.log(file as RcFile);
        //     let formData = new FormData();
        //     formData.append("file", file as RcFile);
        //     if (content) {
        //         formData.append("content", content);
        //     }
        //     console.log(v);
        //     //uploadProductFiles(formData);
        // });

        if (files.length > 0) {
            files.forEach((f: any) => {
                let formData = new FormData();
                formData.append("files", f.originFileObj as RcFile);
                if (v.description) {
                    formData.append("content", v.description + " - " + f.name);
                }
                formData.append("product_temp", props.product.id_product);

                uploadProductFiles(formData);
            });
        }

        if (file.length > 0) {
            file.forEach((f: any) => {
                let formData = new FormData();
                formData.append("file", f.originFileObj as RcFile);
                if (v.description) {
                    formData.append("content", v.description + " - " + f.name);
                }
                formData.append("product_temp", props.product.id_product);
                uploadProductFiles(formData);
            });
        }
    };

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewVisible(true);
    };

    const fetchImage = (item: any) => {
        let link: any = document.createElement("a");

        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/product_files/download/${item.id_product_files}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.blob();
                } else {
                    if (response.status === 401) {
                        console.log("erro no token");
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    const imageObjURL = URL.createObjectURL(res);

                    setImg(imageObjURL);
                    link.src = imageObjURL;
                    link.setAttribute(
                        "src",
                        `${item.description}.${item.file.split(".")[1]}`
                    );

                    link.click();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
    const fetchFile = (item: any) => {
        let link: any = document.createElement("a");

        fetch(
            `${process.env.REACT_APP_BASE_URL_API}api/product_files/download/${item.id_product_files}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        )
            .then((response: any) => {
                if (response.ok) {
                    return response.blob();
                } else {
                    if (response.status === 401) {
                        console.log("erro no token");
                    }
                }
            })
            .then(res => {
                if (res.erro) {
                    console.log(res.erro);
                } else {
                    const imageObjURL = URL.createObjectURL(res);
                    link.href = imageObjURL;
                    link.setAttribute(
                        "download",
                        `${item.description}.${item.file.split(".")[1]}`
                    );

                    link.click();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleDisable = useCallback(() => {
        if (validateDesc === true) {
            return false;
        }
        return true;
    }, [validateDesc]);

    useEffect(() => {
        getProductFiles(props.product.id_product);
    }, []);

    useEffect(() => {
        if (defaultProduct.productFiles) {
            if (defaultProduct.productFiles?.length > 0) {
                setValidateDesc(true);
            }
        }
    }, [defaultProduct]);

    return (
        <>
            {defaultProduct.productFiles &&
            defaultProduct.productFiles?.length > 0 ? (
                <>
                    <Button
                        style={{
                            display:
                                defaultProduct.productFiles &&
                                defaultProduct.productFiles.length > 0
                                    ? "hidden"
                                    : "inline-block",
                            textAlign: "end",
                            marginBottom: "10px",
                            marginTop: "-10px",
                        }}
                        type="link"
                        block
                        onClick={() => setAnexAdd(!anexAdd)}
                    >
                        {defaultProduct.productFiles &&
                            defaultProduct.productFiles.length > 0 &&
                            anexAdd &&
                            "Adicionar Anexo"}
                        {defaultProduct.productFiles &&
                            defaultProduct.productFiles.length > 0 &&
                            !anexAdd &&
                            "Cancelar Anexo"}
                    </Button>
                    {anexAdd ? (
                        <>
                            <List
                                dataSource={defaultProduct.productFiles}
                                renderItem={item => (
                                    <List.Item
                                        actions={[
                                            <Popconfirm
                                                title="Você tem certeza que vai cancelar esse arquivo?"
                                                okText="Sim"
                                                cancelText="Cancelar"
                                                okType="danger"
                                                onConfirm={() => {
                                                    delProductFiles(
                                                        item.id_product_files
                                                    );

                                                    props.setOpen(false);
                                                }}
                                            >
                                                {" "}
                                                <DeleteOutlined
                                                    key="delete"
                                                    style={{
                                                        cursor: "pointer",
                                                        color: "red",
                                                    }}
                                                />
                                            </Popconfirm>,
                                            <div>
                                                {item.file?.split(".")[1] ===
                                                    "pdf" ||
                                                item.file?.split(".")[1] ===
                                                    "mp4" ||
                                                item.file?.split(".")[1] ===
                                                    "webm" ||
                                                item.file?.split(".")[1] ===
                                                    "svg" ? (
                                                    <DownloadOutlined
                                                        onClick={() => {
                                                            fetchFile(item);
                                                            setOpenCard(false);
                                                        }}
                                                    />
                                                ) : (
                                                    <EyeOutlined
                                                        onClick={() => {
                                                            fetchImage(item);
                                                            setOpenCard(
                                                                !openCard
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </div>,
                                        ]}
                                    >
                                        <List.Item.Meta
                                            title={item.description}
                                        />
                                    </List.Item>
                                )}
                            ></List>
                            <div
                                style={{
                                    borderTop: "1px solid grey",
                                }}
                            >
                                {openCard && (
                                    <>
                                        <Card
                                            style={{
                                                width: 300,
                                                marginTop: "25px",
                                            }}
                                            cover={
                                                <img
                                                    alt="example"
                                                    src={img}
                                                    style={{
                                                        width: "200px",
                                                        height: "200px",
                                                    }}
                                                />
                                            }
                                        ></Card>
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <Form
                            layout="vertical"
                            initialValues={{
                                product_temp: props.product.id_product,
                            }}
                            onFinish={(v: any) => {
                                handleUpload(v);
                                props.setOpen(false);
                            }}
                        >
                            <Row gutter={[24, 0]}>
                                <Col span={12}>
                                    <Form.Item
                                        name="product_temp"
                                        style={{ display: "none" }}
                                    >
                                        <Input
                                            type="hidden"
                                            name="product_temp"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Midia do produto:"
                                        name="files"
                                    >
                                        <Upload
                                            listType="picture"
                                            {...propss}
                                            onPreview={handlePreview}
                                            onChange={handleFiles}
                                        >
                                            <Button icon={<UploadOutlined />}>
                                                {" "}
                                                Upload
                                            </Button>
                                        </Upload>
                                        <ModalAntd
                                            visible={previewVisible}
                                            onCancel={() =>
                                                setPreviewVisible(false)
                                            }
                                            footer={null}
                                            width={500}
                                        >
                                            <img
                                                alt="example"
                                                style={{
                                                    width: "100%",
                                                    padding: "20px",
                                                }}
                                                src={previewImage}
                                            />
                                        </ModalAntd>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    {" "}
                                    <Form.Item
                                        label="Midia do produto:"
                                        name="file"
                                    >
                                        <Upload
                                            listType="picture"
                                            {...propss}
                                            onPreview={handlePreview}
                                            maxCount={1}
                                            onChange={handleFile}
                                        >
                                            <Button icon={<UploadOutlined />}>
                                                {" "}
                                                Upload miniatura para o site
                                            </Button>
                                        </Upload>
                                        <ModalAntd
                                            visible={previewVisible}
                                            onCancel={() =>
                                                setPreviewVisible(false)
                                            }
                                            footer={null}
                                            width={500}
                                        >
                                            <img
                                                alt="example"
                                                style={{
                                                    width: "100%",
                                                    padding: "20px",
                                                }}
                                                src={previewImage}
                                            />
                                        </ModalAntd>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[24, 0]}>
                                <Col span={18}>
                                    <Form.Item
                                        label="Descrição:"
                                        name="description"
                                    >
                                        <TextArea
                                            placeholder="Digite a descrição dos arquivos"
                                            showCount
                                            maxLength={100}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div style={{ marginTop: "20px" }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    //disabled={handleDisable()}
                                    loading={defaultProduct.uploading}
                                    block
                                >
                                    Salvar Midia
                                </Button>
                            </div>
                        </Form>
                    )}
                </>
            ) : (
                <Form
                    layout="vertical"
                    initialValues={{
                        product_temp: props.product.id_product,
                    }}
                    onFinish={(v: any) => {
                        handleUpload(v);
                        props.setOpen(false);
                    }}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <Form.Item
                                name="product_temp"
                                style={{ display: "none" }}
                            >
                                <Input type="hidden" name="product_temp" />
                            </Form.Item>
                            <Form.Item label="Midia do produto:" name="files">
                                <Upload
                                    listType="picture"
                                    {...propss}
                                    onPreview={handlePreview}
                                    onChange={handleFiles}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        {" "}
                                        Upload
                                    </Button>
                                </Upload>
                                <ModalAntd
                                    visible={previewVisible}
                                    onCancel={() => setPreviewVisible(false)}
                                    footer={null}
                                    width={500}
                                >
                                    <img
                                        alt="example"
                                        style={{
                                            width: "100%",
                                            padding: "20px",
                                        }}
                                        src={previewImage}
                                    />
                                </ModalAntd>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {" "}
                            <Form.Item label="Midia do produto:" name="file">
                                <Upload
                                    listType="picture"
                                    {...propss}
                                    onPreview={handlePreview}
                                    maxCount={1}
                                    onChange={handleFile}
                                >
                                    <Button icon={<UploadOutlined />}>
                                        {" "}
                                        Upload miniatura para o site
                                    </Button>
                                </Upload>
                                <ModalAntd
                                    visible={previewVisible}
                                    onCancel={() => setPreviewVisible(false)}
                                    footer={null}
                                    width={500}
                                >
                                    <img
                                        alt="example"
                                        style={{
                                            width: "100%",
                                            padding: "20px",
                                        }}
                                        src={previewImage}
                                    />
                                </ModalAntd>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                        <Col span={18}>
                            <Form.Item
                                label="Descrição:"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                        message: "Coloque uma Descrição",
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return (
                                                    setValidateDesc(false),
                                                    Promise.reject()
                                                );
                                            }
                                            setValidateDesc(true);
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <TextArea
                                    placeholder="Digite a descrição dos arquivos"
                                    showCount
                                    maxLength={100}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div style={{ marginTop: "20px" }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={handleDisable()}
                            loading={defaultProduct.uploading}
                            block
                        >
                            Salvar Midia
                        </Button>
                    </div>
                </Form>
            )}
        </>
    );
}
